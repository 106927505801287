import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Maintenance } from '../../authentication/authentication.api.service';
import { AuthHttpAdapter } from '../../share/http/auth-http.adapter';
import { AuthHttpService } from '../../share/http/auth-http.service';
import { Adapter, APIKey, Body, DELETE, GET, Headers, MediaType, Path, POST, Produces, PUT, ResponseType } from '../../share/http/index';
import { Tenant } from '../../share/models/tenant';

const imgType = { 'Accept': 'image/webp,image/png,image/*,*/*;q=0.8' };

@Injectable()
export class TenantAPIService extends AuthHttpService {

  private _api = this.configService.getAPI('users');

  @GET('/tenants')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getTenants(): Observable<Tenant[]> { return null; }

  @GET('/tenants?active=true')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getActiveTenants(): Observable<Tenant[]> { return null; }

  @GET('/tenants/{id}')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getTenant(@Path('id') id: string): Observable<any> { return null; }

  @GET('/tenants/{id}/tenantconfiguration?hierarchical=true')
  @APIKey('userconfig')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getTenantConfig(@Path('id') id: string): Observable<any> { return null; }

  @GET('/tenants/{tenantId}/imageFile')
  @ResponseType('blob')
  @Headers(imgType)
  @Adapter(AuthHttpAdapter.imgUrlAdapter)
  public getImageOfTenant(@Path('tenantId') id: string): Observable<any> { return null; }

  @POST('/tenants/{tenantId}/image')
  @Produces(MediaType.FILE)
  public createTenantImage(@Body form: any, @Path('tenantId') id: string, ): Observable<any> { return null; }

  @POST('/tenants')
  @Produces(MediaType.FORM_DATA)
  public createTenant(@Body form: FormGroup): Observable<any> { return null; }

  @PUT('/tenants/{id}')
  @Produces(MediaType.FORM_DATA)
  public updateTenant(@Body form: FormGroup, @Path('id') id: string): Observable<any> { return null; }

  @PUT('/tenants/{id}/tenantconfiguration?hierarchical=true')
  @APIKey('userconfig')
  @Produces(MediaType.JSON)
  public updateTenantConfig(@Body form: any, @Path('id') id: string): Observable<any> { return null; }

  @DELETE('/tenants/{id}')
  public deleteTenant(@Path('id') id: string): Observable<any> { return null; }

  @GET('/maintenance')
  @APIKey('userconfig')
  public getMaintenance(): Observable<Maintenance[]> { return null; }

  @POST('/maintenance')
  @APIKey('userconfig')
  public createMaintenance(@Body maintenance: Maintenance): Observable<Maintenance> { return null; }

  @PUT('/maintenanceId/{id}')
  @APIKey('userconfig')
  public updateMaintenance(@Body maintenance: Maintenance, @Path('id') id: string): Observable<Maintenance> { return null; }

  @DELETE('/maintenance/{id}')
  @APIKey('userconfig')
  public deleteMaintenance(@Path('id') id: string): Observable<Maintenance> { return null; }
}
