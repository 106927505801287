import { Injectable } from '@angular/core';
import { Moment, unitOfTime } from 'moment';

// see manual here https://github.com/WebDevTmas/moment-round

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  roundMoment(mom: Moment, precision: number, key: unitOfTime.All, direction: string): Moment {
    if (typeof direction === 'undefined') {
      direction = 'round';
    }

    const keys: unitOfTime.All[] = ['hours', 'minutes', 'seconds', 'milliseconds'];
    const maxValues = [24, 60, 60, 1000];
    let value = 0;
    let rounded = false;
    let subRatio = 1;
    let maxValue;

    for (const i in keys) {
      if (key.hasOwnProperty(i)) {
        const k = keys[i];
        if (k === key) {
          value = mom.get(key);
          maxValue = maxValues[i];
          rounded = true;
        } else if (rounded) {
          subRatio *= maxValues[i];
          value += mom.get(k) / subRatio;
          mom.set(k, 0);
        }
      }
    }

    value = Math[direction](value / precision) * precision;
    value = Math.min(value, maxValue);
    mom.set(key, value);

    return mom;
  }

  roundNumber(number: number, precision: number, direction: string): number {
    if (typeof direction === 'undefined') {
      direction = 'round';
    }
    return Math[direction](number / precision) * precision;
  }

  public ceilMoment(mom: Moment, precision: number, key: unitOfTime.All) {
    return this.roundMoment(mom, precision, key, 'ceil');
  }

  public floorMoment = function (mom: Moment, precision: number, key: unitOfTime.All) {
    return this.roundMoment(mom, precision, key, 'floor');
  };

  public ceil(number: number, precision: number): number {
    return this.roundNumber(number, precision, 'ceil');
  }

  public floor(number: number, precision: number): number {
    return this.roundNumber(number, precision, 'floor');
  }

  public sortArrayAlphabetically(a: any, b: any): number {
    const aLow = a['name'].toLowerCase();
    const bLow = b['name'].toLowerCase();
    if (aLow > bLow) {
        return 1;
    } else if (aLow < bLow) {
        return -1;
    }
    return 0;
  }
}
