import { Injectable } from '@angular/core';
import { API } from './config/api';
import { environment } from '../environments/environment';

@Injectable()
export class ConfigService {

    getAPI(key: string): string {
        try {
            return this.getBaseUrl() + API.routes[key];
        } catch (e) {
            console.error('API key not existing:' + key + e);
        }
    }

    getBaseUrl() {
        return environment.gateway;
    }
}

