import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipDefaultOptions,
  MatTooltipModule,
  MatMenuModule
  } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GestureConfig } from '@angular/material';
import { Global } from '../../config/global';
import { MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { NgModule } from '@angular/core';

// dateFormat
export const MY_FORMATS = {
  parse: {
    dateInput: Global.dateFormat,
  },
  display: {
    dateInput: Global.dateFormat,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 500,
  touchendHideDelay: 1000,
};

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSortModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatCardModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSliderModule,
    MatBadgeModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatListModule,
    MatRadioModule,
    MatRippleModule,
    MatMenuModule,
    ReactiveFormsModule,
  ],
  exports: [
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatDatepickerModule,
    MomentDateModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSortModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatCardModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSliderModule,
    MatBadgeModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatListModule,
    MatRadioModule,
    MatRippleModule,
    MatMenuModule,
    ReactiveFormsModule,
  ],
  declarations: [],
  providers: [
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }
  ]
})
export class MaterialModule { }
