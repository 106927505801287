/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dynamic-map.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dynamic-map.component";
import * as i3 from "../map.service";
var styles_DynamicMapComponent = [i0.styles];
var RenderType_DynamicMapComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DynamicMapComponent, data: {} });
export { RenderType_DynamicMapComponent as RenderType_DynamicMapComponent };
export function View_DynamicMapComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "map"]], [[1, "id", 0]], [["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (_co.onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mapId; _ck(_v, 0, 0, currVal_0); }); }
export function View_DynamicMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dynamic-map", [], null, null, null, View_DynamicMapComponent_0, RenderType_DynamicMapComponent)), i1.ɵdid(1, 4964352, null, 0, i2.DynamicMapComponent, [i3.MapService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DynamicMapComponentNgFactory = i1.ɵccf("app-dynamic-map", i2.DynamicMapComponent, View_DynamicMapComponent_Host_0, { singleMarker: "singleMarker", markers: "markers", route: "route", inputLat: "inputLat", inputLng: "inputLng", placeName: "placeName", directions: "directions", mapId: "mapId", circle: "circle" }, {}, []);
export { DynamicMapComponentNgFactory as DynamicMapComponentNgFactory };
