
import { Component, Inject, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogConfirmComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  @HostBinding('class.danger') danger = false;

  ngOnInit() {
    this.danger = this.data.danger ? true : false;

    // Check fallback to default dialog translation identifiers
    this.data.okButton = this.data.okButton || 'dialog.ok';
    this.data.cancelButton = this.data.cancelButton || 'dialog.cancel';

    console.log(this.data.translationParameters);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
