
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { FormFieldset } from '../../components/form/form-item-base';
import { DialogFormComponent } from './dialog-form/dialog-form.component';
import { FormGroup } from '@angular/forms';
import { DialogFilePickerComponent } from './dialog-file-picker/dialog-file-picker.component';
import { VehicleAPIService } from '../../../modules/vehicle/vehicle.api.service';

@Injectable()
export class DialogService {

  constructor(
    private dialog: MatDialog,
    private _vehicleAPI: VehicleAPIService
  ) {}

  public confirmWithParameters(title: string, message?: string, translationParameters?: any,
    buttonIdentifiers?: string[], isHtml?: boolean): Observable<boolean> {

    let dialogRef: MatDialogRef<DialogConfirmComponent>;

    dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: '400px',
      data: {
        title: title,
        message: message,
        translationParameters: translationParameters,
        okButton: buttonIdentifiers && buttonIdentifiers[0] ? buttonIdentifiers[0] : 'dialog.ok',
        cancelButton: buttonIdentifiers && buttonIdentifiers[1] ? buttonIdentifiers[1] : 'dialog.cancel',
        isHtml: isHtml ? isHtml : false
      }
    });

    return dialogRef.afterClosed();
  }


  public confirm(title: string, message?: string, buttonIdentifiers?: string[]): Observable<boolean> {
    return this.confirmWithParameters(title, message, {}, buttonIdentifiers);
  }

  public confirmHtml(title: string, message?: string, buttonIdentifiers?: string[]): Observable<boolean> {
    return this.confirmWithParameters(title, message, {}, buttonIdentifiers, true);
  }

  public confirmDanger(title: string, message?: string): Observable<boolean> {

    let dialogRef: MatDialogRef<DialogConfirmComponent>;

    dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: '400px',
      panelClass: 'danger',
      data: {
        title: title,
        message: message,
        danger: true
      }
    });

    return dialogRef.afterClosed();
  }

  public filePicker(title: string, message?: string) {
    let dialogRef: MatDialogRef<DialogFilePickerComponent>;

    dialogRef = this.dialog.open(DialogFilePickerComponent, {
      width: '500px',
      data: {
        title: title,
        message: message,
      }
    });

    return dialogRef.afterClosed();
  }

  // Upload CSV to server
  upload(file: File): Promise<any> {
    return this._vehicleAPI.importCsvFile(file).toPromise();
  }

  public dialogForm(
    formFieldsets: FormFieldset[],
    columns?: number,
    dynamicForm?: FormFieldset[],
    buildFunction?: (
      parameter: any,
      formFieldsets: FormFieldset[]) => FormFieldset,
      dynamicFormIdentifier?: string, additionalButton?: string
    ): Observable<FormGroup> {
      let dialogRef: MatDialogRef<DialogFormComponent>;
      dialogRef = this.dialog.open(DialogFormComponent, {
        width: columns && columns !== 1 ? '560px' : '400px',
        data: {
          columns: columns,
          formFieldsets: formFieldsets,
          dynamicForm: dynamicForm,
          buildDynamicFieldset: buildFunction,
          dynamicFormIdentifier: dynamicFormIdentifier,
          additionalButton: additionalButton
        }
    });

    return dialogRef.afterClosed();
  }
}
