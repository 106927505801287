import { ActivatedRoute, NavigationEnd, Router, Routes } from '@angular/router';
import { AuthenticationAPIService } from './authentication.api.service';
import { AuthenticationGuard } from '../share/helper/guards/authentication.guard';
import { AuthenticationService } from './authentication.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { catchError, first, flatMap, map } from 'rxjs/operators';
import { Company, User, Tenant } from '../share/models/index';
import { CompanyAPIService } from '../modules/company/company.api.service';
import { empty } from 'rxjs/internal/observable/empty';
import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { NotificationService } from '../share/utility/notification/notification.service';
import { Observable } from 'rxjs/internal/Observable';
import { Rights } from './../share/models/right';
import { RootLoadingService } from '../share/utility/loading/root-loading.service';
import { Sidebar, SidebarCategory } from '../config/sidebar';
import { UserAPIService } from '../modules/user/user.api.service';
import { UserConfiguration } from '../../../core/userConfiguration';
import { forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';
import { TenantMainService } from '../modules/tenant/tenant-main.service';
import { DialogService } from '../share/utility/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { TenantConfiguration } from '../share/models/tenantConfiguration';

export interface AppContext {
  user: User;
  company: Company;
  tenant: Tenant;
  tenantConfig: TenantConfiguration;
}

@Injectable()
export class AuthenticationAppService {
  private _companies = new BehaviorSubject<Company[]>(null);
  private _company = new BehaviorSubject<Company>(null);
  private _user = new BehaviorSubject<User>(null);
  private _appContext = new BehaviorSubject<AppContext>(null);
  private _userConfiguration = new BehaviorSubject<UserConfiguration>(null);
  private _tenantConfiguration = new BehaviorSubject<TenantConfiguration>(null);
  private _isManageTenantView = new BehaviorSubject<boolean>(false);
  private _sidebarMenu: BehaviorSubject<SidebarCategory[]> = new BehaviorSubject<SidebarCategory[]>(null);
  private _subMenu = new BehaviorSubject<Routes>(null);

  constructor(
    private _authAPI: AuthenticationAPIService,
    private _auth: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _loadingService: RootLoadingService,
    private _notificationService: NotificationService,
    private _companyAPIService: CompanyAPIService,
    private _authGuardService: AuthenticationGuard,
    private _userAPIService: UserAPIService,
    private _tenantService: TenantMainService,
    private _dialogService: DialogService,
    private _translationService: TranslateService,
  ) {
    this.initFromLocalStorage();

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this._router.url.includes('tenant')) {
        this._isManageTenantView.next(true);
      } else {
        this._isManageTenantView.next(false);
      }
    });

    // Listen on user object change events
    this.getUserAsOservable().subscribe(user => {
      if (user !== undefined && user !== null) {
        this.storeUser();
        this.refreshMenu(user);
      }
    });

    // Store context to localStorage when changed
    this.getAppContextAsOservable().subscribe(appContext => {
      if (appContext !== undefined) {
        this.storeAppContext(appContext);
      }
    });

    // Store context to localStorage when changed
    this.getUserConfigurationAsObservable().subscribe(userConfig => {
      if (userConfig && userConfig !== undefined && userConfig != null) {
        this.storeUserConfig(userConfig);
      }
    });
  }

  get companies(): Company[] {
    return this._companies.getValue();
  }

  getCompaniesObservable(): BehaviorSubject<Company[]> {
    return this._companies;
  }

  get company(): Company {
    return this._company.getValue();
  }

  set company(company: Company) {
    this._company.next(company);
  }

  getCompanyObservable(): BehaviorSubject<Company> {
    return this._company;
  }

  set user(user: User) {
    this._user.next(user);
  }

  get user(): User {
    return this._user.getValue();
  }

  getUserAsOservable(): BehaviorSubject<User> {
    return this._user;
  }

  set appContext(appContext: AppContext) {
    this._appContext.next(appContext);
  }

  get appContext(): AppContext {
    return this._appContext.getValue();
  }

  getAppContextAsOservable(): BehaviorSubject<AppContext> {
    return this._appContext;
  }

  set userConfiguration(uc: UserConfiguration) {
    this._userConfiguration.next(uc);
  }

  set tenantConfiguration(tc: TenantConfiguration) {
    this._tenantConfiguration.next(tc);
  }

  getTenantConfigurationAsObservable(): BehaviorSubject<TenantConfiguration> {
    return this._tenantConfiguration;
  }

  getUserConfigurationAsObservable(): BehaviorSubject<UserConfiguration> {
    return this._userConfiguration;
  }

  get sidebar(): SidebarCategory[] {
    return this._sidebarMenu.getValue();
  }

  set sidebar(sidebar: SidebarCategory[]) {
    this._sidebarMenu.next(sidebar);
  }

  getSidebarAsObservable(): Observable<SidebarCategory[]> {
    return this._sidebarMenu;
  }

  set submenu(routes: Routes) {
    this.getSidebarAsObservable().pipe(
      first())
      .subscribe(menu => {
        const routeArray = [];
        routes.forEach(menuItem => {
          if (menuItem.data.hideInMenu != null && menuItem.data.hideInMenu === true) {
            return;
          }
          const menuItemRights = menuItem.data.rights as Array<string>;
          this.user.role.rights.forEach(right => {
            if ((menuItemRights.indexOf(right.name) > -1 || menuItemRights.length === 0) && !routeArray.includes(menuItem)) {
              routeArray.push(menuItem);
            }
          });
        });
        this._subMenu.next(routeArray);
      });
  }

  get subMenu(): BehaviorSubject<Routes> {
    return this._subMenu;
  }

  get isManageTenantView(): BehaviorSubject<boolean> {
    return this._isManageTenantView;
  }

  initFromLocalStorage() {

    // Retrieve user object
    const userData = localStorage.getItem('user');
    if (userData && userData !== undefined) {
      this.user = JSON.parse(userData);
    }

    // Retrieve user configuration
    const userConfigData = localStorage.getItem('userconfig');
    if (userConfigData && userConfigData !== undefined && userConfigData !== 'undefined' && userConfigData != null) {
      this.userConfiguration = JSON.parse(userConfigData);
    }

    // Retrieve application context (tenant, company)
    if (localStorage.getItem('appContext')) {
      this.initContext();

      // Update context for display in status bar (workaround?)
      // company/tenant are not defined in every app state (e.g. tenant logout)
      if (this.appContext.company) {
        this.updateContext(this.appContext.company.id);
      } else {
        this.updateContext();
      }
    }
  }

  storeUser() {
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  storeUserConfig(userConfiguration?: UserConfiguration) {
    if (userConfiguration && userConfiguration !== undefined) {
      localStorage.setItem('userconfig', JSON.stringify(userConfiguration));
    }
  }

  setMaintenanceDialog() {
    this._authAPI.getMaintenance().subscribe(result => {
      if (result && result.length > 0) {
        const maintenance = result.pop();
        const acknowledgedMaintenanceId: String = localStorage.getItem('ackMaintenanceId');
        if  (acknowledgedMaintenanceId === 'undefined' ||
              (acknowledgedMaintenanceId !== undefined && acknowledgedMaintenanceId === maintenance.id)) {
          return;
        }

        const startTime = moment(maintenance.startTimePlanned).format('DD.MM.YY HH:mm');
        const endTime = moment(maintenance.endTimePlanned).format('DD.MM.YY HH:mm');
        const message = `<p>${startTime} - ${endTime} <br> ${maintenance.message} </p>`;

        this._dialogService.confirmHtml('dialog.maintenance', message, ['dialog.remind', 'dialog.ok']).subscribe(confirm => {
          if (!confirm) {
            localStorage.setItem('ackMaintenanceId', maintenance.id);
          }
        });
      }
    });
  }

  navigateAfterLogin(returnUrl: string) {
    if (this.sidebar.length === 0) {
      this._notificationService.showWarning('login.message.noMenu');
      this._auth.logout();
      this._loadingService.hideLoading();
    } else if (this.isSuperAdmin()) {
      // Get default tenant id from userconfig
      this._userAPIService.getOwnUserConfiguration().pipe(first()).subscribe(userConfig => {
        this.userConfiguration = userConfig;
        const defaultTenantId = userConfig.userConfigurations.DEFAULT_TENANT;
        console.log('Selected default tenant: ' + defaultTenantId);
        (defaultTenantId === undefined) ? this.navigateToTenantView(returnUrl) : this.loginTenantId(defaultTenantId);
      });
    } else {
      this.navigateToUserView(returnUrl);
    }
    this.setMaintenanceDialog();
  }

  navigateToTenantView(returnUrl: string) {
    // Clear context to set a new context
    this.clearContext();
    returnUrl === '/' ? this._router.navigate(['/tenant']) : this.navigateToUserView(returnUrl);
  }

  navigateToUserView(returnUrl: string) {
    this.updateContext();
    returnUrl !== '/' ? this._router.navigate([returnUrl]) : this.navigateToFirstElementInMenu();
  }

  isSuperAdmin() {
    return this._authGuardService.hasRight([Rights.manage_all_tenants]);
  }

  loginTenantId(id: string) {
    // this._loadingService.showLoading();
    console.log('loginTenantID: ' + id);

    let tenant: Tenant;
    // Do we need to load the tenant object for this tenantId?
    if (this.appContext && this.appContext.tenant != null && this.appContext.tenant.id === id) {
      tenant = this.appContext.tenant;
      this.selectTenant(tenant);
    } else {
      this.selectByTenantId(id);

    }
  }

  loginTenant(tenant: Tenant) {
    this._loadingService.showLoading();

    this.selectTenant(tenant);
  }

  private selectTenant(tenant: Tenant) {
    this._authAPI.getTenantToken(tenant.id).pipe(
      map(response => response.body.token),
      first(),
      catchError(
        error => {
          console.error('Error in tenant login: ' + error.message);
          return empty();
        }
      )).subscribe(token => {
        localStorage.setItem('token', token);
        this.updateContextWithTenant(null, tenant);
        this.getSidebarAsObservable().pipe(
          first(routeArray => routeArray !== null && routeArray !== undefined))
          .subscribe(routeArray => {
            this.navigateToFirstElementInMenu();
          });
      });
  }


  loginCompany(companyId: string) {
    this._authAPI.getCompanyToken(companyId).pipe(
      map(response => {
        const token = response.body.token;
        localStorage.setItem('token', token);
      }),
      first(),
      catchError(error => {
        console.error('Error in company login' + error.message);
        return empty();
      })).subscribe(token => {
        const returnUrl = this._router.routerState.snapshot.url || '/';
        this._router.navigate([returnUrl]);
        this._company.next(this.getCurrentCompany(companyId));
        this.updateContext(companyId);
      });
  }

  logoutTenant() {
    this.clearContext();

    // Should we really remove the default setting, when the user logs out of a tenent?
    // this.changeDefaultTenant();
  }

  changeDefaultTenant() {
    const userConfig = this.userConfiguration;
    userConfig.userConfigurations.DEFAULT_TENANT = null;

    this.userConfiguration = userConfig;
    this._userAPIService.updateUserOwnConfiguration(userConfig).subscribe(val => console.log(val));
  }

  impersonateUser(userId: string) {
    this._loadingService.showLoading();
    const obsUser = this._userAPIService.getUser(userId);
    const obsToken = this._authAPI.impersonateUser(userId);
    const subscription = forkJoin(obsUser, obsToken);
    subscription.pipe(
      map(response => {
        localStorage.setItem('originalUser', localStorage.getItem('user'));
        localStorage.setItem('user', JSON.stringify(response[0]));
        this.user = response[0];
        localStorage.setItem('originalToken', localStorage.getItem('token'));
        localStorage.setItem('token', response[1]['token']);
      }),
      flatMap(() => {
        return this._userAPIService.getOwnUserConfiguration();
      }),
      map((userConfig: UserConfiguration) => {
        localStorage.setItem('userconfig', JSON.stringify(userConfig));
        this.userConfiguration = userConfig;
      }),
      catchError(
        error => {
          console.error('Error in impersonation: ' + error.message);
          return empty();
        }
      )).subscribe(result => {
        this.updateContext();
        this.navigateToFirstElementInMenu();
        this._notificationService.showSuccess('user.message.userImpersonated');
      });
  }

  quitImpersonateUser() {
    this._loadingService.showLoading();
    localStorage.setItem('user', localStorage.getItem('originalUser'));
    localStorage.removeItem('originalUser');
    localStorage.setItem('token', localStorage.getItem('originalToken'));
    localStorage.removeItem('originalToken');
    this._userAPIService.getOwnUserConfiguration().pipe(
      first())
      .subscribe(userConfig => {
        localStorage.setItem('userconfig', JSON.stringify(userConfig));
        this.user = JSON.parse(localStorage.getItem('user'));
        this.updateContext();
        this.navigateToFirstElementInMenu();
        this._notificationService.showSuccess('user.message.userImpersonationFinished');
      });
  }

  updatePassword(form: FormGroup) {
    this._loadingService.showLoading();
    this._authAPI.updatePassword(form).pipe(
      first()).subscribe(result => {
        this._loadingService.hideLoading();
        this._notificationService.showSuccess('user.message.userPasswordResetConfirmation');
      });
  }

  requestNewPassword(username: string) {
    this._authAPI.requestPasswordreset(username).pipe(
      first()).subscribe(result => {
        this._notificationService.showSuccess('user.message.userPasswordLinkSent');
      });
  }

  private refreshMenu(user: User) {
    // refresh tbe sidemenu to only show the menu items the user is allowed to see
    const sidebarArray: SidebarCategory[] = [];
    Sidebar.sidebarItems.forEach(menuCategory => {
      if (menuCategory.name !== 'router.headers.marketplace' ||
        (menuCategory.name === 'router.headers.marketplace' && environment.efsDevMode)) {
        const newCategory: SidebarCategory = {
          name: menuCategory.name,
          items: []
        };

        menuCategory.items.forEach(menuItem => {
          // check if the users has the rights to see the menu item
          const menuItemRights = menuItem.rights as Array<string>;
          let addMenuItem = menuItemRights.length === 0;
          user.role.rights.forEach(right => {
            if (menuItemRights.indexOf(right.name) > -1) {
              addMenuItem = true;
            }
          });

          if (addMenuItem) {
            newCategory.items.push(menuItem);
          }
        });

        // add the category to the sidebar if it contains items
        if (newCategory.items.length > 0) {
          sidebarArray.push(newCategory);
        }
      }
    });

    this.sidebar = sidebarArray;
  }

  private navigateToFirstElementInMenu() {
    this.sidebar[0] && this.sidebar[0].items[0] && this.sidebar[0].items[0].path ?
      this._router.navigate([this.sidebar[0].items[0].path]) : this._router.navigate(['/']);
  }

  private getCurrentCompany(companyId) {
    let currentCompany: Company;
    this.companies.forEach(company => {
      if (company.id === companyId) {
        currentCompany = company;
      }
    });

    // the passed companyId is not part of this tenant
    // take the first one available from the list
    if (!currentCompany) {
      currentCompany = this.companies[0];
    }
    return currentCompany;
  }

  private selectByTenantId(tenantId: string) {
    let currentTenant: Tenant;
    return this._tenantService.getObjects().subscribe(response => {
      if (response.length > 0) {
        currentTenant = response[0];
      }

      response.forEach(tenant => {
        if (tenant.id === tenantId) {
          currentTenant = tenant;
        }
      });

      this.selectTenant(currentTenant);

      return currentTenant;
    });
  }

  // private getSelectedTenantId() {
  // return (this.getAppContext() ? this.getAppContext().tenant.id : null);
  // }

  private initContext() {
    const appContext: AppContext = JSON.parse(localStorage.getItem('appContext'));
    this.appContext = appContext;
  }


  private updateContextWithTenant(companyId: string, tenant?: Tenant) {
    const isTenantAdmin = this._authGuardService.hasRight([Rights.manage_companies]);
    const tenantConfigObservable = this._userAPIService.getTenantConfiguration();
    let companyCtx: Company;

    // if no company is defined, take the companyId from user object
    if (!companyId) {
      if (this.appContext && this.appContext.company != null && this.appContext.company !== undefined) {
        companyId = this.appContext.company.id;
      } else {
        companyId = this.user.companyId;
      }
    }

    // Tenant Admin can switch between companies.
    // He needs all companies in the tenant
    // Update tenant configuration
    if (isTenantAdmin) {
      // Get new company from the selected company id
      forkJoin(this._companyAPIService.getCompanies(), tenantConfigObservable).subscribe(response => {
        this._companies.next(response[0]);
        companyCtx = this.getCurrentCompany(companyId);
        // update selected company
        this.company = companyCtx;
        this.setAppContext(this.user, companyCtx, tenant, response[1]);
      });
    } else {
      // Select the company of the ordinary user from the user object
      forkJoin(this._companyAPIService.getCompany(companyId), tenantConfigObservable).subscribe(response => {
        companyCtx = response[0];
        this.company = companyCtx;
        this._companies.next(null);
        this.setAppContext(this.user, companyCtx, tenant, response[1]);
      });
    }
  }

  private updateContext(companyId?: string) {

    // Take the user.tenant when no explicit tenant is set
    let tenantCtx: Tenant;
    if (this.appContext && this.appContext.tenant != null && this.appContext.tenant !== undefined) {
      tenantCtx = this.appContext.tenant;
    } else {
      tenantCtx = this.user.tenant;
    }

    this.updateContextWithTenant(companyId, tenantCtx);
  }

  private clearCompanyAppContext() {
    const appContext = this.appContext;
    if (appContext && appContext !== undefined) {
      appContext.company = null;
    }
  }

  private setAppContext(user: User, company: Company, tenant: Tenant, tenantConfig: TenantConfiguration) {
    const appContext: AppContext = {
      user: this.user,
      company: company,
      tenant: tenant,
      tenantConfig: tenantConfig
    };
    this.appContext = appContext;
  }


  private clearContext() {
    const clearedAppContext: AppContext = {
      user: this.user,
      company: null,
      tenant: null,
      tenantConfig: null
    };

    this.appContext = clearedAppContext;
    // Remove the item from localStorage - Perhaps
    // localStorage.removeItem('appContext');
  }

  private storeAppContext(appContext: AppContext) {
    if (appContext && appContext !== undefined) {
      localStorage.setItem('appContext', JSON.stringify(appContext));
    }
  }
}
