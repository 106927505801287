export class Tenant {
    consistent: boolean;
    contact: string;
    firstLevelSupportEmail: string;
    firstLevelSupportTelephoneNumber: string;
    deleted: boolean;
    id: string;
    name: string;
    vanityname: string;
    active: boolean;
    expiresAt: Date;
    comment: string;
    tenantConfigurations: string[];
    imageUrl: string;
}
