import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
// tslint:disable-next-line:import-blacklist
import { empty, Observable, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { Maintenance } from '../../authentication/authentication.api.service';
import { RootTableService } from '../../share/components/table/root-table.service';
import { Tenant } from '../../share/models';
import { DialogService } from '../../share/utility/dialog/dialog.service';
import { RootLoadingService } from '../../share/utility/loading/root-loading.service';
import { NotificationService } from '../../share/utility/notification/notification.service';
import { TenantAPIService } from './tenant.api.service';

@Injectable()
export class TenantMainService extends RootTableService<Tenant> {

  constructor(
    private _tenantAPI: TenantAPIService,
    private _notificationService: NotificationService,
    protected _dialogService: DialogService,
    private _loadingService: RootLoadingService,
    private _domSanitizer: DomSanitizer,
  ) {
    super();
  }

  createObject(formGroup: FormGroup): Observable<any> {
    this._loadingService.showLoading();
    return this._tenantAPI.createTenant(formGroup).pipe(
      map(result => {
        this._loadingService.hideLoading();
        this._notificationService.showSuccess('tenant.message.tenantCreated');
      })
    );
  }

  updateObject(form: FormGroup, id: string): Observable<any> {
    return this._dialogService.confirm('tenant.message.tenantUpdateConfirm').pipe(
      flatMap(confirm => {
        if (confirm) {
          return this._tenantAPI.updateTenant(form, id).pipe(
            map(result => {
              this._notificationService.showSuccess('tenant.message.tenantUpdated');
            })
          );
        }
      })
    );
  }

  updateTenantConfig(configuration: any, id: string): Observable<any> {
    return this._dialogService.confirm('tenant.message.tenantUpdateConfirm').pipe(
      flatMap(confirm => {
        if (confirm) {
          return this._tenantAPI.updateTenantConfig(configuration, id).pipe(
            map(result => {
              this._notificationService.showSuccess('tenant.message.tenantUpdated');
            })
          );
        }
      })
    );
  }

  createGenericObject(): Tenant {
    return new Tenant();
  }

  getTenantConfig(id: string): Observable<any> {
    return this._tenantAPI.getTenantConfig(id).pipe(
      map(response => {
        return response.body.tenantConfigurations;
      }),
      catchError(error => {
        console.error('Error getting TenantConfig: ' + error.message);
        return empty();
      })
    );
  }

  deleteObject(object: Tenant): Observable<any> {
    return this._dialogService.confirm('Are you sure you wish to delete this tenant?').pipe(
      flatMap(confirm => {
        if (confirm) {
          return this._tenantAPI.deleteTenant(object.id).pipe(
            map(result => {
              this._notificationService.showSuccess('Successfully Deleted');
            })
          );
        }
      })
    );
  }
  getObjects(): Observable<Tenant[]> {
    return this._tenantAPI.getTenants();
  }
  getAll(): Observable<any[]> {
    throw new Error('Method not implemented.');
  }

  getImageOfTenant(tenantId: string): Observable<any> {
    return this._tenantAPI.getImageOfTenant(tenantId).pipe(
      map(response => this._domSanitizer.bypassSecurityTrustUrl(response)),
      catchError(error => {
        return of('/assets/img/default-image.png');
      })
    );
  }

  uploadImageOfTenant(tenantId: string, file: any): Observable<any> {
    return this._tenantAPI.createTenantImage(file, tenantId).pipe(
      map(response => response),
      catchError(error => {
        console.log('Tenant image error: ', error);
        // Change for better default image
        return of('');
      }));
    }
  /**
   * Maintenance
   */

  getMaintenance(): Observable<any> {
    return this._tenantAPI.getMaintenance();
  }

  createMaintenance(form: Maintenance): Observable<Maintenance> {
    return this._tenantAPI.createMaintenance(form).pipe(
      map(result => {
        this._notificationService.showSuccess('Maintenance created');
        return result;
      })
    );
  }

  updateMaintenance(form: Maintenance, id: string): Observable<Maintenance> {
    return this._tenantAPI.updateMaintenance(form, id).pipe(
      map(result => {
        this._notificationService.showSuccess('Maintenance updated');
        return result;
      })
    );
  }

  deleteMaintenance(id: string) {
    return this._tenantAPI.deleteMaintenance(id).pipe(
      map(result => {
        this._notificationService.showSuccess('Maintenance deleted');
      })
    );
  }
}
