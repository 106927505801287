
import { throwError,  Observable, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationService } from '../utility/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthHttpResponseHandler {
    constructor(
        private _translateService: TranslateService,
        private notificationsService: NotificationService,

    ) { }

    /**
     * Global http error handler.
     *
     * @param error
     * @param source
     * @returns {ErrorObservable}
     */
    public onCatch(response: any, source?: Observable<any>): Observable<any> {
        switch (response.status) {
            case 400:
                this.handleBadRequest(response);
                break;

            case 401:
                this.handleUnauthorized(response);
                break;

            case 403:
                this.handleForbidden();
                break;

            case 404:
                this.handleNotFound(response);
                break;

            case 415:
                this.handleBadRequest(response);
                break;

            case 500:
                this.handleServerError();
                break;

            case 'timeout':
                this.handleTimeout(response);
                break;

            default:
                break;
        }

        return throwError(response);
    }

    /**
     * Shows notification errors when server response status is 401
     *
     * @param error
     */
    private handleBadRequest(responseBody: any): Observable<never> {
        try {
            const errorMessage = JSON.parse(responseBody._body);
            // console.log(test.message);
            this.handleErrorMessages(/*'Error : ' + errorMessage.code + ' - ' + */errorMessage.message);
        } catch (error) {
            if (!responseBody.error || responseBody.error === undefined) {
                this.handleServerError('Error ' + responseBody.status + ' occured');
            } else {
                this.handleServerError(responseBody.error.message);
            }
        }
        return empty();
    }

    /**
     * Shows notification errors when server response status is 401 and redirects user to login page
     *
     * @param responseBody
     */
    private handleUnauthorized(responseBody: any): Observable<never> {
        this.notificationsService.showWarning('error.api.403');
        return empty();
    }

    /**
     * Shows notification errors when server response status is 403
     */
    private handleForbidden(): Observable<never> {
        this.notificationsService.showWarning('error.api.403');
        return empty();
    }

    /**
    * Shows notification errors when server response status is 403
    */
    private handleTimeout(err: any): Observable<never> {
        this.notificationsService.showWarning(err.message);
        return empty();
    }

    /**
     * Shows notification errors when server response status is 404
     *
     * @param responseBody
     */
    private handleNotFound(responseBody: any): Observable<never> {
        this.notificationsService.showWarning('ServerError 404 - Not found');
        return empty();
    }

    /**
     * Shows notification errors when server response status is 500
     */
    private handleServerError(response?: any): Observable<never> {
        if (response) {
            this.notificationsService.showWarning(response);
        } else {
            this.notificationsService.showWarning(this._translateService.instant('server.error'));
        }
        return empty();
    }

    /**
     * Parses server response and shows notification errors with translated messages
     *
     * @param response
     */
    private handleErrorMessages(message?: string): void {
        /*  if (!response) {
             return;
         }
         for (const key of Object.keys(response)) {
             if (Array.isArray(response[key])) {
                 response[key].forEach((value) => this.showNotificationError('Error', this.getTranslatedValue(value)));
             } else {
                 this.showNotificationError('Error', this.getTranslatedValue(response[key]));
             }
         } */
        if (message) {
            this.notificationsService.showWarning(message);
        } else {
            this.notificationsService.showWarning('undefined error');
        }
    }

    /**
     * Extracts and returns translated value from server response
     *
     * @param value
     * @returns {string}
     */
    private getTranslatedValue(value: string): string {
        /* if (value.indexOf('[') > -1) {
            const key = value.substring(value.lastIndexOf('[') + 1, value.lastIndexOf(']'));
            value = this.translateService.instant(key);
        } */

        return value;
    }

    /**
     * Returns relative url from the absolute path
     *
     * @param responseBody
     * @returns {string}
     */
    private getRelativeUrl(url: string): string {
        return url.toLowerCase().replace(/^(?:\/\/|[^\/]+)*\//, '');
    }

    /**
     * Shows error notification with given title and message
     *
     * @param title
     * @param message
     */
    private showNotificationError(title: string, message: string): void {
        // this.notificationsService.error(title, message, this.configService.get('notifications').options);
    }
}
