import { DriversLicense, Role, Tenant, Company } from './index';

export class User {
    id: string;
    tenant: Tenant;
    tenantId: string;
    company: Company;
    companyId: string;
    username: string;
    password: string;
    formOfAddress: string;
    firstName: string;
    lastName: string;
    birthdate: Date;
    birthplace: string;
    street: string;
    housenumber: string;
    zipcode: string;
    city: string;
    country: string;
    mobilenumber: string;
    phonenumber: string;
    email: string;
    role: Role;
    active: boolean;
    deleted: boolean;
    consistent: boolean;
    driversLicense: DriversLicense;
    accessDeviceId: string;
    rfidToken: string;
    vehicleId: string;
    termsOfUseAccepted: boolean;
    lastLogin: Date;
    registeredAt: Date;
    updatedAt: Date;
    createdAt: Date;
    defaultSiteId: string;
    // This is not part of the object
    // It's needed for image displaying
    imageUrl: string;
}
