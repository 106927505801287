
import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { RootToolbarService } from '../../main/root-components/root-toolbar/root-toolbar.service';
import { Observable } from 'rxjs';
import { merge } from 'rxjs/operators';

export type TableState = 'master' | 'secondLevel' | 'detail' | 'new' | 'edit';
export type TableEvent = TableState | 'close' | 'cancel' | 'submit';
export type TableAction = 'create' | 'delete' | 'update' | 'refreshDetail' | 'refreshMaster' | 'cancel' | TableView | any;
export type TableView = 'grid' | 'list';

@Injectable()
export class RootTableStateService {

  tableStateEvent = new BehaviorSubject<TableState>('master');
  tableEvent = new Subject<TableEvent>();
  tableActionEvent = new Subject<TableAction>();

  constructor(private _toolbarService: RootToolbarService) {
    const toolbarButtonEvent = this._toolbarService.toolbarButtonController;
    toolbarButtonEvent.pipe(merge(this.tableEvent)).subscribe(event => {
      if (event === 'master' || (event === 'close' && this.tableStateEvent.getValue() !== 'edit') ||
        event === 'cancel' && this.tableStateEvent.getValue() === 'new') {
        this.tableStateEvent.next('master');
      } else if (event === 'cancel' && this.tableStateEvent.getValue() === 'edit') {
        this.tableStateEvent.next('detail');
        this.tableActionEvent.next('cancel');
      } else if (event === 'close' && this.tableStateEvent.getValue() === 'edit') {
        this.tableStateEvent.next('master');
        this.tableActionEvent.next('cancel');
      } else if (event === 'detail' || event === 'edit' || event === 'new') {
        this.tableStateEvent.next(event);
      } else if (event === 'submit' && this.tableStateEvent.getValue() === 'new') {
        this.tableActionEvent.next('create');
      } else if (event === 'submit' && (this.tableStateEvent.getValue() === 'edit')) {
        this.tableActionEvent.next('update');
      } else if (event === 'submitTab' && (this.tableStateEvent.getValue() === 'edit')) {
        // console.log('submitTab');
        this.tableStateEvent.next('detail');
      } else if (event === 'delete' || event === 'refreshDetail' || event === 'refresh' || event === 'grid' || event === 'list') {
        this.tableActionEvent.next(event);
      } else if (event === 'refreshMaster') {
        this.tableActionEvent.next('refreshMaster');
      } else {
        this.tableActionEvent.next('customAction::' + event);
      }
    });
  }

  isTableState(name: string): boolean {
    return name === 'master' || name === 'detail' ||
      name === 'new' || name === 'edit' || name === 'delete' ||
      name === 'close' || name === 'cancel' ||
      name === 'submit';
  }

}
