import { User, UserGroup } from '../../share/models/index';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthHttpService, MediaType } from '../../share/http/auth-http.service';
import { AuthHttpAdapter } from '../../share/http/auth-http.adapter';
import {
  Path, Produces, APIKey, Body,
  GET, POST, PUT, DELETE, Headers, Adapter, ResponseType, RequestAdapter
} from '../../share/http/auth-http.decorator';
import { FormGroup } from '@angular/forms';
import { Role } from '../../share/models/role';
import { UserConfiguration } from '../../../../core/userConfiguration';
import { TenantConfiguration } from '../../share/models/tenantConfiguration';

const imgType = {
  'Accept': 'image/webp,image/png,image/*,*/*;q=0.8'
};

@Injectable()
export class UserAPIService extends AuthHttpService {

  private _api = this.configService.getAPI('users');

  @POST('/users/{id}/driverslicenses/check')
  public checkDriverLicense(@Path('id') id: string): Observable<any> { return null; }

  @GET('/users')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getUsers(): Observable<User[]> { return null; }

  @PUT('/users/{id}/activate')
  public activateUser(@Path('id') id: string): Observable<any> { return null; }

  @PUT('/users/{id}/deactivate')
  public deactivateUser(@Path('id') id: string): Observable<any> { return null; }

  @GET('/users?active=true')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getActiveUsers(): Observable<User[]> { return null; }

  @POST('/users')
  @Produces(MediaType.FORM_DATA)
  //   @API('authentication')
  public createUser(@Body form: FormGroup): Observable<any> { return null; }

  @PUT('/users/{id}')
  @Produces(MediaType.FORM_DATA)
  public updateUser(@Body form: FormGroup, @Path('id') id: string): Observable<any> { return null; }

  @PUT('/users/{id}')
  public updateUserObject(@Body user: User, @Path('id') id: string): Observable<any> { return null; }

  @GET('/users/{id}')
  public getUser(@Path('id') id: string): Observable<User> { return null; }

  @PUT('/self')
  @Produces(MediaType.FORM_DATA)
  public updateSelf(@Body form: FormGroup): Observable<any> { return null; }

  @PUT('/self')
  public updateSelfObject(@Body object: User): Observable<any> { return null; }

  @DELETE('/users/{id}')
  public deleteUser(@Path('id') id: string): Observable<any> { return null; }

  @GET('/roles')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getRoles(): Observable<Role[]> { return null; }

  @POST('/roles')
  @Produces(MediaType.FORM_DATA)
  public createRole(@Body form: FormGroup): Observable<any> { return null; }

  @PUT('/roles/{id}')
  @Produces(MediaType.FORM_DATA)
  public updateRole(@Body form: FormGroup, @Path('id') id: string): Observable<any> { return null; }

  @DELETE('/roles/{id}')
  public deleteRole(@Path('id') id: string): Observable<any> { return null; }

  @GET('/rights')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getRights(): Observable<Role[]> { return null; }

  @GET('/usergroups')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getUserGroups(): Observable<UserGroup[]> { return null; }

  @POST('/usergroups')
  @Produces(MediaType.FORM_DATA)
  public createUserGroup(@Body form: FormGroup): Observable<any> { return null; }

  @PUT('/usergroups/{id}')
  @Produces(MediaType.FORM_DATA)
  public updateUserGroup(@Body form: FormGroup, @Path('id') id: string): Observable<any> { return null; }

  @DELETE('/usergroups/{id}')
  public deleteUserGroup(@Path('id') id: string): Observable<any> { return null; }

  @GET('/userconfiguration')
  @APIKey('userconfig')
  public getOwnUserConfiguration(): Observable<UserConfiguration> { return null; }

  @PUT('/userconfiguration')
  @APIKey('userconfig')
  @RequestAdapter(AuthHttpAdapter.booleanToStringAdapter)
  public updateUserOwnConfiguration(@Body userConfig: UserConfiguration): Observable<any> { return null; }

  @GET('/tenantconfiguration')
  @APIKey('userconfig')
  public getTenantConfiguration(): Observable<TenantConfiguration> { return null; }

  @GET('/users/{id}/imageFile')
  @ResponseType('blob')
  @Headers(imgType)
  @Adapter(AuthHttpAdapter.imgUrlAdapter)
  public getImageOfUser(@Path('id') id: string): Observable<any> { return null; }

  @POST('/users/{companyId}/image')
  @Produces(MediaType.FILE)
  public createUserImage(@Body form: any, @Path('companyId') id: string): Observable<any> { return null; }

  @GET('/companies/{companyId}/imageFile')
  @ResponseType('blob')
  @Headers(imgType)
  @Adapter(AuthHttpAdapter.imgUrlAdapter)
  public getImageOfCompany(@Path('companyId') id: string): Observable<any> { return null; }

  @POST('/companies/{tenantId}/image')
  @Produces(MediaType.FILE)
  public createCompanyImage(@Body form: any, @Path('tenantId') id: string): Observable<any> { return null; }

  /*
    public getUsersByRoleIdOrName(roleIdOrName: string): Observable<User[]> {
        const path = this._api + '/' + roleIdOrName + '/users';
        return this.getArray<User>(path);
    }

    public getUsersByCompanyId(companyId: string): Observable<User[]> {
        const path = this._api + '/companies/' + companyId + '/users';
        return this.getArray<User>(path);
    }

    getUserByName(name: string): Observable<User> {
        const path = this._api + '/' + name;
        return this.getObject<User>(path);
    }

    public getUserGroups(): Observable<UserGroup[]> {
        const path = this._api + '/usergroups';
        return this.getArray<UserGroup>(path);
    }

    public getRights(): Observable<Right[]> {
        const path = this._api + '/rights';
        return this.getArray<Right>(path);
    }

    public getRoles(): Observable<Role[]> {
        const path = this._api + '/roles';
        return this.getArray<Role>(path);
    } */

}
