import { EventEmitter, OnDestroy, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { FormItemBase } from '../form-item-base';
import { FormGroup } from '@angular/forms';
import { LngLat } from 'mapbox-gl';
import { DynamicMapComponent } from '../../map/dynamic-map/dynamic-map.component';
import { MapService } from '../../map/map.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { UtilityService } from '../../../utility/utility.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { DATE_PICKER_OUTPUT_FORMAT } from '../date-adapter/date-adapter';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
var DynamicFormItemComponent = /** @class */ (function () {
    function DynamicFormItemComponent(_cdr, _mapService, _utilityService, datepipe) {
        this._cdr = _cdr;
        this._mapService = _mapService;
        this._utilityService = _utilityService;
        this.datepipe = datepipe;
        this.onDateChange = new EventEmitter();
        this.onImageChange = new EventEmitter();
        this.subscriptions = [];
        this.geoWaypoints = new Subject();
        this.filteredOptions = new BehaviorSubject([]);
        this.selectedElements = new BehaviorSubject([]);
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    Object.defineProperty(DynamicFormItemComponent.prototype, "isValid", {
        get: function () {
            return this.form.get(this.controlKey).valid;
        },
        enumerable: true,
        configurable: true
    });
    DynamicFormItemComponent.prototype.ngOnInit = function () {
        this.setControlKey();
        this.setValues();
        this.setSubscription();
        this.customErrorStateMatcher = {
            isErrorState: function (control) {
                if (control) {
                    var hasInteraction = control.dirty || control.touched;
                    var isInvalid = hasInteraction ? control.invalid : false;
                    return isInvalid;
                }
                return false;
            }
        };
    };
    DynamicFormItemComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    };
    DynamicFormItemComponent.prototype.compareFn = function (option, value) {
        var returnvalue;
        if (value && value.id) {
            returnvalue = option.id === value.id || option === value.id;
        }
        else {
            returnvalue = option === value;
        }
        return returnvalue;
    };
    DynamicFormItemComponent.prototype.dateChange = function (event, dependsOn) {
        var dependsOnDateEvent = {
            depend: {
                key: dependsOn.key,
                isMin: dependsOn.isMin
            },
            value: event.value.toDate()
        };
        this.onDateChange.emit(dependsOnDateEvent);
    };
    DynamicFormItemComponent.prototype.jsonDate = function (event) {
        var date = moment(event.value).format(DATE_PICKER_OUTPUT_FORMAT);
        this.form.get(this.controlKey).setValue(date);
        // console.log(JSON.stringify(date));
    };
    // User selected a waypoint in the UI store it
    DynamicFormItemComponent.prototype.setLocation = function (waypoint) {
        var lat = waypoint.geoLatitude;
        var lng = waypoint.geoLongitude;
        var lnglat = new LngLat(lng, lat);
        if (this.map.singleMarker) {
            this.map.singleMarker.remove();
        }
        this.map.singleMarker = this._mapService.createMarker(lnglat, waypoint.name);
        this._mapService.addMarker(this.map.map, this.map.singleMarker);
        this._mapService.fitBound(this.map.map, [lnglat]);
        // Update the geo position
        this.form.get('geoLongitude').setValue(waypoint.geoLongitude);
        this.form.get('geoLatitude').setValue(waypoint.geoLatitude);
        // Update strings in form
        this.form.patchValue({
            country: waypoint.country,
            zipcode: waypoint.zipcode,
            region: waypoint.region,
            city: waypoint.city,
            street: waypoint.street,
            housenumber: waypoint.streetNumber,
        });
    };
    DynamicFormItemComponent.prototype.startDate = function (dt, stepSize) {
        if (!dt.value || dt.value === '') {
            var now = moment();
            return this._utilityService.ceilMoment(now, stepSize, 'minutes');
        }
        else {
            return dt.value;
        }
    };
    DynamicFormItemComponent.prototype.removeChip = function (selectElement) {
        var selectedElements = this.selectedElements.getValue();
        selectedElements = selectedElements.filter(function (el) { return el.id !== selectElement.id; }); // remove
        selectedElements.sort(this.sortSelectElementArray); // sort
        this.selectedElements.next(selectedElements); // update
        this.filteredElements.push(selectElement); // add
        this.filteredElements.sort(this.sortSelectElementArray); // sort
        this.setChiplistValue(selectedElements); // set values in ctrl
    };
    DynamicFormItemComponent.prototype.addChip = function (selectElement) {
        var selectedElement = this.filteredElements.filter(function (el) { return el.id === selectElement || el.id === selectElement.id; })[0]; // get object
        var selectedElements = this.selectedElements.getValue();
        selectedElements.push(selectedElement); // add
        selectedElements.sort(this.sortSelectElementArray); // sort
        this.filteredElements = this.filteredElements.filter(function (el) { return el !== selectedElement; }); // remove object from filtered list
        this.filteredElements.sort(this.sortSelectElementArray);
        this.selectedElements.next(selectedElements); // update
        this.setChiplistValue(selectedElements); // set values in ctrl
    };
    DynamicFormItemComponent.prototype.removeChipSimple = function (element) {
        var index = this.formItem.value.indexOf(element);
        if (index >= 0) {
            this.formItem.value.splice(index, 1);
        }
    };
    DynamicFormItemComponent.prototype.addChipSimple = function (event) {
        var input = event.input;
        var value = event.value;
        if ((value || '').trim()) {
            this.formItem.value.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    };
    DynamicFormItemComponent.prototype.setValues = function () {
        if (this.formItem.value && this.formItem.controlType === 'datepicker' && this.formItem.value != null) {
            var date = moment(this.formItem.value).format(DATE_PICKER_OUTPUT_FORMAT);
            this.form.get(this.controlKey).setValue(date);
        }
        else if (this.formItem.value && this.formItem.controlType === 'datetimepicker') {
            this.form.get(this.controlKey).setValue(moment(this.formItem.value));
        }
        else if (this.formItem.controlType === 'chiplist') {
            var selectedElementsInit_1 = this.formItem.selectedElements;
            var selectElements = this.formItem.selectElements;
            selectedElementsInit_1.sort(this.sortSelectElementArray);
            this.selectedElements.next(selectedElementsInit_1); // init value
            var filteredElements = selectElements.filter(function (el) {
                for (var _i = 0, selectedElementsInit_2 = selectedElementsInit_1; _i < selectedElementsInit_2.length; _i++) {
                    var initEl = selectedElementsInit_2[_i];
                    if (el.id === initEl.id) {
                        return false; // exclude object
                    }
                }
                return true; // include object
            });
            this.filteredElements = filteredElements.sort(this.sortSelectElementArray);
        }
        else if (!this.controlKey.includes('id')) {
            var formElement = this.form.get(this.controlKey);
            if (formElement) {
                // console.log(formElement);
                formElement.setValue(this.formItem.value);
            }
            else {
                console.log('FormItem with name ' + this.controlKey + ' not found');
            }
        }
    };
    DynamicFormItemComponent.prototype.setControlKey = function () {
        // check if nested group
        if (this.groupName) {
            this.controlKey = this.groupName + '.' + this.formItem.key;
        }
        else {
            this.controlKey = this.formItem.key;
        }
    };
    DynamicFormItemComponent.prototype.setSubscription = function () {
        var _this = this;
        if (this.formItem.controlType === 'map') {
            var mapControl = this.form.get(this.controlKey);
            this.subscriptions.push(mapControl.valueChanges.pipe(debounceTime(300)).subscribe(function (search) {
                if (search && search.length >= 1 && typeof search === 'string') {
                    _this._mapService.getGeocoding(search).subscribe(function (response) {
                        _this.geoWaypoints.next(response);
                    });
                }
            }));
        }
        else if (this.formItem.controlType === 'auto') {
            var autoCtrl = this.form.get(this.controlKey);
            var selectElements_1 = this.formItem.selectElements;
            autoCtrl.valueChanges.pipe(startWith(''), map(function (value) { return value ? value : ''; }), map(function (value) { return typeof value === 'string' ? value : value.name; }), map(function (value) { return value ? _this.filterOptions(selectElements_1, value) : selectElements_1; })).subscribe(function (value) {
                _this.filteredOptions.next(value);
            });
        }
        else if (this.formItem.controlType === 'chiplist') {
            var chipCtrl = this.form.get(this.controlKey);
            chipCtrl.valueChanges.pipe(startWith(''), map(function (value) { return value ? value : ''; }), map(function (value) { return typeof value === 'string' ? value : value.name; }), map(function (value) { return value ? _this.filterOptions(_this.filteredElements, value) : _this.filteredElements; })).subscribe(function (value) {
                _this.filteredOptions.next(value);
            });
        }
    };
    DynamicFormItemComponent.prototype.filterOptions = function (source, value) {
        return source.filter(function (el) {
            return el.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
    };
    DynamicFormItemComponent.prototype.setChiplistValue = function (selectedElements) {
        var chipCtrl = this.form.get(this.controlKey);
        var value = selectedElements.map(function (el) {
            return el.value;
        });
        // reset value
        this.chiplistInput.nativeElement.value = '';
        chipCtrl.setValue(null);
        chipCtrl.setValue(value);
    };
    DynamicFormItemComponent.prototype.sortSelectElementArray = function (a, b) {
        if (a.name > b.name) {
            return 1;
        }
        else if (a.name < b.name) {
            return -1;
        }
        else {
            return 0;
        }
    };
    DynamicFormItemComponent.prototype.onChangeImage = function (event) {
        var _this = this;
        if (event.target.files && event.target.files[0]) {
            var file_1 = event.target.files[0];
            var reader = new FileReader();
            var formReference_1 = this.form;
            var formItemElement_1 = this.formItem;
            reader.readAsDataURL(file_1);
            reader.onload = function (readerEvent) {
                formItemElement_1.value = readerEvent.target['result'];
                var chipCtrl = formReference_1.get(_this.controlKey);
                chipCtrl.setValue(readerEvent.target['result']);
                formReference_1.get('file').setValue(file_1);
                _this._cdr.detectChanges();
            };
        }
    };
    DynamicFormItemComponent.prototype.onChangeSelection = function (event) {
        this._cdr.detectChanges();
    };
    return DynamicFormItemComponent;
}());
export { DynamicFormItemComponent };
