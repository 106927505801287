/**
 * Available Languages in UI
 */
export enum Languages {
  EN,
  DE,
  ZH,
}

export class RegionalConfig {
  /**
   * name: translation string according to language JSONs
   * id: Use ISO 3166-1 alpha-2 code of country
   */
  static regions: any[] = [
    {
      id: 'DE',
      name: 'region.DE',
    },
    {
      id: 'GB',
      name: 'region.GB',
    },
    {
      id: 'US',
      name: 'region.US',
    }
  ];

  // get languages used by translation service as an array
  static languages: string[] = Object.keys(Languages).filter(e => isNaN(e[0]as any));
}
