import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthHttpService } from './auth-http.service';
import { AuthHttpResponseHandler } from './auth-httpResponseHandler.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class AuthHttpServiceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthHttpServiceModule,

      providers: [
        AuthHttpService,
        AuthHttpResponseHandler
      ]
    };
  }
}
