
export class API {
    static routes =
        {
            'authentication': 'authentication/api',
            'vehicles': 'coredatavehicle/api',
            'vehiclestatus': 'coredatavehiclestatus/api',
            'coredatabooking': 'coredatabooking/api',
            'booking' : 'booking/api',
            'companies': 'coredatacompany/api',
            'users': 'coredatauser/api',
            'userconfig': 'coredatauserconfiguration/api',
            'locations': 'coredatacompany/api',
            'pools': 'coredatavehicle/api',
            'accesstokens': 'coredataresourceaccesstoken/api',
            'kpi': 'coredatakpi/api',
            'mailer': 'mailer/api',
            'motown': 'backend',
            'mobiplaBooking': 'booking',
            'document': 'document/api'
        };
}
