// Format to query Open Route service to get Distance Time matrix
export class OpenrouteMatrixCoordinates {
  locations: number[][];
  destinations: number[];
  sources: number[];
  metrics: string[] = ['duration', 'distance'];
  units: string = 'km';
}

// Format of Distance Time matrix response received from Open Route service
export interface OpenRouteMatrixResponse {
  durations?: ((number)[] | null)[] | null;
  distances?: ((number)[] | null)[] | null;
  destinations?: (DestinationsEntityOrSourcesEntity)[] | null;
  sources?: (DestinationsEntityOrSourcesEntity)[] | null;
  metadata: Metadata;
}
export interface DestinationsEntityOrSourcesEntity {
  location?: (number)[] | null;
  snapped_distance: number;
}
export interface Metadata {
  attribution: string;
  service: string;
  timestamp: number;
  query: Query;
  engine: Engine;
}
export interface Query {
  locations?: ((number)[] | null)[] | null;
  profile: string;
  responseType: string;
}
export interface Engine {
  version: string;
  build_date: string;
  graph_date: string;
}
