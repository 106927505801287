import { Vehicle } from './vehicle';

export class Pool {
    id: string;
    name: string;
    contact: string;
    siteId: string;
    companyId: string;
    tenantId: string;
    deleted: boolean;
    operator: string;
    userGroupIds: Array<string>;
    vehicles?: Array<Vehicle>;
}
