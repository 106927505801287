import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Waypoint } from '../models/index';
import { GeoAdress } from './../models/geoAdress';

export class AuthHttpAdapter {

  static baseAdapter(res: HttpResponse<any>, adapterFn?: Function): any {
    if (res.status === 200 || res.status === 201 || res.status === 202) {
      try {
        const jsonRes = res.body;
        return adapterFn ? adapterFn.call(undefined, jsonRes) : jsonRes;
      } catch (e) {
        return res;
      }
    }
  }

  static arrayAdapter<T>(objects: any): Array<T> {
    return objects.map(object => object = <T>object);
  }

  static openrouteDirectionAdapter<T>(objects: any): any {
    // get GeoJson
    return objects.routes[0];
  }

  static openrouteMatrixAdapter<T>(objects: any): any {
    return objects;
  }

  static mapboxWaypointAdaper<T>(objects: any): Waypoint[] {
    const waypoints: Waypoint[] = [];
    objects.features.map(object => {
      let country;
      let region;
      let zipcode;
      if (object.context) {
        object.context.forEach(contextEl => {
          if (contextEl.id.indexOf('region') > -1) {
            region = contextEl.text;
          }
          if (contextEl.id.indexOf('country') > -1) {
            country = contextEl.text;
          }
          if (contextEl.id.indexOf('postcode') > -1) {
            zipcode = contextEl.text;
          }
        });
      }
      waypoints.push({
        id: undefined,
        zipcode: zipcode,
        region: region,
        country: country,
        name: object.place_name,
        geoLongitude: object.center[0],
        geoLatitude: object.center[1],
        userId: null
      });
    });
    return waypoints;
  }

  static geoAdressAdapter<T>(objects: GeoAdress[]): Waypoint[] {
    const waypoints: Waypoint[] = [];
    objects.map(newObject => {
      waypoints.push({
        id: undefined,
        userId: undefined,
        name: newObject.placeName,
        zipcode: newObject.postCode,
        region: newObject.region,
        country: newObject.country,
        city: newObject.city,
        street: newObject.street,
        streetNumber: newObject.streetNumber,
        geoLongitude: newObject.longitude,
        geoLatitude: newObject.latitude
      });
    });
    return waypoints;
  }

  static objectAdapter<T>(object: any): T {
    return object = <T>object;
  }

  static imgUrlAdapter(object: any): any {
    return URL.createObjectURL(object);
  }

  static pdfFileAdapter(object: any): any {
    const file = new Blob([object], { type: 'application/pdf' });
    return file;
  }

  static booleanToStringAdapter(object: any): any {
    const httpRequest: HttpRequest<any> = object as HttpRequest<any>;
    if (httpRequest) {
      for (const key1st in httpRequest.body) {
        if (httpRequest.body.hasOwnProperty(key1st)) {
          if (typeof httpRequest.body[key1st] === 'boolean') {
            if (httpRequest.body[key1st]) {
              // if true
              httpRequest.body[key1st] = 'true';
            } else {
              // if false
              httpRequest.body[key1st] = 'false';
            }
          } else if (typeof httpRequest.body[key1st] === 'object') {
            for (const key2nd in httpRequest.body[key1st]) {
              if (httpRequest.body[key1st].hasOwnProperty(key2nd)) {
                if (typeof httpRequest.body[key1st][key2nd] === 'boolean') {
                  if (httpRequest.body[key1st][key2nd]) {
                    // if true
                    httpRequest.body[key1st][key2nd] = 'true';
                  } else {
                    // if false
                    httpRequest.body[key1st][key2nd] = 'false';
                  }
                }
              }
            }
          }
        }
      }
      return httpRequest ? httpRequest : object;
    }
  }
}

