import { Rights } from '../share/models/index';

export class SidebarCategory {
    name: string;
    items: SidebarItem[];
}

export class SidebarItem {
    name: string;
    icon: string;
    rights: string[];
    path: string;
    isEfsDevModeElement?: boolean;
}

export class Sidebar {

    constructor() { }

    static sidebarItems: SidebarCategory[] = [

        {
            name: 'router.headers.mobility',
            items: [
                {
                    name: 'router.dashboard',
                    icon: 'icons',
                    rights: [Rights.manage_all_tenants],
                    path: 'dashboard',
                },
                {
                    name: 'router.booking',
                    icon: 'briefcase',
                    rights: [Rights.book_and_use_vehicles, Rights.manage_bookings],
                    path: 'booking',
                },
                {
                    name: 'router.vehicle',
                    icon: 'car',
                    rights: [Rights.manage_vehicles],
                    path: 'vehicle',
                },
                {
                    name: 'router.pool',
                    icon: 'exclude',
                    rights: [Rights.manage_pool_as_company, Rights.manage_companies],
                    path: 'pool',
                },
                {
                    name: 'router.accessToken',
                    icon: 'key',
                    rights: [Rights.manage_access_keys],
                    path: 'accessToken',
                },
                {
                    name: 'router.chargingstation',
                    icon: 'power',
                    rights: [Rights.view_charging_stations, Rights.view_whitelist, Rights.view_transactions],
                    path: 'chargingstation',
                },
                {
                    name: 'router.invoice',
                    icon: 'receipt',
                    rights: [Rights.view_invoice],
                    path: 'invoice',
                },
                {
                    name: 'router.report',
                    icon: 'files',
                    rights: [Rights.view_report],
                    path: 'report',
                },
                {
                    name: 'router.waypoint',
                    icon: 'map-marker-user',
                    rights: [Rights.view_user_favorite_waypoint],
                    path: 'waypoints',
                },

            ]
        },
        {
            name: 'router.headers.marketplace',
            items: [
                {
                    name: 'router.servicestore',
                    icon: 'location',
                    rights: [Rights.manage_site_as_company],
                    path: 'servicestore',
                },
                {
                    name: 'router.developer',
                    icon: 'location',
                    rights: [Rights.manage_site_as_company],
                    path: 'developer',
                }]
        },
        {
            name: 'router.headers.manage',
            items: [
                {
                    name: 'router.user',
                    icon: 'users',
                    rights: [Rights.manage_all_user, Rights.manage_user_as_tenant, Rights.manage_user_as_company, Rights.manage_rights],
                    path: 'user',
                },
                {
                    name: 'router.company',
                    icon: 'city',
                    rights: [Rights.manage_companies],
                    path: 'company',
                },
                {
                    name: 'router.location',
                    icon: 'location',
                    rights: [Rights.manage_site_as_company],
                    path: 'location',
                },
                {
                    name: 'router.tenantpreferences',
                    icon: 'site-map',
                    rights: [Rights.manage_all_tenants, Rights.manage_tenant],
                    path: 'additional',
                }
            ]
        }];
}
