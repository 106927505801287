import { Subscription } from 'rxjs';
import { LoaderState, RootLoadingService } from './root-loading.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root-loading',
  templateUrl: './root-loading.component.html',
  styleUrls: ['./root-loading.component.scss']
})
export class RootLoadingComponent implements OnInit {

  private _loading: boolean;
  constructor(private rootLoadingService: RootLoadingService) {
    this._loading = false;
  }

  ngOnInit() {
    this.rootLoadingService.loaderState
      .subscribe((state: LoaderState) => {
        this._loading = state.show;
      });
  }

  get loading(): boolean {
    return this._loading;
  }

}
