import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HttpClientService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      });
  }

  get(path: string): Observable<any> {
    return this.http.get(path, {
      headers: this.headers
    });
  }

  post(path: string, body: Object): Observable<any> {
    return this.http.post(path, body, {
      headers: this.headers
    });
  }

  put(path: string, body: Object): Observable<any> {
    return this.http.put(path, body, {
      headers: this.headers
    });
  }

  delete(path: string): Observable<any> {
    return this.http.delete(path, {
      headers: this.headers
    });
  }
}
