import { AuthHttpService, MediaType } from './auth-http.service';
import { methodBuilder, paramBuilder } from './utils.service';

/* *********************************************
 * Class decorators
 * *********************************************/

/**
 * Set the base URL of REST resource
 * @param {String} url - base URL
 */
export function BaseUrl(url: string) {
    return function <TFunction extends Function>(Target: TFunction): TFunction {
        Target.prototype.getBaseUrl = () => url;
        return Target;
    };
}

/**
 * Set default headers for every method of the HttpService
 * @param {Object} headers - deafult headers in a key-value pair
 */
export function DefaultHeaders(headers: any) {
    return function <TFunction extends Function>(Target: TFunction): TFunction {
        Target.prototype.getDefaultHeaders = () => headers;
        return Target;
    };
}

/* *********************************************
 * Method decorators
 * *********************************************/

/**
 * GET method
 * @param {string} url - resource url of the method
 */
export const GET = methodBuilder('GET');
/**
 * POST method
 * @param {string} url - resource url of the method
 */
export const POST = methodBuilder('POST');
/**
 * PUT method
 * @param {string} url - resource url of the method
 */
export const PUT = methodBuilder('PUT');
/**
 * DELETE method
 * @param {string} url - resource url of the method
 */
export const DELETE = methodBuilder('DELETE');
/**
 * HEAD method
 * @param {string} url - resource url of the method
 */
export const HEAD = methodBuilder('HEAD');


/**
 * Set custom headers for a REST method
 * @param {Object} headersDef - custom headers in a key-value pair
 */
export function Headers(headersDef: any) {
    return function (target: AuthHttpService, propertyKey: string, descriptor: any) {
        descriptor.headers = headersDef;
        return descriptor;
    };
}

/**
 * Defines the media type(s) that the methods can produce
 * @param MediaType producesDef - MediaType to be sent
 */
export function Produces(producesDef: MediaType) {
    return function (target: AuthHttpService, propertyKey: string, descriptor: any) {
        descriptor.isJSON = producesDef === MediaType.JSON;
        descriptor.isFormData = producesDef === MediaType.FORM_DATA;
        descriptor.isByteArray = producesDef === MediaType.BYTE_ARRAY;
        descriptor.isMotown = producesDef === MediaType.MOTOWN;
        descriptor.isFile = producesDef === MediaType.FILE;
        return descriptor;
    };
}

/**
 * Defines the adapter function to modify the API response suitable for the app
 * @param TFunction adapterFn - function to be called
 */
export function Adapter(adapterFn: Function) {
    return function (target: AuthHttpService, propertyKey: string, descriptor: any) {
        descriptor.adapter = adapterFn || null;
        return descriptor;
    };
}

export function RequestAdapter(adapterFn: Function) {
    return function (target: AuthHttpService, propertyKey: string, descriptor: any) {
        descriptor.reqAdapter = adapterFn || null;
        return descriptor;
    };
}

export function APIKey(apiKey: string) {
    return function (target: AuthHttpService, propertyKey: string, descriptor: any) {
        descriptor.apiKey = apiKey || null;
        return descriptor;
    };
}

export function API(api: string) {
    return function (target: AuthHttpService, propertyKey: string, descriptor: any) {
        descriptor.api = api || null;
        descriptor.isExtern = true;
        return descriptor;
    };
}

export function FULLAPI(isFullAPI: boolean) {
    return function (target: AuthHttpService, propertyKey: string, descriptor: any) {
        descriptor.isFullAPI = true;
        return descriptor;
    };
}


export function Params(params: { [key: string]: string }) {
    return function (target: AuthHttpService, propertyKey: string, descriptor: any) {
        descriptor.params = params || null;
        return descriptor;
    };
}

export function Extern(isExtern: boolean) {
    return function (target: AuthHttpService, propertyKey: string, descriptor: any) {
        descriptor.isExtern = isExtern || null;
        return descriptor;
    };
}

export function ResponseType(responseType: string) {
    return function (target: AuthHttpService, propertyKey: string, descriptor: any) {
        descriptor.responseType = responseType || null;
        return descriptor;
    };
}

/* *********************************************
 * Parameter decorators
 * *********************************************/

/**
 * Path constiable of a method's url, type: string
 * @param {string} key - path key to bind value
 */
export const Path = paramBuilder('Path');
/**
 * Query value of a method's url, type: string
 * @param {string} key - query key to bind value
 */
export const Query = paramBuilder('Query');
/**
 * Body of a REST method, type: key-value pair object
 * Only one body per method!
 */
export const Body = paramBuilder('Body')('Body');
/**
 * Custom header of a REST method, type: string
 * @param {string} key - header key to bind value
 */
export const Header = paramBuilder('Header');
