import { JwtService } from './../../../authentication/jwt-helper.service';
import { Injectable } from '@angular/core';
import { CanLoad, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivateChild } from '@angular/router/src/interfaces';
import { User } from '../../models';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanLoad, CanActivateChild {

  constructor(
    private _router: Router,
    private _jwtService: JwtService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.loggedIn()) {
      return true;
    } else {
      this._router.navigate(['/login']);
      return false;
    }
  }

  loggedIn() {
    return (this.hasToken() && !this._jwtService.isTokenExpired() && this.hasUserObject());
  }

  canLoad() {
    if (this.loggedIn()) {
      return true;
    } else {
      // Reroute to login as the user is NOT logged in!
      this._router.navigate(['/login']);
      return false;
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivateRoute(next.data.rights);
  }

  hasUserObject() {
    const userObject = localStorage.getItem('user');
    return (userObject !== undefined);
  }

  hasAppContext() {
    const appContext = localStorage.getItem('appContext');
    return (appContext !== undefined && appContext != null && appContext !== 'null');
  }

  hasToken() {
    const token = localStorage.getItem('token');
    return (token !== undefined && token != null && token !== 'null');
  }

  canActivateRoute(rights: string[]): boolean {
    const user: User = JSON.parse(localStorage.getItem('user'));
    let canActivate = false;
    if (!rights || rights.length === 0) {
      canActivate = true;
    } else if (user) {
      user.role.rights.forEach(userRight => {
        rights.forEach(rightName => {
          if (userRight.name === rightName) {
            canActivate = true;
          }
        });
      });
    }
    return canActivate;
  }

  hasRight(rights: string[]): boolean {
    const user: User = JSON.parse(localStorage.getItem('user'));
    let canActivate = false;
    if (user && rights && rights.length > 0) {
      user.role.rights.forEach(userRight => {
        rights.forEach(rightName => {
          if (userRight.name === rightName) {
            canActivate = true;
          }
        });
      });
    }
    return canActivate;
  }

  hasSingleRight(right: string): boolean {
    return this.hasRight([right]);
  }
}
