import { Tenant, Right } from './index';

/* export const RoleNames = {
    superAdmin: 'Superadmin',
    tenantAdmin: 'Tenantadmin',
    disponent: 'Disponent',
    user: 'User'
}; */

export class Role {
    consistent: Boolean;
    deleted: Boolean;
    roleName: string;
    id: string;
    rights: Array<Right>;
    tenant: Tenant;
}
