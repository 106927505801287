import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationService } from './notification/notification.service';
import { NotificationComponent } from './notification/notification/notification.component';
import { DialogModule } from './dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { UtilityService } from './utility.service';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    TranslateModule
  ],
  declarations: [
    NotificationComponent
  ],
  providers: [
    NotificationService,
    UtilityService
  ],
  exports: [TranslateModule],
  entryComponents: [
    NotificationComponent
  ]
})
export class UtilityModule {}
