import { UserAPIService } from './../modules/user/user.api.service';
import { AuthenticationAPIService, UserRegistration } from './authentication.api.service';

import { HttpClientService } from '../share/http/http-client.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';
import { Injectable, NgZone } from '@angular/core';
import { ConfigService } from '../app-config.service';
import { User } from '../share/models/index';
import { DialogService } from '../share/utility/dialog/dialog.service';
import { NotificationService } from '../share/utility/notification/notification.service';
import { RootLoadingService } from '../share/utility/loading/root-loading.service';
import { map } from 'rxjs/operators';
import { BootController } from '../../boot-control';

@Injectable()
export class AuthenticationService {

  constructor(
    private _httpClient: HttpClientService,
    private _router: Router,
    private _configService: ConfigService,
    private _dialogService: DialogService,
    private _notificationService: NotificationService,
    private _loadingService: RootLoadingService,
    private _authAPI: AuthenticationAPIService,
    private _userAPI: UserAPIService,
    private _ngZone: NgZone) {
  }

  login(username: string, password: string): Observable<User> {
    const api = this._configService.getAPI('authentication') + '/authenticate';
    return this._httpClient.post(api, { username: username, password: password }).pipe(
      map((json: { jwt: string, user: any }) => {
        const token = json.jwt;
        const user = json.user;
        if (token && user) {
          localStorage.setItem('token', token);
          return user;
        } else {
          throw new Error();
        }
      }));
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('userconfig');
    localStorage.removeItem('appContext');
    localStorage.removeItem('chargingToken');
    localStorage.removeItem('originalToken');
    localStorage.removeItem('originalUser');

    // Triggers the reboot in main.ts
    this._ngZone.runOutsideAngular(() => BootController.getbootControl().restart());
    this._router.navigate(['/login']);
  }

  timeout() {
    const title = 'Session abgelaufen';
    const message = 'Sie wurden aufgrund von Inaktivität automatisch vom System abgemeldet.';
    this.logout();
    this._dialogService.confirm(title, message).subscribe(confirm => {
      // do nothing
    });
  }

  // refactor -> use authAPI
  sendPassword(username: string): Observable<Response> {
    const api = this._configService.getAPI('authentication') + '/users/' + username + '/passwordrequesttoken';
    return this._httpClient.get(api);
  }

  // refactor -> use authAPI
  resetPassword(username: string, token: string, newPassword: string): Observable<Response> {
    const api = this._configService.getAPI('authentication') + '/users/' + username + '/password?token=' + token;
    this._loadingService.showLoading();
    return this._httpClient.put(api, { newPassword: newPassword });
  }

  registerSimpleUser(userRegistration: UserRegistration) {
    return this._authAPI.registerSimpleUser(userRegistration);
  }

  getInvitationToken(inviteToken: string) {
    return this._authAPI.getInvitationInfo(inviteToken);
  }

}
