

export class DriversLicense {
    checkedBy: string;
    checkedDate: Date;
    constistent: boolean;
    country: string;
    deleted: boolean;
    expiresDate: Date;
    id: string;
    issuedDate: Date;
    licenseCategories: LicenseCategories[];
    number: string;
    tenantId: string;
    valid: boolean;
    companyId: string;
}


export enum LicenseCategories {
    'A', 'A1', 'A2', 'AM',
    'Mofa',
    'B', 'BE', 'B 96',
    'C1', 'C1E', 'D1', 'D1E', 'D', 'DE',
    'L', 'T'
  }
