<!-- <div class="login">
  <div class="login-box">
    <form id="login-form" #f="ngForm" novalidate>
      <div id="login-logo">
        <img src="/assets/img/logo.svg" alt="FirmenLogo">
      </div>
      <div class="input-icon">
        <i class="icon icon-user"></i>
        <!--todo: email or password-->
<!--   <input [(ngModel)]="model.username" type="text" placeholder="Benutzername"  name="username"#username="ngModel" autofocus required />
        <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
      </div>
      <div class="input-icon">
        <i class="icon icon-lock2"></i>
        <input [(ngModel)]="model.password" type="password" placeholder="Passwort" name="password" #password="ngModel" required autofocus />
        <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
      </div> -->

<div class="login" [ngStyle]="{'background-image': 'url(./assets/img/intro_0' + random + '.jpg)'}">
  <div class="login-box">
    <div id="login-logo">
      <img src="/assets/img/logo.svg" alt="FirmenLogo">
    </div>    
    <form #loginForm="ngForm">
      <h2 *ngIf="!pwforgotten">Login</h2>
      <mat-form-field *ngIf="!pwforgotten" [floatLabel]="'never'">
        <input matInput [(ngModel)]="model.username" name="username" placeholder="Nutzername oder Email eingeben"
          #username="ngModel" minlength="3" required>
        <i class="icon icon-user" matSuffix></i>
        <mat-error *ngIf="username.invalid && username.errors.required">Pflichtfeld</mat-error>
        <mat-error *ngIf="username.invalid && username.errors.minlength">Mindestlänge 3 Zeichen</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="!pwforgotten" [floatLabel]="'never'">
        <input matInput [(ngModel)]="model.password" name="password" placeholder="Passwort eingeben"
          [type]="!hide ? 'password' : 'text'" #password="ngModel" minlength="6" required>
        <i *ngIf="!hide" class="icon icon-eye" matSuffix (click)="hide = !hide"></i>
        <i *ngIf="hide" class="icon icon-eye-blocked" matSuffix (click)="hide = !hide"></i>
        <mat-error *ngIf="password.invalid && password.errors.required">Pflichtfeld</mat-error>
        <mat-error *ngIf="password.invalid && password.errors.minlength">Mindestlänge 6 Zeichen</mat-error>
      </mat-form-field>

      <h2 *ngIf="pwforgotten">Passwort anfordern</h2>
      <div class="info-text" *ngIf="pwforgotten">
        Bitte geben Sie Ihren Nutzernamen oder Email-Adresse ein, um ein neues Passwort anzufordern:
      </div>

      <mat-form-field *ngIf="pwforgotten" [floatLabel]="'never'">
        <input matInput [(ngModel)]="model.usernamePw" name="usernamePw" placeholder="Nutzername oder Email eingeben"
          #usernamePw="ngModel" minlength="6" required>
        <i class="icon icon-user" matSuffix (click)="hide = !hide"></i>
        <mat-error *ngIf="usernamePw.invalid && usernamePw.errors.required">Pflichtfeld</mat-error>
        <mat-error *ngIf="usernamePw.invalid && usernamePw.errors.minlength">Mindestlänge 6 Zeichen</mat-error>
      </mat-form-field>
      <div id="login-error" *ngIf="error && !pwforgotten">
        {{error}}
      </div>
      <div id="login-error" *ngIf="passwordError && pwforgotten">
        {{passwordError}}
      </div>
      <button *ngIf="pwforgotten" mat-raised-button color="accent" type="submit"
        (click)="loginForm.valid ? sendPassword($event) : loginForm.touch">Passwort zurücksetzen</button>
      <button *ngIf="!pwforgotten" mat-raised-button color="accent" type="submit"
        (click)="loginForm.valid ? login() : loginForm.touch">Login</button>
      <div class="forgot-password" *ngIf="!pwforgotten" (click)="triggerPassword()">Passwort vergessen?</div>
      <div class="forgot-password" *ngIf="pwforgotten" (click)="triggerPassword()">Abbrechen</div>
    </form>
  </div>
</div>