import { User } from './user';

export class Invite {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    code: string;
    tenantId: string;
    companyId: string;
    userId: string;
    expiresAt: Date;
    user?: User;
  }
