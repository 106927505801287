export class VehicleCategory {
    id: string;
    name: string;
    tenantId: string;
    companyId: string;
    vehicleCategory: string;
    pictureResource: string;
    imageUrl: string;
    image: Blob;
}
