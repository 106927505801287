import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor(
    private _jwtHelper: JwtHelperService,
  ) { }

  isTokenExpired() {
    return this._jwtHelper.isTokenExpired();
  }
}
