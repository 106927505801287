
import { Component, Inject, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { VehicleService } from '../../../../modules/vehicle/vehicle-main/vehicle-main.service';
import { VehicleAPIService } from '../../../../modules/vehicle/vehicle.api.service';

@Component({
  selector: 'app-dialog-file-picker',
  templateUrl: './dialog-file-picker.component.html',
  styleUrls: ['./dialog-file-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogFilePickerComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogFilePickerComponent>,
    private _vehicleAPI: VehicleAPIService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  file: File;

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  // Readout file from FileList from input
  onFileInput(files: FileList | null): void {
    if (files) {
      this.file = files.item(0);
    }
  }
}
