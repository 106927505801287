import {
  Adapter,
  APIKey,
  Body,
  DELETE,
  GET,
  POST,
  PUT,
  FULLAPI,
  Headers,
  Query,
  ResponseType
  } from '../../share/http/auth-http.decorator';
import { AuthHttpAdapter } from '../../share/http/auth-http.adapter';
import { AuthHttpService } from '../../share/http/auth-http.service';
import { Booking, Vehicle } from '../../share/models/index';
import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { MediaType, Path, Produces } from '../../share/http/index';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';
import { VehicleModel } from '../../share/models';
import { VehicleCategory } from '../../share/models';
import { VehicleStatus } from '../../../../core/vehicleStatus';
import { AvailableVehicleCategory } from '../../share/models/availableVehicleCategory';

export interface IVehicleRequest {
  saveVehicle(vehicle: string);
  updateVehicle(vehicle: string): Observable<Response>;
  deleteVehicle(vehicle: string): Observable<Response>;
  getVehiclesByTenant(tenantId: string): Observable<Vehicle[]>;
  getActiveVehicleByVehicleCategory(vehicleCategory: string): Observable<Vehicle>;
  getVehicleByVehicleCategory(licensePlateNumber: string): Observable<Vehicle>;
  getVehicleByLicensePlateNumber(licensePlateNumber: string): Observable<Vehicle>;
  getActiveVehiclesForBooking(bookingId: string): Observable<Vehicle[]>;
  getVehicleByVin(vin: string): Observable<Vehicle>;
  getVehicleByVehicleCategory(vehicleCategory: string): Observable<Vehicle>;
  getActiveVehicleCategories(tenantId: string): Observable<string>;
  getVehiclesBySite(siteId: string): Observable<Vehicle[]>;
  // getAvailableVehiclesForBooking(booking: Booking, filterByCategory: boolean): Observable<any>;
}

export interface IVehicleModelRequest {
  getVehicleModelsByTenant(tenantId: string): Observable<VehicleModel[]>;
  getVehicleCategories(): Observable<any>;
}

const csvFiles = { 'Content-Type': 'multipart/form-data' };
const imgType = { 'Accept': 'image/webp,image/png,image/*,*/*;q=0.8' };

@Injectable()
export class VehicleAPIService extends AuthHttpService {
  // implements IVehicleRequest, IVehicleModelRequest  {

  private _api = this.configService.getAPI('vehicles');

  @POST('/vehicles')
  @Produces(MediaType.FORM_DATA)
  public createVehicle( @Body form: FormGroup): Observable<any> { return null; }

  @PUT('/vehicles/{id}')
  @Produces(MediaType.FORM_DATA)
  public updateVehicle( @Body form: FormGroup, @Path('id') id: string): Observable<any> { return null; }

  @PUT('/vehicles/{id}')
  public updateVehicleObject(@Body vehicle: Vehicle, @Path('id') id: string): Observable<any> { return null; }

  @GET('/vehicles')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getVehicles(): Observable<Vehicle[]> { return null; }

  @DELETE('/vehicles/{id}')
  public deleteVehicle( @Path('id') id: string): Observable<any> { return null; }

  @POST('/vehicleModels')
  public createVehicleModel( @Body object: any): Observable<any> { return null; }

  @PUT('/vehicleModels/{id}')
  public updateVehicleModel( @Body object: any, @Path('id') id: string): Observable<any> { return null; }

  @GET('/vehicleModels')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getVehicleModels(): Observable<VehicleModel[]> { return null; }

  @DELETE('/vehicleModels/{id}')
  public deleteVehicleModel( @Path('id') id: string): Observable<any> { return null; }

  @GET('/vehicleModels/{id}/images')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getVehicleModelImages(@Path('id') id: string): Observable<string[]> { return null; }

  @POST('/vehicleModels/{id}/images')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  @Produces(MediaType.BYTE_ARRAY)
  public updateVehicleModelImages(@Path('id') id: string, @Body object: Blob[]): Observable<any> { return null; }

  // get default image
  @GET('/vehicleModels/{id}/image')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getVehicleModelMainImage(@Path('id') id: string): Observable<Blob> { return null; }

  @GET('/vehicleModels/{id}/images/{imageId}')
  public getVehicleModelImage(@Path('id') id: string, @Path('imageId') imageId: string): Observable<Blob[]> { return null; }

  @DELETE('{imageURI}')
  @FULLAPI(true)
  public deleteVehicleModelImage(@Path('imageURI') imageUri: string): Observable<any> { return null; }

  // VEHICLE CATEGORY APIs

  @POST('/vehicleCategories')
  @Produces(MediaType.FORM_DATA)
  public createVehicleCategory( @Body object: FormGroup): Observable<any> { return null; }

  @PUT('/vehicleCategories/{id}')
  @Produces(MediaType.FORM_DATA)
  public updateVehicleCategory( @Body object: FormGroup, @Path('id') id: string): Observable<any> { return null; }

  @GET('/vehicleCategories')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getVehicleCategories(): Observable<VehicleCategory[]> { return null; }

  @DELETE('/vehicleCategories/{id}')
  public deleteVehicleCategory( @Path('id') id: string): Observable<any> { return null; }

  @GET('/vehicleCategories/{id}/images')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getVehicleCategoryImages(@Path('id') id: string): Observable<string[]> { return null; }

  @POST('/vehicleCategories/{id}/images?override=true')
  @Produces(MediaType.FILE)
  public updateVehicleCategoryImages(@Body form: any, @Path('id') id: string ): Observable<any> { return null; }

  // get default image
  @GET('/vehicleCategories/{id}/images')
  @ResponseType('blob')
  @Headers(imgType)
  @Adapter(AuthHttpAdapter.imgUrlAdapter)
  public getVehicleCategoryMainImage(@Path('id') id: string): Observable<any> { return null; }

  @GET('/vehicleCategories/{id}/images/{imageId}')
  public getVehicleCategoryImage(@Path('id') id: string, @Path('imageId') imageId: string): Observable<Blob[]> { return null; }

  @DELETE('{imageURI}')
  @FULLAPI(true)
  public deleteVehicleCategoryImage(@Path('imageURI') imageUri: string): Observable<any> { return null; }

  // ELSE


  @POST('/booking/vehicleCategories')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getAvailableVehicleCategories(@Body booking: Booking): Observable<AvailableVehicleCategory[]> { return null; }


  @GET('/vehicles/{id}/images')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getVehicleImages(@Path('id') id: string): Observable<string[]> { return null; }

  @POST('/vehicles/{id}/images')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  @Produces(MediaType.BYTE_ARRAY)
  public updateVehicleImages(@Path('id') id: string, @Body object: Blob[]): Observable<any> { return null; }

  @GET('/vehicles/{id}/images/{imageId}')
  public getVehicleImage(@Path('id') id: string, @Path('imageId') imageId: string): Observable<Blob[]> { return null; }

  @DELETE('{imageURI}')
  @FULLAPI(true)
  public deleteVehicleImage(@Path('imageURI') imageUri: string): Observable<any> { return null; }

  @GET('/statuses')
  @APIKey('vehiclestatus')
  public getVehicleStatuses(): Observable<VehicleStatus[]> {return null; }

  @POST('/import/csv')
  @Produces(MediaType.FILE)
  public importCsvFile(@Body form: any): Observable<any> { return null; }

  /* public updateVehicle(vehicle: string): Observable<Response> {
    try {
      const vehicleObject = <Vehicle>JSON.parse(vehicle);
      const path = this._api + '/vehicles/' + vehicleObject.id;
      const body = JSON.stringify(vehicle);
      return this.putObject(path, body);
    } catch (error) {
      console.log(error);
    }
  }

  public deleteVehicle(vehicle: string): Observable<Response> {
    try {
      const vehicleObject = <Vehicle>JSON.parse(vehicle);
      const path = this._api + '/vehicles/' + vehicleObject.id;
      return this.delObject(path);
    } catch (error) {
      console.log(error);
    }
  }

  // Implements IVehicleRequest
  public getVehicles(): Observable<Vehicle[]> {
    const path = this._api + '/vehicles';
    return this.getArray<Vehicle>(path);
  }
 */

  /*
    public getVehiclesByTenant(tenantId: string): Observable<Vehicle[]> {
      const path = this._api + '/vehicles/tenants/' + tenantId + '/vehicles';
      return this.getArray<Vehicle>(path);
    }

    public getActiveVehicleByVehicleCategory(vehicleCategory: string): Observable<Vehicle> {
      const path = this._api + '/vehicles?active=true&category=' + vehicleCategory;
      return this.getObject<Vehicle>(path);
    }

    public getVehicleByVehicleCategory(vehicleCategory: string): Observable<Vehicle> {
      const path = this._api + '/vehicles?category=' + vehicleCategory;
      return this.getObject<Vehicle>(path);
    }

    public getVehicleByLicensePlateNumber(licensePlateNumber: string): Observable<Vehicle> {
      const path = this._api + '/vehicles?licensePlateNumber=' + licensePlateNumber;
      return this.getObject<Vehicle>(path);
    }

    public getActiveVehiclesForBooking(bookingId: string): Observable<Vehicle[]> {
      const path = this._api + '/vehicles?active=true&singleBookingId=' + bookingId;
      return this.getArray<Vehicle>(path);
    }

    public getVehicleByVin(vin: string): Observable<Vehicle> {
      const path = this._api + '/vehicles?vin=' + vin;
      return this.getObject<Vehicle>(path);
    }

    public getActiveVehicleCategories(tenantId: string): Observable<string> {
      const path = this._api + '/tenants/' + tenantId + '/categories';
      return this.getObject<string>(path);
    }

    public getVehiclesBySite(siteId: string): Observable<Vehicle[]> {
      const path = this._api + '/sites/' + siteId + '/categories';
      return this.getArray<Vehicle>(path);
    }

    public getVehiclesByPool(poolId: string): Observable<Vehicle[]> {
      const path = this._api + '/pools/' + poolId + '/vehicles';
      return this.getArray<Vehicle>(path);
    }

    public updateVehiclesByPool(vehicles: Array<Vehicle>, poolId: string): Observable<Vehicle[]> {
      const path = this._api + '/pools/' + poolId + '/vehicles';
      return this.getArray<Vehicle>(path);
    }

    public getAvailableVehiclesForBooking(booking: Booking, filterByCategory: boolean = true): Observable<Vehicle[]> {
      // was post before
      const path = this._api + '/booking/vehicles?filterByCategory=' + filterByCategory;
      return this.getArray<Vehicle>(path);
    }

    // Implements IVehicleModelRequest
    public getVehicleModelsByTenant(tenantId: string): Observable<VehicleModel[]> {
      const path = this._api + '/tenants/' + tenantId + '/vehicleModels';
      return this.getArray<VehicleModel>(path);
    }

    public getVehicleCategories(): Observable<string[]> { */
  /*  const path = API.vehicles + '/vehicleModelsCategories';
   return this.getArray<string>(path); */

  /*  return null;
 }

 private getData(path: string): Observable<any> {
   return this.getObject<any>(path);
 }

 public getFuelTypes(): Observable<string[]> {
   // const path = API.vehicles + '/fuelType';
   // return this.getArray<string>(path);
   return null;
 } */
}

