import * as i0 from "@angular/core";
// see manual here https://github.com/WebDevTmas/moment-round
var UtilityService = /** @class */ (function () {
    function UtilityService() {
        this.floorMoment = function (mom, precision, key) {
            return this.roundMoment(mom, precision, key, 'floor');
        };
    }
    UtilityService.prototype.roundMoment = function (mom, precision, key, direction) {
        if (typeof direction === 'undefined') {
            direction = 'round';
        }
        var keys = ['hours', 'minutes', 'seconds', 'milliseconds'];
        var maxValues = [24, 60, 60, 1000];
        var value = 0;
        var rounded = false;
        var subRatio = 1;
        var maxValue;
        for (var i in keys) {
            if (key.hasOwnProperty(i)) {
                var k = keys[i];
                if (k === key) {
                    value = mom.get(key);
                    maxValue = maxValues[i];
                    rounded = true;
                }
                else if (rounded) {
                    subRatio *= maxValues[i];
                    value += mom.get(k) / subRatio;
                    mom.set(k, 0);
                }
            }
        }
        value = Math[direction](value / precision) * precision;
        value = Math.min(value, maxValue);
        mom.set(key, value);
        return mom;
    };
    UtilityService.prototype.roundNumber = function (number, precision, direction) {
        if (typeof direction === 'undefined') {
            direction = 'round';
        }
        return Math[direction](number / precision) * precision;
    };
    UtilityService.prototype.ceilMoment = function (mom, precision, key) {
        return this.roundMoment(mom, precision, key, 'ceil');
    };
    UtilityService.prototype.ceil = function (number, precision) {
        return this.roundNumber(number, precision, 'ceil');
    };
    UtilityService.prototype.floor = function (number, precision) {
        return this.roundNumber(number, precision, 'floor');
    };
    UtilityService.prototype.sortArrayAlphabetically = function (a, b) {
        var aLow = a['name'].toLowerCase();
        var bLow = b['name'].toLowerCase();
        if (aLow > bLow) {
            return 1;
        }
        else if (aLow < bLow) {
            return -1;
        }
        return 0;
    };
    UtilityService.ngInjectableDef = i0.defineInjectable({ factory: function UtilityService_Factory() { return new UtilityService(); }, token: UtilityService, providedIn: "root" });
    return UtilityService;
}());
export { UtilityService };
