<div [formGroup]="form" [hidden]="formItem.hidden">
  <!-- IMPORTANT: changes need to be applied to both parts of the code! -->
  <!-- the code is duplicated because the formGroupName needs to be set only on the one part -->
  <div *ngIf="groupName" [formGroupName]="groupName">
    <ng-container [ngSwitch]="formItem.controlType">
      <div *ngSwitchCase="'textbox'">
        <mat-form-field>
          <input matInput [errorStateMatcher]="customErrorStateMatcher" [type]="formItem.type"
            [formControlName]="formItem.key" name="formItem.key" [placeholder]="formItem.label | translate "
            [value]="formItem.value ? (formItem.pipeObjects ? (formItem.value | idToName:formItem.pipeObjects) : formItem.value) : null"
            [required]="formItem.required" [readonly]="formItem.readOnly" autocomplete="off">
          <mat-error>{{'dynamicItem.error' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'textfield'" class="textfield">
        <mat-form-field>
          <textarea matInput [errorStateMatcher]="customErrorStateMatcher" [type]="formItem.type"
            [formControlName]="formItem.key" name="formItem.key" [placeholder]="formItem.label | translate "
            [value]="formItem.value ? (formItem.pipeObjects ? (formItem.value | idToName:formItem.pipeObjects) : formItem.value) : null"
            [required]="formItem.required" [readonly]="formItem.readOnly" autocomplete="off">
          </textarea>
          <mat-error>{{'dynamicItem.error' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'datepicker'">
        <mat-form-field>
          <input matInput [matDatepicker]="dp" [min]="formItem.minDate" [max]="formItem.maxDate"
            [placeholder]="formItem.label | translate" [formControlName]="formItem.key"
            (dateChange)="formItem.dependsOn ? dateChange($event, formItem.dependsOn) : null; jsonDate($event)"
            [readonly]="formItem.readOnly">
          <mat-datepicker-toggle matSuffix [for]="dt"
            [disabled]="form.controls[groupName].controls[formItem.key].disabled">
          </mat-datepicker-toggle>
          <mat-datepicker #dp [disabled]="formItem.readOnly"></mat-datepicker>
          <mat-error>{{'dynamicItem.error' | translate }}</mat-error>
          <span matSuffix [ngClass]="formItem.suffixClass" [hidden]="!formItem.suffix">{{formItem.suffix}}</span>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'datetimepicker'">
        <input #inputFormField class="datetime" [owlDateTime]="dt" [owlDateTimeTrigger]="dt" [min]="formItem.minDate"
          [max]="formItem.maxDate" [placeholder]="formItem.label | translate" [formControlName]="formItem.key"
          (dateTimeChange)="formItem.dependsOn ? dateChange($event, formItem.dependsOn) : null">
        <span [owlDateTimeTrigger]="dt"><i class="icon lineicon-calendar-full"></i></span>
        <owl-date-time #dt (monthSelected)="formItem.dependsOn ? dateChange($event, formItem.dependsOn) : null"
          [stepMinute]="formItem.stepSize" [startAt]="startDate(inputFormField, formItem.stepSize)"
          [firstDayOfWeek]="1"></owl-date-time>
      </div>

      <div *ngSwitchCase="'dropdown'">
        <mat-form-field>
          <mat-select [compareWith]="compareFn" [placeholder]="formItem.label | translate "
            (selectionChange)="onChangeSelection($event)" [formControlName]="formItem.key"
            [multiple]="formItem.isMultiple" [required]="formItem.required">
            <mat-option *ngFor="let selectElement of formItem.selectElements" [value]="selectElement.value">
              {{selectElement.name | translate}}
            </mat-option>
          </mat-select>
          <mat-error>{{'dynamicItem.error' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'auto'">
        <mat-form-field>
          <input matInput [type]="text" [formControlName]="formItem.key" [placeholder]="formItem.label | translate"
            [matAutocomplete]="auto" [required]="formItem.required">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="formItem.displayFn.bind(this.formItem)">
            <mat-option *ngFor="let selectElement of filteredOptions | async" [value]="selectElement.value">
              <span *ngIf="selectElement.special"> <i class="icon lineicon-star"></i> {{ selectElement.name }}</span>
              <span *ngIf="!selectElement.special">{{ selectElement.name }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'enum'">
        <mat-form-field>
          <mat-select [placeholder]="formItem.label | translate" [formControlName]="formItem.key"
            [multiple]="formItem.isMultiple" [required]="formItem.required">
            <mat-option *ngFor="let e of formItem.enum" [value]="e">
              {{e}}
            </mat-option>
          </mat-select>
          <mat-error>{{'dynamicItem.error' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'title'" class="title">
        <mat-form-field class="titleFormField">
          <input matInput [errorStateMatcher]="customErrorStateMatcher" [type]="formItem.type"
            [formControlName]="formItem.key" name="formItem.key" [placeholder]="formItem.label | translate "
            [value]="formItem.value ? (formItem.pipeObjects ? (formItem.value | idToName:formItem.pipeObjects) : formItem.value) : null"
            [required]="formItem.required" [readonly]="formItem.readOnly">
          <mat-error>{{'dynamicItem.error' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'map'">
        <div class="map-container">
          <div class="map-address">
            <mat-form-field [floatLabel]="'never'">
              <input matInput [placeholder]="formItem.label | translate " [matAutocomplete]="auto"
                [formControlName]="formItem.key" [value]="formItem.value" [required]="formItem.required">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let waypoint of geoWaypoints | async" [value]="waypoint.name"
                  (onSelectionChange)="setLocation(waypoint)">
                  <span>{{ waypoint.name }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <app-dynamic-map #map [directions]="formItem.directions" [inputLat]="formItem.geoLatitude"
            [inputLng]="formItem.geoLongitude" [placeName]="formItem.placeName">
          </app-dynamic-map>
        </div>
      </div>

      <div *ngSwitchCase="'chiplist'">
        <mat-form-field class="example-chip-list">
          <mat-chip-list #chipList [multiple]="formItem.isMultiple" [formControlName]="formItem.key"
            [required]="formItem.required" [selectable]="!disabled">
            <mat-chip *ngFor="let selectElement of selectedElements | async" [removable]="!disabled">
              {{selectElement.name}}
              <ng-container *ngIf="!disabled">
                &nbsp; <i class="icon icon-cancel" matSuffix (click)="removeChip(selectElement)"></i>
              </ng-container>
            </mat-chip>
            <input #chiplistInput [placeholder]="formItem.label | translate" [formControlName]="formItem.key"
              [matAutocomplete]="auto" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="formItem.separatorKeysCodes" [matChipInputAddOnBlur]="true" [hidden]="formItem.readOnly || false">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addChip($event.option.value)">
            <mat-option *ngFor="let selectElement of filteredOptions | async" [value]="selectElement.value">
              {{selectElement.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'chiplist-simple'">
        <mat-form-field class="example-chip-list">
          <mat-chip-list #chipList [multiple]="formItem.isMultiple" [formControlName]="formItem.key"
            [required]="formItem.required" [selectable]="!disabled">
            <mat-chip *ngFor="let selectElement of formItem.value" [removable]="!disabled">
              {{selectElement}}
              <ng-container *ngIf="!disabled">
                &nbsp; <i class="icon icon-cancel" matSuffix (click)="removeChipSimple(selectElement)"></i>
              </ng-container>
            </mat-chip>
            <input #chiplistInput placeholder="Modules" [formControlName]="formItem.key" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addChipSimple($event)" [hidden]="formItem.readOnly || false">
          </mat-chip-list>
        </mat-form-field>
      </div>

      <!-- <div *ngSwitchCase="'chiplist'">
        <mat-form-field class="example-chip-list">
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let element of formItem.selectElements" [selectable]="selectable" [removable]="removable"
              (removed)="remove(element)">
              {{element}}
              <i class="icon lineicon-calendar-full" *ngIf="removable"></i> -->
      <!--<mat-icon matChipRemove >cancel</mat-icon>-->
      <!-- </mat-chip>
            <input placeholder="formItem.label | translate" [formControlName]="formItem.key"
              [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let element of filteredElements | async" [value]="element.name">
              {{element.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field> -->

      <!-- <app-chiplist [source]="formItem.selectElements" [placeholder]="formItem.label | translate"
          [value]="formItem.selectedElements"></app-chiplist> -->
      <!-- </div> -->

      <div *ngSwitchCase="'switch'">
        <mat-slide-toggle [checked]="formItem.value" [formControlName]="formItem.key">{{formItem.label | translate }}
        </mat-slide-toggle>
      </div>

      <div *ngSwitchCase="'checkbox'">
        <mat-checkbox [checked]="formItem.value" [formControlName]="formItem.key" [required]="formItem.required">
          {{formItem.label | translate }}
        </mat-checkbox>
      </div>

    </ng-container>
  </div>

  <div *ngIf="!groupName">
    <ng-container [ngSwitch]="formItem.controlType">

      <div *ngSwitchCase="'textbox'">
        <mat-form-field>
          <input matInput [errorStateMatcher]="customErrorStateMatcher" [type]="formItem.type"
            [formControlName]="formItem.key" name="formItem.key" [placeholder]="formItem.label | translate "
            [value]="formItem.value ? (formItem.pipeObjects ? (formItem.value | idToName:formItem.pipeObjects) : formItem.value) : null"
            [required]="formItem.required" [readonly]="formItem.readOnly" autocomplete="off">
          <mat-error>{{'dynamicItem.error' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'textfield'" class="textfield">
        <mat-form-field>
          <textarea matInput [errorStateMatcher]="customErrorStateMatcher" [type]="formItem.type"
            [formControlName]="formItem.key" name="formItem.key" [placeholder]="formItem.label | translate "
            [value]="formItem.value ? (formItem.pipeObjects ? (formItem.value | idToName:formItem.pipeObjects) : formItem.value) : null"
            [required]="formItem.required" [readonly]="formItem.readOnly" autocomplete="off">
        </textarea>
          <mat-error>{{'dynamicItem.error' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'datepicker'">
        <mat-form-field>
          <input matInput [matDatepicker]="dp" [min]="formItem.minDate" [max]="formItem.maxDate"
            [placeholder]="formItem.label | translate" [formControlName]="formItem.key"
            (dateChange)="formItem.dependsOn ? dateChange($event, formItem.dependsOn) : null; jsonDate($event)"
            [readonly]="formItem.readOnly">
          <mat-datepicker-toggle matSuffix [for]="dp" [disabled]="form.controls[formItem.key].disabled">
          </mat-datepicker-toggle>
          <mat-datepicker #dp [disabled]="formItem.readOnly"></mat-datepicker>
          <mat-error>{{'dynamicItem.error' | translate }}</mat-error>
          <span matSuffix [ngClass]="formItem.suffixClass" [hidden]="!formItem.suffix">{{formItem.suffix}}</span>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'datetimepickerwithLabel'">
        <mat-form-field>
          <input matInput [disabled]="form.controls[formItem.key].disabled" type="datetime-local"
            [placeholder]="formItem.label | translate" [min]="formItem.minDate" [max]="formItem.maxDate"
            [value]="formItem.value | date:'yyyy-MM-ddTHH:mm'">
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'datetimepicker'">
        <input #inputFormField class="datetime" [owlDateTime]="dt" [owlDateTimeTrigger]="dt" [min]="formItem.minDate"
          [max]="formItem.maxDate" [placeholder]="formItem.label | translate" [formControlName]="formItem.key"
          (dateTimeChange)="formItem.dependsOn ? dateChange($event, formItem.dependsOn) : null">
        <span [owlDateTimeTrigger]="dt"><i class="icon lineicon-calendar-full"></i></span>
        <owl-date-time #dt (monthSelected)="formItem.dependsOn ? dateChange($event, formItem.dependsOn) : null"
          [stepMinute]="formItem.stepSize" [startAt]="startDate(inputFormField, formItem.stepSize)" [firstDayOfWeek]="1"></owl-date-time>
      </div>

      <div *ngSwitchCase="'dropdown'">
        <mat-form-field>
          <mat-select [compareWith]="compareFn" [placeholder]="formItem.label | translate "
            (selectionChange)="onChangeSelection($event)" [formControlName]="formItem.key"
            [multiple]="formItem.isMultiple" [required]="formItem.required">
            <mat-option *ngFor="let selectElement of formItem.selectElements" [value]="selectElement.value">
              {{selectElement.name | translate}}
            </mat-option>
          </mat-select>
          <mat-error>{{'dynamicItem.error' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'auto'">
        <mat-form-field>
          <input matInput [type]="text" [formControlName]="formItem.key" [placeholder]="formItem.label | translate"
            [matAutocomplete]="auto" [required]="formItem.required">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="formItem.displayFn.bind(this.formItem)">
            <mat-option *ngFor="let selectElement of filteredOptions | async" [value]="selectElement.value">
              {{ selectElement.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'enum'">
        <mat-form-field>
          <mat-select [placeholder]="formItem.label | translate" [formControlName]="formItem.key"
            [multiple]="formItem.isMultiple" [required]="formItem.required">
            <mat-option *ngFor="let e of formItem.enum" [value]="e">
              {{e}}
            </mat-option>
          </mat-select>
          <mat-error>{{'dynamicItem.error' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'imagepickerUrl'" class="imagePicker">
        <img *ngIf="formItem.value" [src]="formItem.value" />
        <input #file accept="image/png, image/jpeg" type="file"
          (change)="onChangeImage($event)" />
        <button mat-button class="button-image" type="button" *ngIf="!disabled"
          (click)="file.click()"><span *ngIf="!formItem.value">Add Image</span></button>
      </div>

      <div *ngSwitchCase="'imagepicker'" class="imagePicker">
        <div [class]="disabled ? 'imagepickerBorder' : 'imagepickerBorderEdit'">
          <img *ngIf="formItem.value" class="serviceLogo" src="{{'data:image/png;base64,' + formItem.value}}" />
          <input #imagePicker [style.display]="'none'" id="input-file-id" accept="image/png, image/jpeg" type="file"
            (change)="onChangeImage($event)" />
          <button mat-flat-button type="button" *ngIf="!disabled" (click)="onClickLoadImage($event)">Load Image</button>
        </div>
      </div>


      <div *ngSwitchCase="'title'" class="title">
        <mat-form-field class="titleFormField">
          <input matInput [errorStateMatcher]="customErrorStateMatcher" [type]="formItem.type"
            [formControlName]="formItem.key" name="formItem.key" [placeholder]="formItem.label | translate "
            [value]="formItem.value ? (formItem.pipeObjects ? (formItem.value | idToName:formItem.pipeObjects) : formItem.value) : null"
            [required]="formItem.required" [readonly]="formItem.readOnly">
          <mat-error>{{'dynamicItem.error' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'map'">
        <div class="map-container">
          <div class="map-address">
            <mat-form-field [floatLabel]="'never'">
              <input matInput [placeholder]="formItem.label | translate " [matAutocomplete]="auto"
                [formControlName]="formItem.key" [value]="formItem.value" [required]="formItem.required">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let waypoint of geoWaypoints | async" [value]="waypoint.name"
                  (onSelectionChange)="setLocation(waypoint)">
                  <span>{{ waypoint.name }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <app-dynamic-map #map [directions]="formItem.directions" [inputLat]="formItem.geoLatitude"
            [inputLng]="formItem.geoLongitude" [placeName]="formItem.placeName">
          </app-dynamic-map>
        </div>
      </div>

      <div *ngSwitchCase="'chiplist'">
        <mat-form-field class="example-chip-list">
          <mat-chip-list #chipList [multiple]="formItem.isMultiple" [formControlName]="formItem.key"
            [required]="formItem.required" [selectable]="!disabled">
            <mat-chip *ngFor="let selectElement of selectedElements | async" [removable]="!disabled">
              {{selectElement.name}}
              <ng-container *ngIf="!disabled">
                &nbsp; <i class="icon icon-cancel" matSuffix (click)="removeChip(selectElement)"></i>
              </ng-container>
            </mat-chip>
            <input #chiplistInput [placeholder]="formItem.label | translate" [formControlName]="formItem.key"
              [matAutocomplete]="auto" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="formItem.separatorKeysCodes" [matChipInputAddOnBlur]="true" [hidden]="formItem.readOnly || false">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addChip($event.option.value)">
            <mat-option *ngFor="let selectElement of filteredOptions | async" [value]="selectElement.value">
              {{selectElement.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'chiplist-simple'">
        <mat-form-field class="example-chip-list">
          <mat-chip-list #chipList [multiple]="formItem.isMultiple" [formControlName]="formItem.key"
            [required]="formItem.required" [selectable]="!disabled">
            <mat-chip *ngFor="let selectElement of formItem.value" [removable]="!disabled">
              {{selectElement}}
              <ng-container *ngIf="!disabled">
                &nbsp; <i class="icon icon-cancel" matSuffix (click)="removeChipSimple(selectElement)"></i>
              </ng-container>
            </mat-chip>
            <input #chiplistInput placeholder="Modules" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addChipSimple($event)" [hidden]="formItem.readOnly || false">
          </mat-chip-list>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'switch'">
        <mat-slide-toggle [checked]="formItem.value" [formControlName]="formItem.key" [required]="formItem.required">
          {{formItem.label | translate }}
        </mat-slide-toggle>
      </div>

      <div *ngSwitchCase="'checkbox'">
        <mat-checkbox [checked]="formItem.value" [formControlName]="formItem.key" [required]="formItem.required">
          {{formItem.label | translate }}
        </mat-checkbox>
      </div>

    </ng-container>
  </div>
</div>
