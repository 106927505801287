import { RootLoadingService } from '../../share/utility/loading/root-loading.service';
import { NotificationService } from './../../share/utility/notification/notification.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-pwreset',
  templateUrl: './pwreset.component.html',
  styleUrls: ['./pwreset.component.scss']
})
export class PwresetComponent implements OnInit, OnDestroy {

  model: any = {};
  error: string;
  username: string;
  resetToken: string;
  pwHide: boolean;
  pwHide2: boolean;
  private sub: Subscription;

  constructor(
    private _loadingService: RootLoadingService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService,
    private _router: Router,
    private _route: ActivatedRoute

  ) { }

  ngOnInit() {
    this.sub = this._route.params.subscribe(params => {
      this.username = params['username'];
      this.resetToken = params['token'];
      if (!this.username || !this.resetToken) {
        this._router.navigate(['login']);
      }
   });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  resetPassword($event: any) {
    $event.preventDefault();
    if (this.model['password'] !== this.model['passwordRepeat']) {
      this.error = 'Passwort stimmt nicht überein!';
    } else {
      this._authService.resetPassword(this.username, this.resetToken, this.model['password']).subscribe(
      response => {
        this._notificationService.showSuccess('Password wurde zurückgesetzt');
        this._router.navigate(['login']);
        this._loadingService.hideLoading();
      },
      error => {
        this._notificationService.showWarning('Benutzername oder Email-Adresse nicht bekannt. Bitte versuchen Sie es erneut!');
        this._loadingService.hideLoading();
      });
    }
  }
}
