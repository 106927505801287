import { FormGroup } from '@angular/forms';
import { DialogService } from './../../share/utility/dialog/dialog.service';
import { UserRegistration, ContactDetails } from './../authentication.api.service';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription, empty } from 'rxjs';
import { RootLoadingService } from '../../share/utility/loading/root-loading.service';
import { AuthenticationService } from '../authentication.service';
import { NotificationService } from '../../share/utility/notification/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { flatMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { RegionalConfig } from '../../../../core/regionalConfig';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {

  model: UserRegistration = {};
  error: string;
  pwHide: boolean;
  pwHide2: boolean;
  contactDetails: ContactDetails;
  private sub: Subscription;

  constructor(
    private _loadingService: RootLoadingService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _dialogService: DialogService,
    private _translate: TranslateService
  ) {
    _translate.addLangs(RegionalConfig.languages);
    _translate.setDefaultLang('de');
  }

  ngOnInit() {
    this.sub = this._route.params.pipe(
      flatMap(params => {
        if (params['companyInviteCode']) {
          this.model.companyInviteCode = params['companyInviteCode'];
          this.model.termsOfUseAccepted = false;
          delete this.model.passwordRepeat; // remove unnecessary attribute
          return this._authService.getInvitationToken(this.model.companyInviteCode);
        } else {
          console.error('no invite code provided');
          this._router.navigate(['login']);
          return empty();
        }
      })
    ).subscribe((contactDetails: ContactDetails) => {
      this.contactDetails = contactDetails;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  registerSimpleUser($event: any) {
    $event.preventDefault();
    if (this.model['password'] !== this.model['passwordRepeat']) {
      this.error = 'Passwort stimmt nicht überein!';
    } else if (!this.model.companyInviteCode) {
      this._notificationService.showWarning('Kein Einladungscode vorhanden. Bitte kontaktieren Sie Ihren zuständigen Amdinistrator');
    } else {
      // show terms
      this._translate.get('dialog.termsMessage').pipe(
        flatMap((translatedValue: string) => {
          return this._dialogService.confirmHtml('dialog.terms', translatedValue, ['dialog.accept', 'dialog.deny']);
        }),
        flatMap(confirm => {
          if (confirm) {
            this.model.termsOfUseAccepted = confirm;
            this._loadingService.showLoading();
            return this._authService.registerSimpleUser(this.model);
          } else {
            return empty();
          }
        })
      ).subscribe(
        response => {
          this._notificationService.showSuccess('Sie können sich nun anmelden.');
          this._router.navigate(['login']);
          this._loadingService.hideLoading();
        },
        error => {
          this._notificationService.showWarning(error.error.message);
          this._loadingService.hideLoading();
        });
    }
  }
}
