<div class="login" [ngStyle]="{'background-image': 'url(./assets/img/intro_01.jpg)'}">
  <div class="login-box">
    <div id="login-logo">
      <img src="/assets/img/logo.svg" alt="FirmenLogo">
    </div>
    <form #pwresetForm="ngForm">
      <h2>Passwort zurücksetzen</h2>
      <div class="info-text">        
        Sie haben ein neues Passwort angefordert. Bitte geben Sie Ihr <strong>neues</strong> Passwort zwei mal ein.
      </div>
      <mat-form-field [floatLabel]="'never'">
        <input matInput [(ngModel)]="model.password" name="newPassword" placeholder="Passwort eingeben"
          [type]="!pwHide ? 'password' : 'text'" #password="ngModel" required minlength="6">
        <i *ngIf="!pwHide" class="icon icon-eye" matSuffix (click)="pwHide = !pwHide"></i>
        <i *ngIf="pwHide" class="icon icon-eye-blocked" matSuffix (click)="pwHide = !pwHide"></i>
        <mat-error *ngIf="password.invalid && password.errors.required">Pflichtfeld</mat-error>
        <mat-error *ngIf="password.invalid && password.errors.minlength">Mindestlänge 6 Zeichen</mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="'never'">
        <input matInput [(ngModel)]="model.passwordRepeat" name="password" placeholder="Passwort wiederholen"
          [type]="!pwHide2 ? 'password' : 'text'" #passwordRepeat="ngModel" required minlength="6">
        <i *ngIf="!pwHide2" class="icon icon-eye" matSuffix (click)="pwHide2 = !pwHide2"></i>
        <i *ngIf="pwHide2" class="icon icon-eye-blocked" matSuffix (click)="pwHide2 = !pwHide2"></i>
        <mat-error *ngIf="passwordRepeat.invalid && passwordRepeat.errors.required">Pflichtfeld</mat-error>
        <mat-error *ngIf="passwordRepeat.invalid && passwordRepeat.errors.minlength">Mindestlänge 6 Zeichen</mat-error>
      </mat-form-field>

      <div id="login-error" *ngIf="error">
        {{error}}
      </div>
      <button mat-raised-button color="accent" type="submit"
        (click)="pwresetForm.valid ? resetPassword($event) : pwresetForm.touch">Neues Passwort</button>
    </form>
  </div>
</div>