import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { BootController } from './boot-control';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

const init = () => {
  // reset login status
  // if (!environment.local) {
    // localStorage.removeItem('token');
    // localStorage.removeItem('user');
    // localStorage.removeItem('userconfig');
    // localStorage.removeItem('chargingToken');
    // localStorage.removeItem('originalToken');
    // localStorage.removeItem('appContext');
    // localStorage.removeItem('originalUser');
  // }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .then(() => (<any>window).appBootstrap && (<any>window).appBootstrap())
    .catch(err => console.error('NG Bootstrap Error =>', err));
};

// Init on first load
init();

// Init on reboot request
const boot = BootController.getbootControl().watchReboot().subscribe(() => init());
