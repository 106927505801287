import { Waypoint, User, Vehicle, Location, FuelData } from './index';

export class Booking {
    /*===ScheduleElement===*/
    id: string;
    bookingName: string;
    tenantId: string;
    parentId: string;
    startTimePlanned: Date;
    endTimePlanned: Date;
    startTimeActual: Date;
    endTimeActual: Date;
    /*===Booking===*/
    userIds: Array<string>;
    users: Array<User>;
    status: BookingState;
    priority: number;
    vehicleCategoryId: string;
    vehicleId: string;
    vehicleIdPreferred: string;
    vehicle: Vehicle;
    distanceInKM: number;
    startSite: Location;
    startSiteId: string;
    endSite: Location;
    endSiteId: string;
    waypoints: Waypoint[];
    durationAtDestination: TimeRanges;
    chargeLevel: string;
    fuelLevel: string;
    comment: string;
    costCenter: string;
    mileage: string;
    companyId: string;
    bookerId: string;
    privateTrip: boolean;
    reason: string;
    project: string;
    isDataSetComplete: boolean;
    fuelDatas: FuelData[];
    prebookComment: string;
    licenseChecked: boolean;
    createdAt: Date;
    updatedAt: Date;
    deleted: boolean;
    noShow: boolean;
    bookingType: string;
    ignoreRangeLimit: boolean;
}

export enum BookingState {
    TERMINATED = 'TERMINATED',
    FIXED = 'FIXED',
    APPROVAL_REQUIRED = 'APPROVAL_REQUIRED',
    CANCELED = 'CANCELED',
    RUNNING = 'RUNNING',
    ABORTED = 'ABORTED',
    RESERVED = 'RESERVED'
}
