import { Observable } from 'rxjs';
import { AuthHttpService } from './auth-http.service';
import { HttpClient, HttpRequest, HttpHeaders, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';

export function methodBuilder(method: string) {
  return function (url: string) {
    return function (target: AuthHttpService, propertyKey: string, descriptor: any) {

      const pPath = target[`${propertyKey}_Path_parameters`],
        pQuery = target[`${propertyKey}_Query_parameters`],
        pBody = target[`${propertyKey}_Body_parameters`],
        pHeader = target[`${propertyKey}_Header_parameters`];

      descriptor.value = function (...args: any[]) {
        const body: string = createBody(pBody, descriptor, args);
        let resUrl: string = createPath(url, pPath, args, descriptor);
        const responseType = descriptor.responseType;
        const search: URLSearchParams = createQuery(pQuery, args);
        const headers: HttpHeaders = createHeaders(pHeader, descriptor, this.headers, args, method);
        // Request options
        if (descriptor.isFullAPI) {
        // use resUrl as is
        } else if (descriptor.apiKey) {
          resUrl = this.configService.getAPI(descriptor.apiKey) + resUrl;
        } else if (descriptor.api) {
          resUrl = descriptor.api + resUrl;
        } else {
          resUrl = this._api + resUrl;
        }

        let req = new HttpRequest(method, resUrl, body, {
          responseType: responseType ? responseType : 'json',
          headers: headers
        });

        // intercept the request
        req = this.requestInterceptor(req, descriptor.reqAdapter);
        // check if token is valid
        // make the request and store the observable for later transformation
        let observable: Observable<HttpEvent<any>>;
        const client = new HttpClient(null);
        if (!descriptor.isExtern) {
          this.loggedIn();
          observable = this.authHttp.request(req);
        } else {
          observable = this.authHttp.request(req);
        }

        // intercept the response
        observable = this.responseInterceptor(observable, descriptor.adapter);

        return observable;
      };

      return descriptor;
    };
  };
}

export function paramBuilder(paramName: string) {
  return function (key: string) {
    return function (target: AuthHttpService, propertyKey: string | symbol, parameterIndex: number) {
      const propertyKeyConverted = propertyKey.toString();
      const metadataKey = `${propertyKeyConverted}_${paramName}_parameters`;
      const paramObj: any = {
        key: key,
        parameterIndex: parameterIndex
      };

      if (Array.isArray(target[metadataKey])) {
        target[metadataKey].push(paramObj);
      } else {
        target[metadataKey] = [paramObj];
      }
    };
  };
}

function createBody(pBody: Array<any>, descriptor: any, args: Array<any>): any {
  if (descriptor.isFormData) {
    const formObject = args[0].value;
    return formObject;
  }
  if (descriptor.isByteArray) {
    const fileInput: FileList = args[1];
    const formData: FormData = new FormData();
    Array.from(fileInput).forEach(file => {
      formData.append('images', file, file['name']);
    });
    return formData;
  }
  if (descriptor.isFile) {
    const file: File = args[0];
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  }
  return pBody ? args[pBody[0].parameterIndex] : null;
}

function createPath(url: string, pPath: Array<any>, args: Array<any>, descriptor: any): string {
  let resUrl: string = url;

  if (pPath) {
    for (const k in pPath) {
      if (pPath.hasOwnProperty(k)) {
        resUrl = resUrl.replace('{' + pPath[k].key + '}', args[pPath[k].parameterIndex]);
      }
    }
  }

  if (descriptor.params) {
    const params = descriptor.params as { [key: string]: string }[];
    const paramsLength = Object.keys(params).length;
    let counter = 0;

    if (resUrl.indexOf('?') < 0) {
      resUrl += '?';
    } else if (paramsLength) {
      resUrl += '&';
    }
    for (const k in params) {
      if (params.hasOwnProperty(k)) {
        resUrl += k + '=' + params[k];
        if (++counter < paramsLength) {
          resUrl += '&';
        }
      }
    }
  }
  return resUrl;
}

function createQuery(pQuery: any, args: Array<any>): URLSearchParams {
  const search = new URLSearchParams();

  if (pQuery) {
    pQuery
      .filter(p => args[p.parameterIndex]) // filter out optional parameters
      .forEach(p => {
        const key = p.key;
        let value = args[p.parameterIndex];
        // if the value is a instance of Object, we stringify it
        if (value instanceof Object) {
          value = JSON.stringify(value);
        }
        search.set(encodeURIComponent(key), encodeURIComponent(value));
      });
  }

  return search;
}

function createHeaders(pHeader: any, descriptor: any, defaultHeaders: any, args: Array<any>, method: string): HttpHeaders {

  let headers: HttpHeaders = defaultHeaders;

  // set parameter specific headers
  if (pHeader) {
    for (const k in pHeader) {
      if (pHeader.hasOwnProperty(k)) {
        if (headers.has(k)) {
          headers = headers.delete(k);
        }
        headers = headers.append(pHeader[k].key, args[pHeader[k].parameterIndex]);
      }
    }
  }

  // set method specific headers
  for (const k in descriptor.headers) {
    if (descriptor.headers.hasOwnProperty(k)) {
      if (headers.has(k)) {
        headers = headers.delete(k);
      }
      headers = headers.append(k, descriptor.headers[k]);
    }
  }

  if (!descriptor.isExtern) {
    headers = headers.append('UbstackUserAuthorization', 'Bearer ' + localStorage.getItem('token'));
    // headers = headers.append('Access-Control-Allow-Origin', '*');
  }

  if (method === 'POST' || method === 'PUT') {
    if (descriptor.isByteArray || descriptor.isFile) {
      headers = headers.delete('Content-Type');
    } else if (descriptor.isMotown) {
      headers = headers.append('Content-Type', 'application/vnd.io.motown.operator-api-v1+json');
    } else if (!headers.has('Content-Type')) {
      // headers = headers.append('Content-Type', 'application/vnd.io.motown.operator-api-v1+json');
      headers = headers.append('Content-Type', 'application/json');
    }
  }

  return headers;
}

