import { HttpClient, HttpRequest } from '@angular/common/http';
export function methodBuilder(method) {
    return function (url) {
        return function (target, propertyKey, descriptor) {
            var pPath = target[propertyKey + "_Path_parameters"], pQuery = target[propertyKey + "_Query_parameters"], pBody = target[propertyKey + "_Body_parameters"], pHeader = target[propertyKey + "_Header_parameters"];
            descriptor.value = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var body = createBody(pBody, descriptor, args);
                var resUrl = createPath(url, pPath, args, descriptor);
                var responseType = descriptor.responseType;
                var search = createQuery(pQuery, args);
                var headers = createHeaders(pHeader, descriptor, this.headers, args, method);
                // Request options
                if (descriptor.isFullAPI) {
                    // use resUrl as is
                }
                else if (descriptor.apiKey) {
                    resUrl = this.configService.getAPI(descriptor.apiKey) + resUrl;
                }
                else if (descriptor.api) {
                    resUrl = descriptor.api + resUrl;
                }
                else {
                    resUrl = this._api + resUrl;
                }
                var req = new HttpRequest(method, resUrl, body, {
                    responseType: responseType ? responseType : 'json',
                    headers: headers
                });
                // intercept the request
                req = this.requestInterceptor(req, descriptor.reqAdapter);
                // check if token is valid
                // make the request and store the observable for later transformation
                var observable;
                var client = new HttpClient(null);
                if (!descriptor.isExtern) {
                    this.loggedIn();
                    observable = this.authHttp.request(req);
                }
                else {
                    observable = this.authHttp.request(req);
                }
                // intercept the response
                observable = this.responseInterceptor(observable, descriptor.adapter);
                return observable;
            };
            return descriptor;
        };
    };
}
export function paramBuilder(paramName) {
    return function (key) {
        return function (target, propertyKey, parameterIndex) {
            var propertyKeyConverted = propertyKey.toString();
            var metadataKey = propertyKeyConverted + "_" + paramName + "_parameters";
            var paramObj = {
                key: key,
                parameterIndex: parameterIndex
            };
            if (Array.isArray(target[metadataKey])) {
                target[metadataKey].push(paramObj);
            }
            else {
                target[metadataKey] = [paramObj];
            }
        };
    };
}
function createBody(pBody, descriptor, args) {
    if (descriptor.isFormData) {
        var formObject = args[0].value;
        return formObject;
    }
    if (descriptor.isByteArray) {
        var fileInput = args[1];
        var formData_1 = new FormData();
        Array.from(fileInput).forEach(function (file) {
            formData_1.append('images', file, file['name']);
        });
        return formData_1;
    }
    if (descriptor.isFile) {
        var file = args[0];
        var formData = new FormData();
        formData.append('file', file);
        return formData;
    }
    return pBody ? args[pBody[0].parameterIndex] : null;
}
function createPath(url, pPath, args, descriptor) {
    var resUrl = url;
    if (pPath) {
        for (var k in pPath) {
            if (pPath.hasOwnProperty(k)) {
                resUrl = resUrl.replace('{' + pPath[k].key + '}', args[pPath[k].parameterIndex]);
            }
        }
    }
    if (descriptor.params) {
        var params = descriptor.params;
        var paramsLength = Object.keys(params).length;
        var counter = 0;
        if (resUrl.indexOf('?') < 0) {
            resUrl += '?';
        }
        else if (paramsLength) {
            resUrl += '&';
        }
        for (var k in params) {
            if (params.hasOwnProperty(k)) {
                resUrl += k + '=' + params[k];
                if (++counter < paramsLength) {
                    resUrl += '&';
                }
            }
        }
    }
    return resUrl;
}
function createQuery(pQuery, args) {
    var search = new URLSearchParams();
    if (pQuery) {
        pQuery
            .filter(function (p) { return args[p.parameterIndex]; }) // filter out optional parameters
            .forEach(function (p) {
            var key = p.key;
            var value = args[p.parameterIndex];
            // if the value is a instance of Object, we stringify it
            if (value instanceof Object) {
                value = JSON.stringify(value);
            }
            search.set(encodeURIComponent(key), encodeURIComponent(value));
        });
    }
    return search;
}
function createHeaders(pHeader, descriptor, defaultHeaders, args, method) {
    var headers = defaultHeaders;
    // set parameter specific headers
    if (pHeader) {
        for (var k in pHeader) {
            if (pHeader.hasOwnProperty(k)) {
                if (headers.has(k)) {
                    headers = headers.delete(k);
                }
                headers = headers.append(pHeader[k].key, args[pHeader[k].parameterIndex]);
            }
        }
    }
    // set method specific headers
    for (var k in descriptor.headers) {
        if (descriptor.headers.hasOwnProperty(k)) {
            if (headers.has(k)) {
                headers = headers.delete(k);
            }
            headers = headers.append(k, descriptor.headers[k]);
        }
    }
    if (!descriptor.isExtern) {
        headers = headers.append('UbstackUserAuthorization', 'Bearer ' + localStorage.getItem('token'));
        // headers = headers.append('Access-Control-Allow-Origin', '*');
    }
    if (method === 'POST' || method === 'PUT') {
        if (descriptor.isByteArray || descriptor.isFile) {
            headers = headers.delete('Content-Type');
        }
        else if (descriptor.isMotown) {
            headers = headers.append('Content-Type', 'application/vnd.io.motown.operator-api-v1+json');
        }
        else if (!headers.has('Content-Type')) {
            // headers = headers.append('Content-Type', 'application/vnd.io.motown.operator-api-v1+json');
            headers = headers.append('Content-Type', 'application/json');
        }
    }
    return headers;
}
