var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Maintenance } from '../../authentication/authentication.api.service';
import { AuthHttpAdapter } from '../../share/http/auth-http.adapter';
import { AuthHttpService } from '../../share/http/auth-http.service';
import { Adapter, APIKey, Body, DELETE, GET, Headers, MediaType, Path, POST, Produces, PUT, ResponseType } from '../../share/http/index';
var imgType = { 'Accept': 'image/webp,image/png,image/*,*/*;q=0.8' };
var TenantAPIService = /** @class */ (function (_super) {
    __extends(TenantAPIService, _super);
    function TenantAPIService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._api = _this.configService.getAPI('users');
        return _this;
    }
    TenantAPIService.prototype.getTenants = function () { return null; };
    TenantAPIService.prototype.getActiveTenants = function () { return null; };
    TenantAPIService.prototype.getTenant = function (id) { return null; };
    TenantAPIService.prototype.getTenantConfig = function (id) { return null; };
    TenantAPIService.prototype.getImageOfTenant = function (id) { return null; };
    TenantAPIService.prototype.createTenantImage = function (form, id) { return null; };
    TenantAPIService.prototype.createTenant = function (form) { return null; };
    TenantAPIService.prototype.updateTenant = function (form, id) { return null; };
    TenantAPIService.prototype.updateTenantConfig = function (form, id) { return null; };
    TenantAPIService.prototype.deleteTenant = function (id) { return null; };
    TenantAPIService.prototype.getMaintenance = function () { return null; };
    TenantAPIService.prototype.createMaintenance = function (maintenance) { return null; };
    TenantAPIService.prototype.updateMaintenance = function (maintenance, id) { return null; };
    TenantAPIService.prototype.deleteMaintenance = function (id) { return null; };
    __decorate([
        GET('/tenants'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "getTenants", null);
    __decorate([
        GET('/tenants?active=true'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "getActiveTenants", null);
    __decorate([
        GET('/tenants/{id}'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "getTenant", null);
    __decorate([
        GET('/tenants/{id}/tenantconfiguration?hierarchical=true'),
        APIKey('userconfig'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "getTenantConfig", null);
    __decorate([
        GET('/tenants/{tenantId}/imageFile'),
        ResponseType('blob'),
        Headers(imgType),
        Adapter(AuthHttpAdapter.imgUrlAdapter),
        __param(0, Path('tenantId')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "getImageOfTenant", null);
    __decorate([
        POST('/tenants/{tenantId}/image'),
        Produces(MediaType.FILE),
        __param(0, Body), __param(1, Path('tenantId')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, String]),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "createTenantImage", null);
    __decorate([
        POST('/tenants'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup]),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "createTenant", null);
    __decorate([
        PUT('/tenants/{id}'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup, String]),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "updateTenant", null);
    __decorate([
        PUT('/tenants/{id}/tenantconfiguration?hierarchical=true'),
        APIKey('userconfig'),
        Produces(MediaType.JSON),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, String]),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "updateTenantConfig", null);
    __decorate([
        DELETE('/tenants/{id}'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "deleteTenant", null);
    __decorate([
        GET('/maintenance'),
        APIKey('userconfig'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "getMaintenance", null);
    __decorate([
        POST('/maintenance'),
        APIKey('userconfig'),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Maintenance]),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "createMaintenance", null);
    __decorate([
        PUT('/maintenanceId/{id}'),
        APIKey('userconfig'),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Maintenance, String]),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "updateMaintenance", null);
    __decorate([
        DELETE('/maintenance/{id}'),
        APIKey('userconfig'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], TenantAPIService.prototype, "deleteMaintenance", null);
    return TenantAPIService;
}(AuthHttpService));
export { TenantAPIService };
