import { Global } from '../../../config/global';
// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
// DateTimeFormat
export var MY_FORMATS = {
    parseInput: Global.dateFormatLong,
    fullPickerInput: Global.dateFormatLong,
    datePickerInput: '',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};
// DateFormat
export var DATE_PICKER_FORMAT = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
var ɵ0 = { useUtc: true };
var FormModule = /** @class */ (function () {
    function FormModule() {
    }
    return FormModule;
}());
export { FormModule };
export { ɵ0 };
