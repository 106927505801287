import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Global } from '../../../config/global';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '../../utility/utility.service';

@Pipe({
  name: 'timeDifference'
})
export class TimeDifferencePipe implements PipeTransform {

  constructor(
    private _translate: TranslateService,
    private _utilityService: UtilityService
  ) { }

  transform(value: any, diff?: any): any {
    let diffDate = moment(moment.now());
    const inputDate = moment(value);

    // check if parameter has been passed
    if (diff) {
      diffDate = moment(diff);
    }
    const duration = moment.duration(diffDate.diff(inputDate));

    // if (diff) {
      if (duration.asMinutes() <= 1) {
        return this._translate.instant('metrics.justNow');
      } else if (duration.asMinutes() < 60) {
        return duration.asMinutes() + ' ' + this._translate.instant('metrics.minutes');
      } else if (duration.asHours() < 2) {
        return duration.asHours() + ' ' + this._translate.instant('metrics.hour');
      } else if (duration.asHours() < 24) {
        return duration.asHours() + ' ' + this._translate.instant('metrics.hours');
      } else if (duration.asHours() >= 24 && duration.asHours() < 48) {
        return duration.asDays() + ' ' + this._translate.instant('metrics.day');
      } else if (duration.asHours() >= 24) {
        return duration.asDays() + ' ' + this._translate.instant('metrics.days');
      }
    /*} else {
      if (duration.asMinutes() <= 5) {
        return this._translate.instant('metrics.justNow');
      } else if (duration.asMinutes() <= 50) {
        return this._utilityService.ceil(duration.asMinutes(), 5) + ' ' + this._translate.instant('metrics.minutes');
      } else if (duration.asHours() < 2) {
        return 1 + ' ' + this._translate.instant('metrics.hour');
      } else if (duration.asHours() < 24) {
        return Math.floor(duration.asHours()) + ' ' + this._translate.instant('metrics.hours');
      } else if (duration.asHours() >= 24 && duration.asHours() < 48) {
        return this._translate.instant('metrics.yesterday');
      } else {
        return moment(value).format(Global.dateFormatNoHour);
      }
    }*/
  }

}
