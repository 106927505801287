import * as moment from 'moment';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { FormFieldset, FormItemBase, Depend, DependValue } from './form-item-base';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { ValidatorFn } from '@angular/forms/src/directives/validators';
import { MapService } from '../map/map.service';
import { Waypoint } from '../../models/index';

@Injectable()
export class FormItemControlService {

  validationStatus = new BehaviorSubject<boolean>(null);
  currentForm = new BehaviorSubject<FormGroup>(null);
  triggerValue = new BehaviorSubject<string>(null);
  // add subscriptions[]

  constructor(
    private _mapService: MapService
  ) { }

  toFormGroup(formFieldsets: FormFieldset[]) {
    const group: any = {};
    formFieldsets.forEach(fieldset => {
      // is nested group?
      if (fieldset.groupName) {
        const nestedGroup: any = {};
        fieldset.formItems.forEach(formItem => {
          nestedGroup[formItem.key] = this.createFormControl(formItem);
          if (formItem.trigger) {
            const formControl = nestedGroup[formItem.key];
            formControl.valueChanges.subscribe(value => {
              this.triggerValue.next(value);
            });
          }
        });
        group[fieldset.groupName] = new FormGroup(nestedGroup);
      } else {
        fieldset.formItems.forEach(formItem => {
          group[formItem.key] = this.createFormControl(formItem);
          if (formItem.trigger) {
            const formControl = group[formItem.key];
            formControl.valueChanges.subscribe(value => {
              this.triggerValue.next(value);
            });
          }
        });
      }
    });
    return new FormGroup(group);
  }

  toSingleFormGroup(formFieldset: FormFieldset) {
    const group: any = {};
    formFieldset.formItems.forEach(formItem => {
      group[formItem.key] = this.createFormControl(formItem);
      if (formItem.trigger) {
        const formControl = group[formItem.key];
        formControl.valueChanges.subscribe(value => {
          this.triggerValue.next(value);
        });
      }
    });
    return new FormGroup(group);
  }

 /*  updateFormFieldsets(formFieldsets: FormFieldset[], data: any[]) {
    formFieldsets.forEach(fieldset => {
      fieldset.formItems.forEach(formItem => {
        if (formItem.key === data[1]) {
          if (data[2]) {
            formItem.maxDate = data[0];
          } else {
            formItem.minDate = data[0];
          }
        }
      });
    });
    return formFieldsets;
  }
 */
  updateDateFields(formFieldsets: FormFieldset[], dependsOnDate: DependValue<Date>) {
    formFieldsets.forEach(fieldset => {
      fieldset.formItems.forEach(formItem => {
        if (formItem.key === dependsOnDate.depend.key) {
          if (dependsOnDate.depend.isMin) {
            formItem.maxDate = dependsOnDate.value;
          } else {
            formItem.minDate = dependsOnDate.value;
          }
        }
      });
    });
    return formFieldsets;
  }

  private createFormControl(formItem: FormItemBase<any>): FormControl {
    let controlOptions: any;
    const validatorsFN: ValidatorFn[] = [];
    if (formItem.type === 'email') {
      validatorsFN.push(Validators.email);
    }
    if (formItem.minLength) {
      validatorsFN.push(Validators.minLength(formItem.minLength));
    }
    if (formItem.maxLength) {
      validatorsFN.push(Validators.maxLength(formItem.maxLength));
    }
    if (formItem.required) {
      validatorsFN.push(Validators.required);
    }
    controlOptions = {
      validators: validatorsFN
    };
    return new FormControl(formItem.value, controlOptions);
  }

}



