export const environment = {
  production: false,
  local: false,
  tileHosting: {
    mapsAPIKey: 'AiE2cGVJGbh0jM3zgsfeN',
    geoAPIKey: 'wsgmr5zXfsrqGhJRpqo1'
  },
  openWeatherMap: {
    APIKey: '28e49c452bf732714a3d8d1269b8abc3'
  },
  openRouteService: {
    APIKey: '5b3ce3597851110001cf624829bd7e89c01a45ad99fbee1f6247a7a4'
  },
  gateway: 'https://dev.ubstack.iao.fraunhofer.de/',
  idle: 600,
  timeout: 2000,
  version: 'dev_' + require('../../package.json').version,
  efsDevMode: true
};
