<div class="login" [ngStyle]="{'background-image': 'url(./assets/img/intro_02.jpg)'}">
  <div class="login-box">
    <div id="login-logo">
      <img src="/assets/img/logo.svg" alt="FirmenLogo">
    </div>
    <div *ngIf="contactDetails" class="invitation">
      <div class="invitationHeadline">Kontakt</div>
      <ul>
        <li>{{contactDetails.contactName}}</li>
        <li>{{contactDetails.contactEmailAdress}}</li>
        <li>{{contactDetails.contactPhonenumber}}</li>
      </ul>
    </div>
    <form #signupForm="ngForm">
      <div class="form-fieldset-header underline">Neues Konto erstellen</div>
      <div class="form-fieldset">
        <mat-form-field [ngClass]="'form-field'" [floatLabel]="'never'">
          <input matInput [(ngModel)]="model.formOfAddress" name="formOfAddress" placeholder="Anrede eingeben"
            #formOfAddress="ngModel" required minlength="3">
          <i class="icon icon-user" matSuffix></i>
          <mat-error *ngIf="formOfAddress.invalid && formOfAddress.errors.required">Pflichtfeld</mat-error>
          <mat-error *ngIf="formOfAddress.invalid && formOfAddress.errors.minlength">Mindestlänge 3 Zeichen</mat-error>
        </mat-form-field>
        <mat-form-field [ngClass]="'form-field'" [floatLabel]="'never'">
          <input matInput [(ngModel)]="model.firstname" name="firstname" placeholder="Vorname eingeben"
            #firstname="ngModel" required minlength="3">
          <i class="icon icon-user" matSuffix></i>
          <mat-error *ngIf="firstname.invalid && firstname.errors.required">Pflichtfeld</mat-error>
          <mat-error *ngIf="firstname.invalid && firstname.errors.minlength">Mindestlänge 3 Zeichen</mat-error>
        </mat-form-field>
        <mat-form-field [ngClass]="'form-field'" [floatLabel]="'never'">
          <input matInput [(ngModel)]="model.lastname" name="lastname" placeholder="Nachname eingeben"
            #lastname="ngModel" required minlength="3">
          <i class="icon icon-user" matSuffix></i>
          <mat-error *ngIf="lastname.invalid && lastname.errors.required">Pflichtfeld</mat-error>
          <mat-error *ngIf="lastname.invalid && lastname.errors.minlength">Mindestlänge 3 Zeichen</mat-error>
        </mat-form-field>
        <mat-form-field [ngClass]="'form-field'" [floatLabel]="'never'">
          <input matInput [(ngModel)]="model.username" name="username" placeholder="Nutzernamen eingeben"
            #username="ngModel" required minlength="6">
          <i class="icon icon-user" matSuffix></i>
          <mat-error *ngIf="username.invalid && username.errors.required">Pflichtfeld</mat-error>
          <mat-error *ngIf="username.invalid && username.errors.minlength">Mindestlänge 6 Zeichen</mat-error>
        </mat-form-field>
        <mat-form-field [ngClass]="'form-field'" [floatLabel]="'never'">
          <input matInput [(ngModel)]="model.email" name="email" placeholder="E-Mail-Adresse eingeben" #email="ngModel"
            required email>
          <i class="icon icon-at-sign" matSuffix></i>
          <mat-error *ngIf="email.invalid && email.errors.email">Ungültige E-Mail-Adresse</mat-error>
          <mat-error *ngIf="email.invalid && email.errors.required">Pflichtfeld</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field [floatLabel]="'never'">
        <input matInput [(ngModel)]="model.password" name="newPassword" placeholder="Passwort eingeben"
          [type]="!pwHide ? 'password' : 'text'" #password="ngModel" required minlength="6">
        <i *ngIf="!pwHide" class="icon icon-eye" matSuffix (click)="pwHide = !pwHide"></i>
        <i *ngIf="pwHide" class="icon icon-eye-blocked" matSuffix (click)="pwHide = !pwHide"></i>
        <mat-error *ngIf="password.invalid && password.errors.required">Pflichtfeld</mat-error>
        <mat-error *ngIf="password.invalid && password.errors.minlength">Mindestlänge 6 Zeichen</mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="'never'">
        <input matInput [(ngModel)]="model.passwordRepeat" name="password" placeholder="Passwort wiederholen"
          [type]="!pwHide2 ? 'password' : 'text'" #passwordRepeat="ngModel" required minlength="6">
        <i *ngIf="!pwHide2" class="icon icon-eye" matSuffix (click)="pwHide2 = !pwHide2"></i>
        <i *ngIf="pwHide2" class="icon icon-eye-blocked" matSuffix (click)="pwHide2 = !pwHide2"></i>
        <mat-error *ngIf="passwordRepeat.invalid && passwordRepeat.errors.required">Pflichtfeld</mat-error>
        <mat-error *ngIf="passwordRepeat.invalid && passwordRepeat.errors.minlength">Mindestlänge 6 Zeichen</mat-error>
      </mat-form-field>
      <div id="login-error" *ngIf="error">
        {{error}}
      </div>
      <button mat-raised-button color="accent" type="submit"
        (click)="!signupForm.valid ? signupForm.touch : registerSimpleUser($event)">Registrieren</button>
    </form>
  </div>
</div>