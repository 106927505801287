import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material/material.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RootTableDetailToolbarComponent } from './root-table-detail-toolbar/root-table-detail-toolbar.component';
import { RootTableStateService } from './root-table-state.service';
import { RootToolbarService } from '../../main/root-components/root-toolbar/root-toolbar.service';
import { TranslateModule } from '@ngx-translate/core';
import { RootTableTwolinesComponent } from './root-table-helper/root-table-twolines/root-table-twolines.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule
  ],
  declarations: [
    RootTableDetailToolbarComponent,
    RootTableTwolinesComponent,
  ],
  providers: [
    RootTableStateService,
    RootToolbarService
  ],
  exports: [
    RootTableDetailToolbarComponent,
    RootTableTwolinesComponent
  ]
})
export class RootTableModule { }
