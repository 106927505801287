export class Transaction {
    chargingStationId: string;
    companyId: string;
    created: Date;
    evseId: { id: string, numberedId: number }[];
    id: any;
    idTag: string;
    vehicleId: string;
    meterStart: number;
    meterStop: number;
    meterValues: any[];
    roamingProvider: string;
    roamingStatus: string;
    startedTimestamp: string;
    stoppedTimestamp: string;
    transactionId: string;
    updated: Date;
    connectorTypes: [];
}
