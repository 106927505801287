import { TenantAPIService } from './../modules/tenant/tenant.api.service';
import { JwtService } from './jwt-helper.service';
import { CompanyAPIService } from './../modules/company/company.api.service';
import { UserAPIService } from './../modules/user/user.api.service';

import { AuthenticationService } from './authentication.service';
import { AuthenticationGuard } from '../share/helper/guards/authentication.guard';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from '../share/material/material.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthenticationAPIService } from './authentication.api.service';
import { AuthenticationAppService } from './authentication.app.service';
import { UtilityModule } from '../share/utility/utility.module';
import { PwresetComponent } from './pwreset/pwreset.component';
import { JwtModule } from '@auth0/angular-jwt';
import { SignupComponent } from './signup/signup.component';
import { TenantMainService } from '../modules/tenant/tenant-main.service';

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    UtilityModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [
          '137.251.108.204:443',
          'ubstack.iao.fraunhofer.de',
          'test.ubstack.iao.fraunhofer.de',
        ],
        blacklistedRoutes: [
          '137.251.108.204:443/authentication/api/authenticate',
          'api.openrouteservice.org:443'
        ],
        headerName: 'UbstackUserAuthorization'
      }
    })
  ],
  declarations: [
    LoginComponent,
    PwresetComponent,
    SignupComponent
  ],
  providers: [
    AuthenticationService,
    AuthenticationAPIService,
    AuthenticationAppService,
    AuthenticationGuard,
    TenantMainService,
    UserAPIService,
    CompanyAPIService,
    TenantAPIService,
    JwtService
  ]
})
export class AuthenticationModule { }
