import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthenticationAPIService } from './authentication.api.service';
import { AuthenticationGuard } from '../share/helper/guards/authentication.guard';
import { AuthenticationService } from './authentication.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { catchError, first, flatMap, map } from 'rxjs/operators';
import { CompanyAPIService } from '../modules/company/company.api.service';
import { empty } from 'rxjs/internal/observable/empty';
import { NotificationService } from '../share/utility/notification/notification.service';
import { Rights } from './../share/models/right';
import { RootLoadingService } from '../share/utility/loading/root-loading.service';
import { Sidebar } from '../config/sidebar';
import { UserAPIService } from '../modules/user/user.api.service';
import { forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';
import { TenantMainService } from '../modules/tenant/tenant-main.service';
import { DialogService } from '../share/utility/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
var AuthenticationAppService = /** @class */ (function () {
    function AuthenticationAppService(_authAPI, _auth, _router, _route, _loadingService, _notificationService, _companyAPIService, _authGuardService, _userAPIService, _tenantService, _dialogService, _translationService) {
        var _this = this;
        this._authAPI = _authAPI;
        this._auth = _auth;
        this._router = _router;
        this._route = _route;
        this._loadingService = _loadingService;
        this._notificationService = _notificationService;
        this._companyAPIService = _companyAPIService;
        this._authGuardService = _authGuardService;
        this._userAPIService = _userAPIService;
        this._tenantService = _tenantService;
        this._dialogService = _dialogService;
        this._translationService = _translationService;
        this._companies = new BehaviorSubject(null);
        this._company = new BehaviorSubject(null);
        this._user = new BehaviorSubject(null);
        this._appContext = new BehaviorSubject(null);
        this._userConfiguration = new BehaviorSubject(null);
        this._tenantConfiguration = new BehaviorSubject(null);
        this._isManageTenantView = new BehaviorSubject(false);
        this._sidebarMenu = new BehaviorSubject(null);
        this._subMenu = new BehaviorSubject(null);
        this.initFromLocalStorage();
        this._router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd && _this._router.url.includes('tenant')) {
                _this._isManageTenantView.next(true);
            }
            else {
                _this._isManageTenantView.next(false);
            }
        });
        // Listen on user object change events
        this.getUserAsOservable().subscribe(function (user) {
            if (user !== undefined && user !== null) {
                _this.storeUser();
                _this.refreshMenu(user);
            }
        });
        // Store context to localStorage when changed
        this.getAppContextAsOservable().subscribe(function (appContext) {
            if (appContext !== undefined) {
                _this.storeAppContext(appContext);
            }
        });
        // Store context to localStorage when changed
        this.getUserConfigurationAsObservable().subscribe(function (userConfig) {
            if (userConfig && userConfig !== undefined && userConfig != null) {
                _this.storeUserConfig(userConfig);
            }
        });
    }
    Object.defineProperty(AuthenticationAppService.prototype, "companies", {
        get: function () {
            return this._companies.getValue();
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationAppService.prototype.getCompaniesObservable = function () {
        return this._companies;
    };
    Object.defineProperty(AuthenticationAppService.prototype, "company", {
        get: function () {
            return this._company.getValue();
        },
        set: function (company) {
            this._company.next(company);
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationAppService.prototype.getCompanyObservable = function () {
        return this._company;
    };
    Object.defineProperty(AuthenticationAppService.prototype, "user", {
        get: function () {
            return this._user.getValue();
        },
        set: function (user) {
            this._user.next(user);
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationAppService.prototype.getUserAsOservable = function () {
        return this._user;
    };
    Object.defineProperty(AuthenticationAppService.prototype, "appContext", {
        get: function () {
            return this._appContext.getValue();
        },
        set: function (appContext) {
            this._appContext.next(appContext);
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationAppService.prototype.getAppContextAsOservable = function () {
        return this._appContext;
    };
    Object.defineProperty(AuthenticationAppService.prototype, "userConfiguration", {
        set: function (uc) {
            this._userConfiguration.next(uc);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationAppService.prototype, "tenantConfiguration", {
        set: function (tc) {
            this._tenantConfiguration.next(tc);
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationAppService.prototype.getTenantConfigurationAsObservable = function () {
        return this._tenantConfiguration;
    };
    AuthenticationAppService.prototype.getUserConfigurationAsObservable = function () {
        return this._userConfiguration;
    };
    Object.defineProperty(AuthenticationAppService.prototype, "sidebar", {
        get: function () {
            return this._sidebarMenu.getValue();
        },
        set: function (sidebar) {
            this._sidebarMenu.next(sidebar);
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationAppService.prototype.getSidebarAsObservable = function () {
        return this._sidebarMenu;
    };
    Object.defineProperty(AuthenticationAppService.prototype, "submenu", {
        set: function (routes) {
            var _this = this;
            this.getSidebarAsObservable().pipe(first())
                .subscribe(function (menu) {
                var routeArray = [];
                routes.forEach(function (menuItem) {
                    if (menuItem.data.hideInMenu != null && menuItem.data.hideInMenu === true) {
                        return;
                    }
                    var menuItemRights = menuItem.data.rights;
                    _this.user.role.rights.forEach(function (right) {
                        if ((menuItemRights.indexOf(right.name) > -1 || menuItemRights.length === 0) && !routeArray.includes(menuItem)) {
                            routeArray.push(menuItem);
                        }
                    });
                });
                _this._subMenu.next(routeArray);
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationAppService.prototype, "subMenu", {
        get: function () {
            return this._subMenu;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationAppService.prototype, "isManageTenantView", {
        get: function () {
            return this._isManageTenantView;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationAppService.prototype.initFromLocalStorage = function () {
        // Retrieve user object
        var userData = localStorage.getItem('user');
        if (userData && userData !== undefined) {
            this.user = JSON.parse(userData);
        }
        // Retrieve user configuration
        var userConfigData = localStorage.getItem('userconfig');
        if (userConfigData && userConfigData !== undefined && userConfigData !== 'undefined' && userConfigData != null) {
            this.userConfiguration = JSON.parse(userConfigData);
        }
        // Retrieve application context (tenant, company)
        if (localStorage.getItem('appContext')) {
            this.initContext();
            // Update context for display in status bar (workaround?)
            // company/tenant are not defined in every app state (e.g. tenant logout)
            if (this.appContext.company) {
                this.updateContext(this.appContext.company.id);
            }
            else {
                this.updateContext();
            }
        }
    };
    AuthenticationAppService.prototype.storeUser = function () {
        localStorage.setItem('user', JSON.stringify(this.user));
    };
    AuthenticationAppService.prototype.storeUserConfig = function (userConfiguration) {
        if (userConfiguration && userConfiguration !== undefined) {
            localStorage.setItem('userconfig', JSON.stringify(userConfiguration));
        }
    };
    AuthenticationAppService.prototype.setMaintenanceDialog = function () {
        var _this = this;
        this._authAPI.getMaintenance().subscribe(function (result) {
            if (result && result.length > 0) {
                var maintenance_1 = result.pop();
                var acknowledgedMaintenanceId = localStorage.getItem('ackMaintenanceId');
                if (acknowledgedMaintenanceId === 'undefined' ||
                    (acknowledgedMaintenanceId !== undefined && acknowledgedMaintenanceId === maintenance_1.id)) {
                    return;
                }
                var startTime = moment(maintenance_1.startTimePlanned).format('DD.MM.YY HH:mm');
                var endTime = moment(maintenance_1.endTimePlanned).format('DD.MM.YY HH:mm');
                var message = "<p>" + startTime + " - " + endTime + " <br> " + maintenance_1.message + " </p>";
                _this._dialogService.confirmHtml('dialog.maintenance', message, ['dialog.remind', 'dialog.ok']).subscribe(function (confirm) {
                    if (!confirm) {
                        localStorage.setItem('ackMaintenanceId', maintenance_1.id);
                    }
                });
            }
        });
    };
    AuthenticationAppService.prototype.navigateAfterLogin = function (returnUrl) {
        var _this = this;
        if (this.sidebar.length === 0) {
            this._notificationService.showWarning('login.message.noMenu');
            this._auth.logout();
            this._loadingService.hideLoading();
        }
        else if (this.isSuperAdmin()) {
            // Get default tenant id from userconfig
            this._userAPIService.getOwnUserConfiguration().pipe(first()).subscribe(function (userConfig) {
                _this.userConfiguration = userConfig;
                var defaultTenantId = userConfig.userConfigurations.DEFAULT_TENANT;
                console.log('Selected default tenant: ' + defaultTenantId);
                (defaultTenantId === undefined) ? _this.navigateToTenantView(returnUrl) : _this.loginTenantId(defaultTenantId);
            });
        }
        else {
            this.navigateToUserView(returnUrl);
        }
        this.setMaintenanceDialog();
    };
    AuthenticationAppService.prototype.navigateToTenantView = function (returnUrl) {
        // Clear context to set a new context
        this.clearContext();
        returnUrl === '/' ? this._router.navigate(['/tenant']) : this.navigateToUserView(returnUrl);
    };
    AuthenticationAppService.prototype.navigateToUserView = function (returnUrl) {
        this.updateContext();
        returnUrl !== '/' ? this._router.navigate([returnUrl]) : this.navigateToFirstElementInMenu();
    };
    AuthenticationAppService.prototype.isSuperAdmin = function () {
        return this._authGuardService.hasRight([Rights.manage_all_tenants]);
    };
    AuthenticationAppService.prototype.loginTenantId = function (id) {
        // this._loadingService.showLoading();
        console.log('loginTenantID: ' + id);
        var tenant;
        // Do we need to load the tenant object for this tenantId?
        if (this.appContext && this.appContext.tenant != null && this.appContext.tenant.id === id) {
            tenant = this.appContext.tenant;
            this.selectTenant(tenant);
        }
        else {
            this.selectByTenantId(id);
        }
    };
    AuthenticationAppService.prototype.loginTenant = function (tenant) {
        this._loadingService.showLoading();
        this.selectTenant(tenant);
    };
    AuthenticationAppService.prototype.selectTenant = function (tenant) {
        var _this = this;
        this._authAPI.getTenantToken(tenant.id).pipe(map(function (response) { return response.body.token; }), first(), catchError(function (error) {
            console.error('Error in tenant login: ' + error.message);
            return empty();
        })).subscribe(function (token) {
            localStorage.setItem('token', token);
            _this.updateContextWithTenant(null, tenant);
            _this.getSidebarAsObservable().pipe(first(function (routeArray) { return routeArray !== null && routeArray !== undefined; }))
                .subscribe(function (routeArray) {
                _this.navigateToFirstElementInMenu();
            });
        });
    };
    AuthenticationAppService.prototype.loginCompany = function (companyId) {
        var _this = this;
        this._authAPI.getCompanyToken(companyId).pipe(map(function (response) {
            var token = response.body.token;
            localStorage.setItem('token', token);
        }), first(), catchError(function (error) {
            console.error('Error in company login' + error.message);
            return empty();
        })).subscribe(function (token) {
            var returnUrl = _this._router.routerState.snapshot.url || '/';
            _this._router.navigate([returnUrl]);
            _this._company.next(_this.getCurrentCompany(companyId));
            _this.updateContext(companyId);
        });
    };
    AuthenticationAppService.prototype.logoutTenant = function () {
        this.clearContext();
        // Should we really remove the default setting, when the user logs out of a tenent?
        // this.changeDefaultTenant();
    };
    AuthenticationAppService.prototype.changeDefaultTenant = function () {
        var userConfig = this.userConfiguration;
        userConfig.userConfigurations.DEFAULT_TENANT = null;
        this.userConfiguration = userConfig;
        this._userAPIService.updateUserOwnConfiguration(userConfig).subscribe(function (val) { return console.log(val); });
    };
    AuthenticationAppService.prototype.impersonateUser = function (userId) {
        var _this = this;
        this._loadingService.showLoading();
        var obsUser = this._userAPIService.getUser(userId);
        var obsToken = this._authAPI.impersonateUser(userId);
        var subscription = forkJoin(obsUser, obsToken);
        subscription.pipe(map(function (response) {
            localStorage.setItem('originalUser', localStorage.getItem('user'));
            localStorage.setItem('user', JSON.stringify(response[0]));
            _this.user = response[0];
            localStorage.setItem('originalToken', localStorage.getItem('token'));
            localStorage.setItem('token', response[1]['token']);
        }), flatMap(function () {
            return _this._userAPIService.getOwnUserConfiguration();
        }), map(function (userConfig) {
            localStorage.setItem('userconfig', JSON.stringify(userConfig));
            _this.userConfiguration = userConfig;
        }), catchError(function (error) {
            console.error('Error in impersonation: ' + error.message);
            return empty();
        })).subscribe(function (result) {
            _this.updateContext();
            _this.navigateToFirstElementInMenu();
            _this._notificationService.showSuccess('user.message.userImpersonated');
        });
    };
    AuthenticationAppService.prototype.quitImpersonateUser = function () {
        var _this = this;
        this._loadingService.showLoading();
        localStorage.setItem('user', localStorage.getItem('originalUser'));
        localStorage.removeItem('originalUser');
        localStorage.setItem('token', localStorage.getItem('originalToken'));
        localStorage.removeItem('originalToken');
        this._userAPIService.getOwnUserConfiguration().pipe(first())
            .subscribe(function (userConfig) {
            localStorage.setItem('userconfig', JSON.stringify(userConfig));
            _this.user = JSON.parse(localStorage.getItem('user'));
            _this.updateContext();
            _this.navigateToFirstElementInMenu();
            _this._notificationService.showSuccess('user.message.userImpersonationFinished');
        });
    };
    AuthenticationAppService.prototype.updatePassword = function (form) {
        var _this = this;
        this._loadingService.showLoading();
        this._authAPI.updatePassword(form).pipe(first()).subscribe(function (result) {
            _this._loadingService.hideLoading();
            _this._notificationService.showSuccess('user.message.userPasswordResetConfirmation');
        });
    };
    AuthenticationAppService.prototype.requestNewPassword = function (username) {
        var _this = this;
        this._authAPI.requestPasswordreset(username).pipe(first()).subscribe(function (result) {
            _this._notificationService.showSuccess('user.message.userPasswordLinkSent');
        });
    };
    AuthenticationAppService.prototype.refreshMenu = function (user) {
        // refresh tbe sidemenu to only show the menu items the user is allowed to see
        var sidebarArray = [];
        Sidebar.sidebarItems.forEach(function (menuCategory) {
            if (menuCategory.name !== 'router.headers.marketplace' ||
                (menuCategory.name === 'router.headers.marketplace' && environment.efsDevMode)) {
                var newCategory_1 = {
                    name: menuCategory.name,
                    items: []
                };
                menuCategory.items.forEach(function (menuItem) {
                    // check if the users has the rights to see the menu item
                    var menuItemRights = menuItem.rights;
                    var addMenuItem = menuItemRights.length === 0;
                    user.role.rights.forEach(function (right) {
                        if (menuItemRights.indexOf(right.name) > -1) {
                            addMenuItem = true;
                        }
                    });
                    if (addMenuItem) {
                        newCategory_1.items.push(menuItem);
                    }
                });
                // add the category to the sidebar if it contains items
                if (newCategory_1.items.length > 0) {
                    sidebarArray.push(newCategory_1);
                }
            }
        });
        this.sidebar = sidebarArray;
    };
    AuthenticationAppService.prototype.navigateToFirstElementInMenu = function () {
        this.sidebar[0] && this.sidebar[0].items[0] && this.sidebar[0].items[0].path ?
            this._router.navigate([this.sidebar[0].items[0].path]) : this._router.navigate(['/']);
    };
    AuthenticationAppService.prototype.getCurrentCompany = function (companyId) {
        var currentCompany;
        this.companies.forEach(function (company) {
            if (company.id === companyId) {
                currentCompany = company;
            }
        });
        // the passed companyId is not part of this tenant
        // take the first one available from the list
        if (!currentCompany) {
            currentCompany = this.companies[0];
        }
        return currentCompany;
    };
    AuthenticationAppService.prototype.selectByTenantId = function (tenantId) {
        var _this = this;
        var currentTenant;
        return this._tenantService.getObjects().subscribe(function (response) {
            if (response.length > 0) {
                currentTenant = response[0];
            }
            response.forEach(function (tenant) {
                if (tenant.id === tenantId) {
                    currentTenant = tenant;
                }
            });
            _this.selectTenant(currentTenant);
            return currentTenant;
        });
    };
    // private getSelectedTenantId() {
    // return (this.getAppContext() ? this.getAppContext().tenant.id : null);
    // }
    AuthenticationAppService.prototype.initContext = function () {
        var appContext = JSON.parse(localStorage.getItem('appContext'));
        this.appContext = appContext;
    };
    AuthenticationAppService.prototype.updateContextWithTenant = function (companyId, tenant) {
        var _this = this;
        var isTenantAdmin = this._authGuardService.hasRight([Rights.manage_companies]);
        var tenantConfigObservable = this._userAPIService.getTenantConfiguration();
        var companyCtx;
        // if no company is defined, take the companyId from user object
        if (!companyId) {
            if (this.appContext && this.appContext.company != null && this.appContext.company !== undefined) {
                companyId = this.appContext.company.id;
            }
            else {
                companyId = this.user.companyId;
            }
        }
        // Tenant Admin can switch between companies.
        // He needs all companies in the tenant
        // Update tenant configuration
        if (isTenantAdmin) {
            // Get new company from the selected company id
            forkJoin(this._companyAPIService.getCompanies(), tenantConfigObservable).subscribe(function (response) {
                _this._companies.next(response[0]);
                companyCtx = _this.getCurrentCompany(companyId);
                // update selected company
                _this.company = companyCtx;
                _this.setAppContext(_this.user, companyCtx, tenant, response[1]);
            });
        }
        else {
            // Select the company of the ordinary user from the user object
            forkJoin(this._companyAPIService.getCompany(companyId), tenantConfigObservable).subscribe(function (response) {
                companyCtx = response[0];
                _this.company = companyCtx;
                _this._companies.next(null);
                _this.setAppContext(_this.user, companyCtx, tenant, response[1]);
            });
        }
    };
    AuthenticationAppService.prototype.updateContext = function (companyId) {
        // Take the user.tenant when no explicit tenant is set
        var tenantCtx;
        if (this.appContext && this.appContext.tenant != null && this.appContext.tenant !== undefined) {
            tenantCtx = this.appContext.tenant;
        }
        else {
            tenantCtx = this.user.tenant;
        }
        this.updateContextWithTenant(companyId, tenantCtx);
    };
    AuthenticationAppService.prototype.clearCompanyAppContext = function () {
        var appContext = this.appContext;
        if (appContext && appContext !== undefined) {
            appContext.company = null;
        }
    };
    AuthenticationAppService.prototype.setAppContext = function (user, company, tenant, tenantConfig) {
        var appContext = {
            user: this.user,
            company: company,
            tenant: tenant,
            tenantConfig: tenantConfig
        };
        this.appContext = appContext;
    };
    AuthenticationAppService.prototype.clearContext = function () {
        var clearedAppContext = {
            user: this.user,
            company: null,
            tenant: null,
            tenantConfig: null
        };
        this.appContext = clearedAppContext;
        // Remove the item from localStorage - Perhaps
        // localStorage.removeItem('appContext');
    };
    AuthenticationAppService.prototype.storeAppContext = function (appContext) {
        if (appContext && appContext !== undefined) {
            localStorage.setItem('appContext', JSON.stringify(appContext));
        }
    };
    return AuthenticationAppService;
}());
export { AuthenticationAppService };
