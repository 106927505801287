import { CableInlet, ChargingCharacteristic, LicenseCategories } from './index';

export class VehicleModel {
    id: string;
    tenantId: string;
    manufacturer: string;
    modelName: string;
    licenseCategories: LicenseCategories;
    vehicleCategory: string;
    vehicleType: VehicleType;
    expectedElectricDrivingRangeMin: number;
    expectedElectricDrivingRangeMax: number;
    energyConsumptionPerHundredKm: number;
    fuelConsumptionPerHundredKm: number;
    fuelType: FuelType;
    pictureResource: string;
    carbonDioxideImpactPerKm: number;
    loadCapacityKG: number;
    batteryCapacityKwh: number;
    cableInlet: CableInlet;
    companyId: string;
    chargingCharacteristic: ChargingCharacteristic;
}

export enum VehicleType {
    'ELECTRIC',
    'CONVENTIONAL',
    'HYBRID',
}

export enum FuelType {
    'Super 95',
    'Super 95 E10',
    'Super Plus 98',
    'Diesel',
    'Wasserstoff',
    'Erdgas',
}

