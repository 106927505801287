import { JwtService } from './../../authentication/jwt-helper.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthHttpAdapter } from './auth-http.adapter';
import { ConfigService } from '../../app-config.service';
import { AuthHttpResponseHandler } from './auth-httpResponseHandler.service';
import { HttpClient, HttpHeaders, HttpEvent, HttpResponse, HttpRequest } from '@angular/common/http';
import { RootLoadingService } from '../utility/loading/root-loading.service';
import { Global } from '../../config/global';
import { timeout, map, catchError, flatMap, filter } from 'rxjs/operators';

/**
 * Supported @Produces media types
 */
export enum MediaType {
    JSON,
    FORM_DATA,
    BYTE_ARRAY,
    MOTOWN,
    FILE
}

@Injectable()
export class AuthHttpService {

    private headers: HttpHeaders;

    public constructor(
        protected authHttp: HttpClient,
        protected jwtService: JwtService,
        protected loadingService: RootLoadingService,
        protected configService: ConfigService,
        protected responseHandler: AuthHttpResponseHandler
    ) {
        this.headers = new HttpHeaders(
            {
                'Accept': ['application/json', 'text/plain', '*/*']
                // 'Access-Control-Allow-Origin': '*',
                // 'UbstackUserAuthorization' : 'Bearer ' + localStorage.getItem('token')
            }
        );
    }

    protected loggedIn() {
        this.jwtService.isTokenExpired();
    }

    protected getDefaultHeaders(): Object {
        return null;
    }
    /**
    * Request Interceptor
    *
    * @method requestInterceptor
    * @param {Request} req - request object
    */
    protected requestInterceptor(req: HttpRequest<any>, adapterFn?: Function) {
        return adapterFn ? adapterFn(req) : req;
    }

    /**
    * Response Interceptor
    *
    * @method responseInterceptor
    * @param {Response} observableRes - response object
    * @returns {Response} res - transformed response object
    */
    protected responseInterceptor(observableRes: Observable<HttpEvent<any>>, adapterFn?: Function): Observable<any> {
        return observableRes.pipe(
            timeout(Global.timeout),
            filter(event => {
                return event instanceof HttpResponse;
            }),
            map(event => {
                if (event instanceof HttpResponse) {
                    return AuthHttpAdapter.baseAdapter(event, adapterFn);
                }
            }),
            catchError((err, source) => {
                this.loadingService.hideLoading();
                if (err && err.name && err.name === 'TimeoutError') {
                    err.status = 'timeout';
                }
                return this.responseHandler.onCatch(err, source);
            })
        );
    }
}
