export class Location {
    name: string;
    street: string;
    tenantId: string;
    contact: string;
    logoURL: string;
    country: string;
    housenumber: string;
    zipcode: string;
    city: string;
    region: string;
    id: string;
    companyId: string;
    geoLatitude: number;
    geoLongitude: number;
}
