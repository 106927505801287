import { RootLoadingService } from './root-loading.service';
import { OnInit } from '@angular/core';
var RootLoadingComponent = /** @class */ (function () {
    function RootLoadingComponent(rootLoadingService) {
        this.rootLoadingService = rootLoadingService;
        this._loading = false;
    }
    RootLoadingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.rootLoadingService.loaderState
            .subscribe(function (state) {
            _this._loading = state.show;
        });
    };
    Object.defineProperty(RootLoadingComponent.prototype, "loading", {
        get: function () {
            return this._loading;
        },
        enumerable: true,
        configurable: true
    });
    return RootLoadingComponent;
}());
export { RootLoadingComponent };
