var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DomSanitizer } from '@angular/platform-browser';
// tslint:disable-next-line:import-blacklist
import { empty, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { RootTableService } from '../../share/components/table/root-table.service';
import { Tenant } from '../../share/models';
import { DialogService } from '../../share/utility/dialog/dialog.service';
import { RootLoadingService } from '../../share/utility/loading/root-loading.service';
import { NotificationService } from '../../share/utility/notification/notification.service';
import { TenantAPIService } from './tenant.api.service';
var TenantMainService = /** @class */ (function (_super) {
    __extends(TenantMainService, _super);
    function TenantMainService(_tenantAPI, _notificationService, _dialogService, _loadingService, _domSanitizer) {
        var _this = _super.call(this) || this;
        _this._tenantAPI = _tenantAPI;
        _this._notificationService = _notificationService;
        _this._dialogService = _dialogService;
        _this._loadingService = _loadingService;
        _this._domSanitizer = _domSanitizer;
        return _this;
    }
    TenantMainService.prototype.createObject = function (formGroup) {
        var _this = this;
        this._loadingService.showLoading();
        return this._tenantAPI.createTenant(formGroup).pipe(map(function (result) {
            _this._loadingService.hideLoading();
            _this._notificationService.showSuccess('tenant.message.tenantCreated');
        }));
    };
    TenantMainService.prototype.updateObject = function (form, id) {
        var _this = this;
        return this._dialogService.confirm('tenant.message.tenantUpdateConfirm').pipe(flatMap(function (confirm) {
            if (confirm) {
                return _this._tenantAPI.updateTenant(form, id).pipe(map(function (result) {
                    _this._notificationService.showSuccess('tenant.message.tenantUpdated');
                }));
            }
        }));
    };
    TenantMainService.prototype.updateTenantConfig = function (configuration, id) {
        var _this = this;
        return this._dialogService.confirm('tenant.message.tenantUpdateConfirm').pipe(flatMap(function (confirm) {
            if (confirm) {
                return _this._tenantAPI.updateTenantConfig(configuration, id).pipe(map(function (result) {
                    _this._notificationService.showSuccess('tenant.message.tenantUpdated');
                }));
            }
        }));
    };
    TenantMainService.prototype.createGenericObject = function () {
        return new Tenant();
    };
    TenantMainService.prototype.getTenantConfig = function (id) {
        return this._tenantAPI.getTenantConfig(id).pipe(map(function (response) {
            return response.body.tenantConfigurations;
        }), catchError(function (error) {
            console.error('Error getting TenantConfig: ' + error.message);
            return empty();
        }));
    };
    TenantMainService.prototype.deleteObject = function (object) {
        var _this = this;
        return this._dialogService.confirm('Are you sure you wish to delete this tenant?').pipe(flatMap(function (confirm) {
            if (confirm) {
                return _this._tenantAPI.deleteTenant(object.id).pipe(map(function (result) {
                    _this._notificationService.showSuccess('Successfully Deleted');
                }));
            }
        }));
    };
    TenantMainService.prototype.getObjects = function () {
        return this._tenantAPI.getTenants();
    };
    TenantMainService.prototype.getAll = function () {
        throw new Error('Method not implemented.');
    };
    TenantMainService.prototype.getImageOfTenant = function (tenantId) {
        var _this = this;
        return this._tenantAPI.getImageOfTenant(tenantId).pipe(map(function (response) { return _this._domSanitizer.bypassSecurityTrustUrl(response); }), catchError(function (error) {
            return of('/assets/img/default-image.png');
        }));
    };
    TenantMainService.prototype.uploadImageOfTenant = function (tenantId, file) {
        return this._tenantAPI.createTenantImage(file, tenantId).pipe(map(function (response) { return response; }), catchError(function (error) {
            console.log('Tenant image error: ', error);
            // Change for better default image
            return of('');
        }));
    };
    /**
     * Maintenance
     */
    TenantMainService.prototype.getMaintenance = function () {
        return this._tenantAPI.getMaintenance();
    };
    TenantMainService.prototype.createMaintenance = function (form) {
        var _this = this;
        return this._tenantAPI.createMaintenance(form).pipe(map(function (result) {
            _this._notificationService.showSuccess('Maintenance created');
            return result;
        }));
    };
    TenantMainService.prototype.updateMaintenance = function (form, id) {
        var _this = this;
        return this._tenantAPI.updateMaintenance(form, id).pipe(map(function (result) {
            _this._notificationService.showSuccess('Maintenance updated');
            return result;
        }));
    };
    TenantMainService.prototype.deleteMaintenance = function (id) {
        var _this = this;
        return this._tenantAPI.deleteMaintenance(id).pipe(map(function (result) {
            _this._notificationService.showSuccess('Maintenance deleted');
        }));
    };
    return TenantMainService;
}(RootTableService));
export { TenantMainService };
