<div *ngIf="form">
  <form [formGroup]="form">

    <!-- Multiple Fieldsets -->
    <div *ngIf="formFieldsets">
      <div *ngFor="let fieldset of formFieldsets"
        [ngClass]="{'no-label-wrapper': fieldset.label === null, 'label-wrapper': fieldset.label !== null}"
        [hidden]="fieldset.label === 'hidden' || !fieldset.show">
        <div *ngIf="fieldset.triggerValue ? fieldset.triggerValue.includes(triggerValue): true">
          <h1 *ngIf="fieldset.label" class="form-fieldset-header">{{fieldset.label | translate}}</h1>
          <div *ngIf="fieldset.sublabels" class="form-fieldset-subheader">
            <ng-container *ngFor="let sublabel of fieldset.sublabels">
              <div class="badge">
                <i *ngIf="sublabel.icon" [ngClass]="'icon lineicon-' + sublabel.icon"></i>
                <span class="form-fieldset-header">{{sublabel.name | translate}}</span>
              </div>
            </ng-container>
          </div>

          <div class="form-fieldset" [ngClass]="{'no-label': fieldset.label === null}">
            <ng-container *ngFor="let formItem of fieldset.formItems">
              <div *ngIf="!formItem.hidden" [class.full-row]="formItem.singlerow" [ngClass]="formItem.controlType" class="form-row">
                <app-dynamic-form-item [formObject]="formObject" [groupName]="fieldset.groupName" [formItem]="formItem"
                  [form]="form" [disabled]="disabled" (onDateChange)="dateChange($event)" (onImageChange)="onImageChange.emit($event)"></app-dynamic-form-item>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Single Fieldset -->
    <div *ngIf="formFieldset">
      <div *ngIf="formFieldset.triggerValue ? formFieldset.triggerValue.includes(triggerValue): true">
        <h1 class="form-fieldset-header">{{formFieldset.label}}</h1>
        <div class="form-fieldset">
          <ng-container *ngFor="let formItem of formFieldset.formItems">
            <div *ngIf="!formItem.hidden" [class.full-row]="formItem.singlerow" [ngClass]="formItem.controlType" class="form-row">
              <app-dynamic-form-item [formObject]="formObject" [groupName]="formFieldset.groupName"
                [formItem]="formItem" [form]="form" [disabled]="disabled" (onDateChange)="dateChange($event)">
              </app-dynamic-form-item>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="dynamic-form" *ngIf="buildDynamicFieldset">
      <div *ngIf="dynamicFormIdentifier" class="dynamic-form-identifier">{{dynamicFormIdentifier}}</div>
      <div class="button-icon action" (click)="addFormItem()">
        <i class="icon lineicon-plus-circle" matprefix></i>
      </div>
      <div *ngIf="dynamicForm.length > 0" class="button-icon red" (click)="removeLastFormItem()">
        <i class="icon lineicon-cross-circle" matprefix></i>
      </div>
    </div>

    <div class="form-item" *ngIf="!disabled && !noSubmitButton">
      <button mat-raised-button color="accent" type="submit" (click)="!form.valid ? touchAll(form) : onSubmit()">
        {{'rootTableDetail.save' | translate}}</button>
      <button mat-button *ngIf="additionalButton" type="button"
        (click)="additionalButtonClicked.emit('true')">{{additionalButton}}</button>
      <button mat-button *ngIf="cancelButton" type="button"
        (click)="cancelClicked.emit('true')">{{'rootTableDetail.cancel' | translate}}</button>
    </div>
  </form>
</div>
