import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(item: string, searchText: string): string {
    let returnValue;
    if (!item) {
      returnValue = '';
    } else if (!searchText) {
      returnValue = item;
    } else {
      returnValue = item.replace(new RegExp(searchText, 'gi'), match => {
        return '<span class="highlightedSpan">' + match + '</span>';
      });
    }
    return returnValue;
  }
}

