import { BehaviorSubject } from 'rxjs';
import { FormFieldset, DependValue } from '../form-item-base';
import { FormItemControlService } from '../form-item-control.service';
// tslint:disable-next-line:max-line-length
import { EventEmitter, OnChanges, OnInit, OnDestroy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
var DynamicFormComponent = /** @class */ (function () {
    function DynamicFormComponent(ficService, _cdr) {
        this.ficService = ficService;
        this._cdr = _cdr;
        this.submitted = new EventEmitter();
        this.formEmitter = new EventEmitter();
        this.cancelClicked = new EventEmitter();
        this.additionalButtonClicked = new EventEmitter();
        this.formValueChange = new EventEmitter();
        this.onImageChange = new EventEmitter();
        this.submittedValue = new EventEmitter();
        this._formFieldsets = new BehaviorSubject([]);
        this._formFieldset = new BehaviorSubject(null);
        this.payLoad = '';
    }
    Object.defineProperty(DynamicFormComponent.prototype, "formFieldsets", {
        get: function () {
            return this._formFieldsets.getValue();
        },
        set: function (formFieldsets) {
            this._formFieldsets.next(formFieldsets);
            if (!this._basicFormFieldsets) {
                this._basicFormFieldsets = formFieldsets;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DynamicFormComponent.prototype, "formFieldset", {
        get: function () {
            return this._formFieldset.getValue();
        },
        set: function (formFieldset) {
            this._formFieldset.next(formFieldset);
        },
        enumerable: true,
        configurable: true
    });
    DynamicFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.formFieldsets && this.dynamicForm) {
            this.formFieldsets = this.formFieldsets.concat(this.dynamicForm);
            this.buildForm();
        }
        else if (this.formFieldsets) {
            this.buildForm();
        }
        else if (this.formFieldset) {
            this.buildSingleForm();
        }
        this._triggerSubscription = this.ficService.triggerValue.subscribe(function (value) {
            _this.triggerValue = value;
        });
    };
    DynamicFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes.formFieldsets !== undefined && changes.formFieldsets.currentValue !== null) {
            this.form = this.ficService.toFormGroup(this.formFieldsets);
            this.formEmitter.emit(this.form);
            this.registerEvents();
        }
        this.toggleFormState();
    };
    DynamicFormComponent.prototype.ngOnDestroy = function () {
        this._triggerSubscription.unsubscribe();
    };
    DynamicFormComponent.prototype.onSubmit = function () {
        this.submitted.emit('submit');
        this.submittedValue.emit(this.form);
        this.payLoad = JSON.stringify(this.form.value);
    };
    DynamicFormComponent.prototype.formIsValid = function () {
        return this.form.valid;
    };
    DynamicFormComponent.prototype.dateChange = function (dependsOnDate) {
        this.formFieldsets = this.ficService.updateDateFields(this.formFieldsets, dependsOnDate);
        this._cdr.detectChanges();
    };
    DynamicFormComponent.prototype.imageChange = function (image) {
        this.onImageChange.emit(image);
        this._cdr.detectChanges();
    };
    // used to add formitems to dynamic form
    DynamicFormComponent.prototype.addFormItem = function () {
        if (!this.dynamicForm) {
            this.dynamicForm = [];
        }
        var dynamicFieldset = this.buildDynamicFieldset(null, this.dynamicForm);
        dynamicFieldset.show = true;
        var basic = this._basicFormFieldsets;
        var formGroup = this.ficService.toSingleFormGroup(dynamicFieldset);
        this.dynamicForm.push(dynamicFieldset);
        this.form.addControl(dynamicFieldset.groupName, formGroup);
        this.formFieldsets = basic.concat(this.dynamicForm);
        this._basicFormFieldsets = basic;
    };
    // used to remove from dynamic form
    DynamicFormComponent.prototype.removeLastFormItem = function () {
        if (this.dynamicForm && this.dynamicForm.length > 0) {
            var dynamicFormItem = this.dynamicForm.pop();
            var basic = this._basicFormFieldsets;
            this.form.removeControl(dynamicFormItem.groupName);
            this.formFieldsets = basic.concat(this.dynamicForm);
            this._basicFormFieldsets = basic;
        }
    };
    /**
     * Marks all controls in a form group as touched
     * @param formGroup - The form group to touch
     */
    DynamicFormComponent.prototype.touchAll = function (formGroup) {
        var _this = this;
        console.error('Not all required fields');
        Object.values(formGroup.controls).forEach(function (control) {
            control.markAsTouched();
            // check if value is formgroup
            // recursive iteration
            if (control.controls) {
                _this.touchAll(control);
            }
        });
    };
    DynamicFormComponent.prototype.toggleFormState = function () {
        if (this.form) {
            if (this.disabled) {
                this.form.disable();
            }
            else {
                this.form.enable();
            }
        }
    };
    DynamicFormComponent.prototype.registerEvents = function () {
        var _this = this;
        this.form.valueChanges.subscribe(function (status) {
            _this.ficService.validationStatus.next(_this.formIsValid());
            _this.formValueChange.emit(status);
        });
    };
    DynamicFormComponent.prototype.buildForm = function () {
        if (this.dynamicForm) {
            var basic = this._basicFormFieldsets;
            this.formFieldsets = basic.concat(this.dynamicForm);
            this._basicFormFieldsets = basic;
        }
        this.form = this.ficService.toFormGroup(this.formFieldsets);
        this.formEmitter.emit(this.form);
        this.registerEvents();
        this.toggleFormState();
    };
    DynamicFormComponent.prototype.buildSingleForm = function () {
        this.form = this.ficService.toSingleFormGroup(this.formFieldset);
        this.formEmitter.emit(this.form);
        this.registerEvents();
    };
    return DynamicFormComponent;
}());
export { DynamicFormComponent };
