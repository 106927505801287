import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { BootController } from './boot-control';
import 'hammerjs';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
var init = function () {
    // reset login status
    // if (!environment.local) {
    // localStorage.removeItem('token');
    // localStorage.removeItem('user');
    // localStorage.removeItem('userconfig');
    // localStorage.removeItem('chargingToken');
    // localStorage.removeItem('originalToken');
    // localStorage.removeItem('appContext');
    // localStorage.removeItem('originalUser');
    // }
    __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
        .then(function () { return window.appBootstrap && window.appBootstrap(); })
        .catch(function (err) { return console.error('NG Bootstrap Error =>', err); });
};
var ɵ0 = init;
// Init on first load
init();
var ɵ1 = function () { return init(); };
// Init on reboot request
var boot = BootController.getbootControl().watchReboot().subscribe(ɵ1);
export { ɵ0, ɵ1 };
