var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { Adapter, APIKey, Body, DELETE, GET, POST, PUT, FULLAPI, Headers, ResponseType } from '../../share/http/auth-http.decorator';
import { AuthHttpAdapter } from '../../share/http/auth-http.adapter';
import { AuthHttpService } from '../../share/http/auth-http.service';
import { Booking, Vehicle } from '../../share/models/index';
import { FormGroup } from '@angular/forms';
import { MediaType, Path, Produces } from '../../share/http/index';
import { Observable } from 'rxjs';
var csvFiles = { 'Content-Type': 'multipart/form-data' };
var imgType = { 'Accept': 'image/webp,image/png,image/*,*/*;q=0.8' };
var VehicleAPIService = /** @class */ (function (_super) {
    __extends(VehicleAPIService, _super);
    function VehicleAPIService() {
        // implements IVehicleRequest, IVehicleModelRequest  {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._api = _this.configService.getAPI('vehicles');
        return _this;
        /* public updateVehicle(vehicle: string): Observable<Response> {
          try {
            const vehicleObject = <Vehicle>JSON.parse(vehicle);
            const path = this._api + '/vehicles/' + vehicleObject.id;
            const body = JSON.stringify(vehicle);
            return this.putObject(path, body);
          } catch (error) {
            console.log(error);
          }
        }
      
        public deleteVehicle(vehicle: string): Observable<Response> {
          try {
            const vehicleObject = <Vehicle>JSON.parse(vehicle);
            const path = this._api + '/vehicles/' + vehicleObject.id;
            return this.delObject(path);
          } catch (error) {
            console.log(error);
          }
        }
      
        // Implements IVehicleRequest
        public getVehicles(): Observable<Vehicle[]> {
          const path = this._api + '/vehicles';
          return this.getArray<Vehicle>(path);
        }
       */
        /*
          public getVehiclesByTenant(tenantId: string): Observable<Vehicle[]> {
            const path = this._api + '/vehicles/tenants/' + tenantId + '/vehicles';
            return this.getArray<Vehicle>(path);
          }
      
          public getActiveVehicleByVehicleCategory(vehicleCategory: string): Observable<Vehicle> {
            const path = this._api + '/vehicles?active=true&category=' + vehicleCategory;
            return this.getObject<Vehicle>(path);
          }
      
          public getVehicleByVehicleCategory(vehicleCategory: string): Observable<Vehicle> {
            const path = this._api + '/vehicles?category=' + vehicleCategory;
            return this.getObject<Vehicle>(path);
          }
      
          public getVehicleByLicensePlateNumber(licensePlateNumber: string): Observable<Vehicle> {
            const path = this._api + '/vehicles?licensePlateNumber=' + licensePlateNumber;
            return this.getObject<Vehicle>(path);
          }
      
          public getActiveVehiclesForBooking(bookingId: string): Observable<Vehicle[]> {
            const path = this._api + '/vehicles?active=true&singleBookingId=' + bookingId;
            return this.getArray<Vehicle>(path);
          }
      
          public getVehicleByVin(vin: string): Observable<Vehicle> {
            const path = this._api + '/vehicles?vin=' + vin;
            return this.getObject<Vehicle>(path);
          }
      
          public getActiveVehicleCategories(tenantId: string): Observable<string> {
            const path = this._api + '/tenants/' + tenantId + '/categories';
            return this.getObject<string>(path);
          }
      
          public getVehiclesBySite(siteId: string): Observable<Vehicle[]> {
            const path = this._api + '/sites/' + siteId + '/categories';
            return this.getArray<Vehicle>(path);
          }
      
          public getVehiclesByPool(poolId: string): Observable<Vehicle[]> {
            const path = this._api + '/pools/' + poolId + '/vehicles';
            return this.getArray<Vehicle>(path);
          }
      
          public updateVehiclesByPool(vehicles: Array<Vehicle>, poolId: string): Observable<Vehicle[]> {
            const path = this._api + '/pools/' + poolId + '/vehicles';
            return this.getArray<Vehicle>(path);
          }
      
          public getAvailableVehiclesForBooking(booking: Booking, filterByCategory: boolean = true): Observable<Vehicle[]> {
            // was post before
            const path = this._api + '/booking/vehicles?filterByCategory=' + filterByCategory;
            return this.getArray<Vehicle>(path);
          }
      
          // Implements IVehicleModelRequest
          public getVehicleModelsByTenant(tenantId: string): Observable<VehicleModel[]> {
            const path = this._api + '/tenants/' + tenantId + '/vehicleModels';
            return this.getArray<VehicleModel>(path);
          }
      
          public getVehicleCategories(): Observable<string[]> { */
        /*  const path = API.vehicles + '/vehicleModelsCategories';
         return this.getArray<string>(path); */
        /*  return null;
       }
      
       private getData(path: string): Observable<any> {
         return this.getObject<any>(path);
       }
      
       public getFuelTypes(): Observable<string[]> {
         // const path = API.vehicles + '/fuelType';
         // return this.getArray<string>(path);
         return null;
       } */
    }
    VehicleAPIService.prototype.createVehicle = function (form) { return null; };
    VehicleAPIService.prototype.updateVehicle = function (form, id) { return null; };
    VehicleAPIService.prototype.updateVehicleObject = function (vehicle, id) { return null; };
    VehicleAPIService.prototype.getVehicles = function () { return null; };
    VehicleAPIService.prototype.deleteVehicle = function (id) { return null; };
    VehicleAPIService.prototype.createVehicleModel = function (object) { return null; };
    VehicleAPIService.prototype.updateVehicleModel = function (object, id) { return null; };
    VehicleAPIService.prototype.getVehicleModels = function () { return null; };
    VehicleAPIService.prototype.deleteVehicleModel = function (id) { return null; };
    VehicleAPIService.prototype.getVehicleModelImages = function (id) { return null; };
    VehicleAPIService.prototype.updateVehicleModelImages = function (id, object) { return null; };
    // get default image
    VehicleAPIService.prototype.getVehicleModelMainImage = function (id) { return null; };
    VehicleAPIService.prototype.getVehicleModelImage = function (id, imageId) { return null; };
    VehicleAPIService.prototype.deleteVehicleModelImage = function (imageUri) { return null; };
    // VEHICLE CATEGORY APIs
    VehicleAPIService.prototype.createVehicleCategory = function (object) { return null; };
    VehicleAPIService.prototype.updateVehicleCategory = function (object, id) { return null; };
    VehicleAPIService.prototype.getVehicleCategories = function () { return null; };
    VehicleAPIService.prototype.deleteVehicleCategory = function (id) { return null; };
    VehicleAPIService.prototype.getVehicleCategoryImages = function (id) { return null; };
    VehicleAPIService.prototype.updateVehicleCategoryImages = function (form, id) { return null; };
    // get default image
    VehicleAPIService.prototype.getVehicleCategoryMainImage = function (id) { return null; };
    VehicleAPIService.prototype.getVehicleCategoryImage = function (id, imageId) { return null; };
    VehicleAPIService.prototype.deleteVehicleCategoryImage = function (imageUri) { return null; };
    // ELSE
    VehicleAPIService.prototype.getAvailableVehicleCategories = function (booking) { return null; };
    VehicleAPIService.prototype.getVehicleImages = function (id) { return null; };
    VehicleAPIService.prototype.updateVehicleImages = function (id, object) { return null; };
    VehicleAPIService.prototype.getVehicleImage = function (id, imageId) { return null; };
    VehicleAPIService.prototype.deleteVehicleImage = function (imageUri) { return null; };
    VehicleAPIService.prototype.getVehicleStatuses = function () { return null; };
    VehicleAPIService.prototype.importCsvFile = function (form) { return null; };
    __decorate([
        POST('/vehicles'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "createVehicle", null);
    __decorate([
        PUT('/vehicles/{id}'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup, String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "updateVehicle", null);
    __decorate([
        PUT('/vehicles/{id}'),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Vehicle, String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "updateVehicleObject", null);
    __decorate([
        GET('/vehicles'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getVehicles", null);
    __decorate([
        DELETE('/vehicles/{id}'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "deleteVehicle", null);
    __decorate([
        POST('/vehicleModels'),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "createVehicleModel", null);
    __decorate([
        PUT('/vehicleModels/{id}'),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "updateVehicleModel", null);
    __decorate([
        GET('/vehicleModels'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getVehicleModels", null);
    __decorate([
        DELETE('/vehicleModels/{id}'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "deleteVehicleModel", null);
    __decorate([
        GET('/vehicleModels/{id}/images'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getVehicleModelImages", null);
    __decorate([
        POST('/vehicleModels/{id}/images'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        Produces(MediaType.BYTE_ARRAY),
        __param(0, Path('id')), __param(1, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, Array]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "updateVehicleModelImages", null);
    __decorate([
        GET('/vehicleModels/{id}/image'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getVehicleModelMainImage", null);
    __decorate([
        GET('/vehicleModels/{id}/images/{imageId}'),
        __param(0, Path('id')), __param(1, Path('imageId')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getVehicleModelImage", null);
    __decorate([
        DELETE('{imageURI}'),
        FULLAPI(true),
        __param(0, Path('imageURI')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "deleteVehicleModelImage", null);
    __decorate([
        POST('/vehicleCategories'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "createVehicleCategory", null);
    __decorate([
        PUT('/vehicleCategories/{id}'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup, String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "updateVehicleCategory", null);
    __decorate([
        GET('/vehicleCategories'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getVehicleCategories", null);
    __decorate([
        DELETE('/vehicleCategories/{id}'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "deleteVehicleCategory", null);
    __decorate([
        GET('/vehicleCategories/{id}/images'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getVehicleCategoryImages", null);
    __decorate([
        POST('/vehicleCategories/{id}/images?override=true'),
        Produces(MediaType.FILE),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "updateVehicleCategoryImages", null);
    __decorate([
        GET('/vehicleCategories/{id}/images'),
        ResponseType('blob'),
        Headers(imgType),
        Adapter(AuthHttpAdapter.imgUrlAdapter),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getVehicleCategoryMainImage", null);
    __decorate([
        GET('/vehicleCategories/{id}/images/{imageId}'),
        __param(0, Path('id')), __param(1, Path('imageId')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getVehicleCategoryImage", null);
    __decorate([
        DELETE('{imageURI}'),
        FULLAPI(true),
        __param(0, Path('imageURI')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "deleteVehicleCategoryImage", null);
    __decorate([
        POST('/booking/vehicleCategories'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Booking]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getAvailableVehicleCategories", null);
    __decorate([
        GET('/vehicles/{id}/images'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getVehicleImages", null);
    __decorate([
        POST('/vehicles/{id}/images'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        Produces(MediaType.BYTE_ARRAY),
        __param(0, Path('id')), __param(1, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, Array]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "updateVehicleImages", null);
    __decorate([
        GET('/vehicles/{id}/images/{imageId}'),
        __param(0, Path('id')), __param(1, Path('imageId')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getVehicleImage", null);
    __decorate([
        DELETE('{imageURI}'),
        FULLAPI(true),
        __param(0, Path('imageURI')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "deleteVehicleImage", null);
    __decorate([
        GET('/statuses'),
        APIKey('vehiclestatus'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "getVehicleStatuses", null);
    __decorate([
        POST('/import/csv'),
        Produces(MediaType.FILE),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Observable)
    ], VehicleAPIService.prototype, "importCsvFile", null);
    return VehicleAPIService;
}(AuthHttpService));
export { VehicleAPIService };
