import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NotificationService } from '../notification/notification.service';
import { Global } from '../../../config/global';

export interface LoaderState {
  show: boolean;
}

@Injectable()
export class RootLoadingService {

  constructor(
    private _idle: Idle,
    private _keepalive: Keepalive,
    private _notificationService: NotificationService
  ) {
    this._idle.setIdle(1);
    this._idle.setIdleName('loading_timeout');
    this._idle.setKeepaliveEnabled(false);
    // this._idle.setIdle(null);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this._idle.setTimeout(Global.timeout / 1000);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this._idle.onTimeout.subscribe(() => {
      this.hideLoading();
      this._notificationService.showWarning('Ein Fehler ist aufgetreten');
    });
  }

  public loaderState = new Subject<LoaderState>();

  public showLoading(): void {
    this.loaderState.next(<LoaderState> {show : true});
    this._idle.watch();
  }

  public hideLoading(): void {
    this.loaderState.next(<LoaderState> {show : false});
    this._idle.stop();
  }
}
