import {MaterialModule} from '../../material/material.module';
import {DialogService} from './dialog.service';
import {DialogConfirmComponent} from './dialog-confirm/dialog-confirm.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogFormComponent } from './dialog-form/dialog-form.component';
import { FormModule } from '../../components/form/form.module';
import { TranslateModule } from '@ngx-translate/core';
import { DialogFilePickerComponent } from './dialog-file-picker/dialog-file-picker.component';
import { VehicleAPIService } from '../../../modules/vehicle/vehicle.api.service';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormModule,
    TranslateModule
  ],
  declarations: [
    DialogConfirmComponent,
    DialogFormComponent,
    DialogFilePickerComponent
  ],
  providers: [
    DialogService,
    VehicleAPIService
  ],
  entryComponents: [
    DialogConfirmComponent,
    DialogFormComponent,
    DialogFilePickerComponent
  ]
})
export class DialogModule { }
