
import { BehaviorSubject } from 'rxjs';
import { FormFieldset, DependValue, FormItemBase } from '../form-item-base';
import { FormGroup } from '@angular/forms';
import { FormItemControlService } from '../form-item-control.service';
// tslint:disable-next-line:max-line-length
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, OnDestroy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { TableState, TableEvent } from '../../table/root-table-state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() set formFieldsets(formFieldsets: FormFieldset[]) {
    this._formFieldsets.next(formFieldsets);
    if (!this._basicFormFieldsets) {
      this._basicFormFieldsets = formFieldsets;
    }
  }
  @Input() set formFieldset(formFieldset: FormFieldset) {
    this._formFieldset.next(formFieldset);
  }
  @Input() disabled: boolean;
  @Input() state: TableState;
  @Input() noSubmitButton: boolean;
  @Input() cancelButton: boolean;
  @Input() additionalButton: string;
  @Input() dynamicForm: FormFieldset[];
  @Input() dynamicFormIdentifier: string;
  @Input() buildDynamicFieldset: (param: any, formFieldsets: FormFieldset[]) => FormFieldset;
  @Output() submitted: EventEmitter<TableEvent> = new EventEmitter();
  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter();
  @Output() cancelClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() additionalButtonClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() formValueChange: EventEmitter<FormGroup> = new EventEmitter();
  @Output() onImageChange: EventEmitter<any> = new EventEmitter();
  @Output() submittedValue: EventEmitter<FormGroup> = new EventEmitter();
  private _formFieldsets = new BehaviorSubject<FormFieldset[]>([]);
  private _formFieldset = new BehaviorSubject<FormFieldset>(null);
  private _basicFormFieldsets: FormFieldset[];
  // more subscriptions -> Subscription[]
  _triggerSubscription: Subscription;
  triggerValue: string;
  form: FormGroup;
  dynamicFormFieldsets: FormFieldset[];
  payLoad = '';

  constructor(
    private ficService: FormItemControlService,
    private _cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.formFieldsets && this.dynamicForm) {
      this.formFieldsets = this.formFieldsets.concat(this.dynamicForm);
      this.buildForm();
    } else if (this.formFieldsets) {
      this.buildForm();
    } else if (this.formFieldset) {
      this.buildSingleForm();
    }
    this._triggerSubscription = this.ficService.triggerValue.subscribe(value => {
      this.triggerValue = value;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formFieldsets !== undefined && changes.formFieldsets.currentValue !== null) {
      this.form = this.ficService.toFormGroup(this.formFieldsets);
      this.formEmitter.emit(this.form);
      this.registerEvents();
    }
    this.toggleFormState();
  }

  ngOnDestroy() {
    this._triggerSubscription.unsubscribe();
  }

  get formFieldsets(): FormFieldset[] {
    return this._formFieldsets.getValue();
  }

  get formFieldset(): FormFieldset {
    return this._formFieldset.getValue();
  }

  onSubmit() {
    this.submitted.emit('submit');
    this.submittedValue.emit(this.form);
    this.payLoad = JSON.stringify(this.form.value);
  }

  formIsValid(): boolean {
    return this.form.valid;
  }

  dateChange(dependsOnDate: DependValue<Date>) {
    this.formFieldsets = this.ficService.updateDateFields(this.formFieldsets, dependsOnDate);
    this._cdr.detectChanges();
  }

  imageChange(image: any) {
    this.onImageChange.emit(image);
    this._cdr.detectChanges();
  }

  // used to add formitems to dynamic form
  addFormItem() {
    if (!this.dynamicForm) {
      this.dynamicForm = [];
    }
    const dynamicFieldset = this.buildDynamicFieldset(null, this.dynamicForm);
    dynamicFieldset.show = true;
    const basic = this._basicFormFieldsets;
    const formGroup = this.ficService.toSingleFormGroup(dynamicFieldset);
    this.dynamicForm.push(dynamicFieldset);
    this.form.addControl(dynamicFieldset.groupName, formGroup);
    this.formFieldsets = basic.concat(this.dynamicForm);
    this._basicFormFieldsets = basic;
  }

  // used to remove from dynamic form
  removeLastFormItem() {
    if (this.dynamicForm && this.dynamicForm.length > 0) {
      const dynamicFormItem = this.dynamicForm.pop();
      const basic = this._basicFormFieldsets;
      this.form.removeControl(dynamicFormItem.groupName);
      this.formFieldsets = basic.concat(this.dynamicForm);
      this._basicFormFieldsets = basic;
    }
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private touchAll(formGroup: FormGroup) {
    console.error('Not all required fields');
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      // check if value is formgroup
      // recursive iteration
      if (control.controls) {
        this.touchAll(control);
      }
    });
  }

  private toggleFormState() {
    if (this.form) {
      if (this.disabled) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  private registerEvents() {
    this.form.valueChanges.subscribe(status => {
      this.ficService.validationStatus.next(this.formIsValid());
      this.formValueChange.emit(status);
    });
  }

  private buildForm() {
    if (this.dynamicForm) {
      const basic = this._basicFormFieldsets;
      this.formFieldsets = basic.concat(this.dynamicForm);
      this._basicFormFieldsets = basic;
    }
    this.form = this.ficService.toFormGroup(this.formFieldsets);
    this.formEmitter.emit(this.form);
    this.registerEvents();
    this.toggleFormState();
  }

  private buildSingleForm() {
    this.form = this.ficService.toSingleFormGroup(this.formFieldset);
    this.formEmitter.emit(this.form);
    this.registerEvents();
  }
}
