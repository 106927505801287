var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { User } from '../../share/models/index';
import { Observable } from 'rxjs';
import { AuthHttpService, MediaType } from '../../share/http/auth-http.service';
import { AuthHttpAdapter } from '../../share/http/auth-http.adapter';
import { Path, Produces, APIKey, Body, GET, POST, PUT, DELETE, Headers, Adapter, ResponseType, RequestAdapter } from '../../share/http/auth-http.decorator';
import { FormGroup } from '@angular/forms';
import { UserConfiguration } from '../../../../core/userConfiguration';
var imgType = {
    'Accept': 'image/webp,image/png,image/*,*/*;q=0.8'
};
var UserAPIService = /** @class */ (function (_super) {
    __extends(UserAPIService, _super);
    function UserAPIService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._api = _this.configService.getAPI('users');
        return _this;
        /*
          public getUsersByRoleIdOrName(roleIdOrName: string): Observable<User[]> {
              const path = this._api + '/' + roleIdOrName + '/users';
              return this.getArray<User>(path);
          }
      
          public getUsersByCompanyId(companyId: string): Observable<User[]> {
              const path = this._api + '/companies/' + companyId + '/users';
              return this.getArray<User>(path);
          }
      
          getUserByName(name: string): Observable<User> {
              const path = this._api + '/' + name;
              return this.getObject<User>(path);
          }
      
          public getUserGroups(): Observable<UserGroup[]> {
              const path = this._api + '/usergroups';
              return this.getArray<UserGroup>(path);
          }
      
          public getRights(): Observable<Right[]> {
              const path = this._api + '/rights';
              return this.getArray<Right>(path);
          }
      
          public getRoles(): Observable<Role[]> {
              const path = this._api + '/roles';
              return this.getArray<Role>(path);
          } */
    }
    UserAPIService.prototype.checkDriverLicense = function (id) { return null; };
    UserAPIService.prototype.getUsers = function () { return null; };
    UserAPIService.prototype.activateUser = function (id) { return null; };
    UserAPIService.prototype.deactivateUser = function (id) { return null; };
    UserAPIService.prototype.getActiveUsers = function () { return null; };
    UserAPIService.prototype.createUser = function (form) { return null; };
    UserAPIService.prototype.updateUser = function (form, id) { return null; };
    UserAPIService.prototype.updateUserObject = function (user, id) { return null; };
    UserAPIService.prototype.getUser = function (id) { return null; };
    UserAPIService.prototype.updateSelf = function (form) { return null; };
    UserAPIService.prototype.updateSelfObject = function (object) { return null; };
    UserAPIService.prototype.deleteUser = function (id) { return null; };
    UserAPIService.prototype.getRoles = function () { return null; };
    UserAPIService.prototype.createRole = function (form) { return null; };
    UserAPIService.prototype.updateRole = function (form, id) { return null; };
    UserAPIService.prototype.deleteRole = function (id) { return null; };
    UserAPIService.prototype.getRights = function () { return null; };
    UserAPIService.prototype.getUserGroups = function () { return null; };
    UserAPIService.prototype.createUserGroup = function (form) { return null; };
    UserAPIService.prototype.updateUserGroup = function (form, id) { return null; };
    UserAPIService.prototype.deleteUserGroup = function (id) { return null; };
    UserAPIService.prototype.getOwnUserConfiguration = function () { return null; };
    UserAPIService.prototype.updateUserOwnConfiguration = function (userConfig) { return null; };
    UserAPIService.prototype.getTenantConfiguration = function () { return null; };
    UserAPIService.prototype.getImageOfUser = function (id) { return null; };
    UserAPIService.prototype.createUserImage = function (form, id) { return null; };
    UserAPIService.prototype.getImageOfCompany = function (id) { return null; };
    UserAPIService.prototype.createCompanyImage = function (form, id) { return null; };
    __decorate([
        POST('/users/{id}/driverslicenses/check'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "checkDriverLicense", null);
    __decorate([
        GET('/users'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "getUsers", null);
    __decorate([
        PUT('/users/{id}/activate'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "activateUser", null);
    __decorate([
        PUT('/users/{id}/deactivate'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "deactivateUser", null);
    __decorate([
        GET('/users?active=true'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "getActiveUsers", null);
    __decorate([
        POST('/users'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "createUser", null);
    __decorate([
        PUT('/users/{id}'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup, String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "updateUser", null);
    __decorate([
        PUT('/users/{id}'),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [User, String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "updateUserObject", null);
    __decorate([
        GET('/users/{id}'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "getUser", null);
    __decorate([
        PUT('/self'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "updateSelf", null);
    __decorate([
        PUT('/self'),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [User]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "updateSelfObject", null);
    __decorate([
        DELETE('/users/{id}'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "deleteUser", null);
    __decorate([
        GET('/roles'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "getRoles", null);
    __decorate([
        POST('/roles'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "createRole", null);
    __decorate([
        PUT('/roles/{id}'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup, String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "updateRole", null);
    __decorate([
        DELETE('/roles/{id}'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "deleteRole", null);
    __decorate([
        GET('/rights'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "getRights", null);
    __decorate([
        GET('/usergroups'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "getUserGroups", null);
    __decorate([
        POST('/usergroups'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "createUserGroup", null);
    __decorate([
        PUT('/usergroups/{id}'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup, String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "updateUserGroup", null);
    __decorate([
        DELETE('/usergroups/{id}'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "deleteUserGroup", null);
    __decorate([
        GET('/userconfiguration'),
        APIKey('userconfig'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "getOwnUserConfiguration", null);
    __decorate([
        PUT('/userconfiguration'),
        APIKey('userconfig'),
        RequestAdapter(AuthHttpAdapter.booleanToStringAdapter),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [UserConfiguration]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "updateUserOwnConfiguration", null);
    __decorate([
        GET('/tenantconfiguration'),
        APIKey('userconfig'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "getTenantConfiguration", null);
    __decorate([
        GET('/users/{id}/imageFile'),
        ResponseType('blob'),
        Headers(imgType),
        Adapter(AuthHttpAdapter.imgUrlAdapter),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "getImageOfUser", null);
    __decorate([
        POST('/users/{companyId}/image'),
        Produces(MediaType.FILE),
        __param(0, Body), __param(1, Path('companyId')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "createUserImage", null);
    __decorate([
        GET('/companies/{companyId}/imageFile'),
        ResponseType('blob'),
        Headers(imgType),
        Adapter(AuthHttpAdapter.imgUrlAdapter),
        __param(0, Path('companyId')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "getImageOfCompany", null);
    __decorate([
        POST('/companies/{tenantId}/image'),
        Produces(MediaType.FILE),
        __param(0, Body), __param(1, Path('tenantId')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, String]),
        __metadata("design:returntype", Observable)
    ], UserAPIService.prototype, "createCompanyImage", null);
    return UserAPIService;
}(AuthHttpService));
export { UserAPIService };
