var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { Adapter, GET, Path, Extern, APIKey } from '../share/http/auth-http.decorator';
import { AuthHttpService } from '../share/http/auth-http.service';
import { AuthHttpAdapter } from '../share/http/auth-http.adapter';
import { Observable } from 'rxjs';
import { POST, PUT, Produces, MediaType, Body } from '../share/http/index';
import { FormGroup } from '@angular/forms';
var Maintenance = /** @class */ (function () {
    function Maintenance() {
    }
    return Maintenance;
}());
export { Maintenance };
var AuthenticationAPIService = /** @class */ (function (_super) {
    __extends(AuthenticationAPIService, _super);
    function AuthenticationAPIService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._api = _this.configService.getAPI('authentication');
        return _this;
    }
    AuthenticationAPIService.prototype.getCompanyToken = function (id) { return null; };
    AuthenticationAPIService.prototype.getTenantToken = function (id) { return null; };
    AuthenticationAPIService.prototype.resetPassword = function (username, token) { return null; };
    AuthenticationAPIService.prototype.createUser = function (form) { return null; };
    AuthenticationAPIService.prototype.registerSimpleUser = function (userRegistration) { return null; };
    AuthenticationAPIService.prototype.getInvitationInfo = function (token) { return null; };
    AuthenticationAPIService.prototype.updatePassword = function (form) { return null; };
    AuthenticationAPIService.prototype.requestPasswordreset = function (username) { return null; };
    AuthenticationAPIService.prototype.impersonateUser = function (userId) { return null; };
    AuthenticationAPIService.prototype.getMaintenance = function () { return null; };
    __decorate([
        GET('/companyToken/{id}'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], AuthenticationAPIService.prototype, "getCompanyToken", null);
    __decorate([
        GET('/tenantToken/{id}'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], AuthenticationAPIService.prototype, "getTenantToken", null);
    __decorate([
        PUT('/users/{username}/password?token={token}'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __param(0, Path('username')), __param(1, Path('token')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, String]),
        __metadata("design:returntype", Observable)
    ], AuthenticationAPIService.prototype, "resetPassword", null);
    __decorate([
        POST('/users'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup]),
        __metadata("design:returntype", Observable)
    ], AuthenticationAPIService.prototype, "createUser", null);
    __decorate([
        POST('/register/simpleuser'),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Observable)
    ], AuthenticationAPIService.prototype, "registerSimpleUser", null);
    __decorate([
        Extern(true),
        GET('/register/invite/info/{invitationToken}'),
        __param(0, Path('invitationToken')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], AuthenticationAPIService.prototype, "getInvitationInfo", null);
    __decorate([
        PUT('/passwords'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup]),
        __metadata("design:returntype", Observable)
    ], AuthenticationAPIService.prototype, "updatePassword", null);
    __decorate([
        GET('/users/{username}/passwordrequesttoken'),
        __param(0, Path('username')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], AuthenticationAPIService.prototype, "requestPasswordreset", null);
    __decorate([
        GET('/impersonateToken/{id}'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], AuthenticationAPIService.prototype, "impersonateUser", null);
    __decorate([
        GET('/maintenance/next'),
        APIKey('userconfig'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], AuthenticationAPIService.prototype, "getMaintenance", null);
    return AuthenticationAPIService;
}(AuthHttpService));
export { AuthenticationAPIService };
