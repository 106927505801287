<div *ngIf="data.danger" class="dangerContainer">
  <i class="icon lineicon-warning icon-large"></i>
  <h2>{{ data.title | translate: data.translationParameters}}</h2>
</div>
<h2 *ngIf="!data.danger">{{ data.title | translate: data.translationParameters}}</h2>

<p *ngIf="data.message && !data.isHtml">{{ data.message  | translate: data.translationParameters}}</p>

<div *ngIf="data.message && data.isHtml" class="htmlContent" [innerHtml]="data.message"></div>

<div class="buttons">
  <button *ngIf="data.danger" mat-button color="warn"
    (click)="dialogRef.close(true)">{{ data.okButton  | translate }}</button>
  <button *ngIf="!data.danger" mat-button color="primary"
    (click)="dialogRef.close(true)">{{ data.okButton  | translate }}</button>
  <button mat-button (click)="dialogRef.close()">{{ data.cancelButton | translate }}</button>
</div>