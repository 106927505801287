import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToName'
})
export class ArrayToNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let returnValue = '';

    // no value given
    if (!value || value === undefined || (value instanceof Array && value.length === 0)) {
      returnValue = '---';
    } else if (value instanceof Array) {
      // look up in given array
      let sortedArray = [];
      value.forEach(element => {
        if (element.name) {
          sortedArray.push(element.name);
        } else if (element.firstName && element.lastName) {
          sortedArray.push(element.firstName + ' ' + element.lastName);
        }
      });
      // sort alphabetically
      sortedArray = sortedArray.sort();
      sortedArray.forEach(element => {
        if (returnValue !== '') {
          returnValue += ', ';
        }
        returnValue += element;
      });
    } else if (value.name) {
      returnValue = value.name;
    } else if (value.firstName && value.lastName) {
      returnValue = value.firstName + value.lastName;
    }
    return returnValue;
  }

}
