import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idToName'
})
export class IdToNamePipe implements PipeTransform {

  transform(value: any, objects: any[]): string {

    let returnValue = '';
    objects.forEach(element => {
      if (element.id && element.name) {
        if (value instanceof Array) {
          value.forEach((id, index) => {
            if (id === element.id) {
              if (returnValue !== '') {
                returnValue += ', ';
              }
              returnValue += element.name;
            }
          });
        }
        if (value === element.id) {
          returnValue = element.name;
        }
        // is user?
      } else if (element.id && element.firstName && element.lastName) {
        value.forEach(userId => {
          if (userId === element.id) {
            if (returnValue !== '') {
              returnValue += ', ';
            }
            returnValue += element.firstName + ' ' + element.lastName;
          }
        });
        // is vehicle?
      } else if (element.id && element.licensePlateNumber) {
        value.forEach(vehicle => {
          if (vehicle.id === element.id) {
            if (returnValue !== '') {
              returnValue += ', ';
            }
            returnValue += element.licensePlateNumber;
          }
        });
      }
    });
    if (returnValue === '') {
      returnValue = '---';
    }
    return returnValue;
  }
}

