 import { VehicleModel, Pool } from './index';
import { VehicleStatus } from '../../../../core/vehicleStatus';
export class Vehicle {
    id: string;
    licensePlateNumber: string;
    features: Array<string>;
    vehicleIdentificationNumber: string;
    firstRegistration: Date;
    inventoryAddedDate: Date;
    inventoryEndDate: Date;
    active: boolean;
    deleted: boolean;
    owner: string;
    adjustedElectricDrivingRange: number;
    adjustedFuelPerHundredKM: number;
    adjustedkWhPerHundredKM: number;
    vehicleModel: VehicleModel;
    seats: number;
    odometerReading: number;
    siteId: string;
    userId: string;
    rfidToken: string;
    tenantId: string;
    // pools: Pool[];
    pools: String[];
    usageType: string;
    poolObjects: Pool[];
    companyId: string;
    securedObjectId: string;
    telematicsProvider: string;
    createdAt: Date;
    updatedAt: Date;
    status: VehicleStatus;
}
