import { Adapter, GET, Path, Extern, APIKey } from '../share/http/auth-http.decorator';
import { AuthHttpService } from '../share/http/auth-http.service';
import { Injectable } from '@angular/core';
import { AuthHttpAdapter } from '../share/http/auth-http.adapter';
import { Observable } from 'rxjs';
import { POST, PUT, Produces, MediaType, Body } from '../share/http/index';
import { FormGroup } from '@angular/forms';

export interface UserRegistration {
  email?: string;
  firstname?: string;
  formOfAddress?: string;
  lastname?: string;
  password?: string;
  companyInviteCode?: string;
  termsOfUseAccepted?: boolean;
  username?: string;
  passwordRepeat?: string;
}

export interface ContactDetails {
  companyId: string;
  contactEmailAdress: string;
  contactName: string;
  contactPhonenumber: string;
}

export class Maintenance {
  createdAt: Date;
  deleted: boolean;
  endTimePlanned: Date;
  id: string;
  message: string;
  modules: any[];
  startTimePlanned: Date;
  tenantIds: any[];
  updatedAt: string;

}

@Injectable()
export class AuthenticationAPIService extends AuthHttpService {

  private _api = this.configService.getAPI('authentication');

  @GET('/companyToken/{id}')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getCompanyToken(@Path('id') id: string): Observable<any> { return null; }

  @GET('/tenantToken/{id}')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getTenantToken(@Path('id') id: string): Observable<any> { return null; }

  @PUT('/users/{username}/password?token={token}')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public resetPassword(@Path('username') username: string, @Path('token') token: string): Observable<any> { return null; }

  @POST('/users')
  @Produces(MediaType.FORM_DATA)
  public createUser(@Body form: FormGroup): Observable<any> { return null; }

  @POST('/register/simpleuser')
  public registerSimpleUser(@Body userRegistration: UserRegistration): Observable<any> { return null; }

  @Extern(true)
  @GET('/register/invite/info/{invitationToken}')
  public getInvitationInfo(@Path('invitationToken') token: string): Observable<any> { return null; }

  @PUT('/passwords')
  @Produces(MediaType.FORM_DATA)
  public updatePassword(@Body form: FormGroup): Observable<any> { return null; }

  @GET('/users/{username}/passwordrequesttoken')
  public requestPasswordreset(@Path('username') username: string): Observable<any> { return null; }

  @GET('/impersonateToken/{id}')
  public impersonateUser(@Path('id') userId: string): Observable<any> { return null; }

  @GET('/maintenance/next')
  @APIKey('userconfig')
  public getMaintenance(): Observable<Maintenance[]> { return null; }
}
