export class Company {
    id: string;
    name: string;
    tenantId: string;
    contact: string;
    logoURL: string;
    street: string;
    housenumber: string;
    zipcode: string;
    city: string;
    region: string;
    country: string;
    siteIds: string[];
    geoLatitude: number;
    geoLongitude: number;
    // Not part of company model itself
    // But used for image saved in user service
    imageUrl: string;
}
