import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthHttpAdapter } from '../../share/http/auth-http.adapter';
import { Adapter, Body, DELETE, GET, POST, PUT } from '../../share/http/auth-http.decorator';
import { AuthHttpService } from '../../share/http/auth-http.service';
import { MediaType, Path, Produces } from '../../share/http/index';
import { Company, Waypoint } from '../../share/models/';

const imgType = { 'Accept': 'image/webp,image/png,image/*,*/*;q=0.8' };

export interface CompanyInvite {
  code: string;
  companyId: string;
  createdAt: string;
  expired: boolean;
  expiresAt: string;
  id: string;
  tenantId: string;
  updatedAt: string;
  userId: string;
}

@Injectable()
export class CompanyAPIService extends AuthHttpService {

  private _api = this.configService.getAPI('companies');

  @POST('/companies')
  @Produces(MediaType.FORM_DATA)
  public createCompany(@Body form: FormGroup): Observable<any> { return null; }

  @PUT('/companies/{id}')
  @Produces(MediaType.FORM_DATA)
  public updateCompany(@Body form: FormGroup, @Path('id') id: string): Observable<any> { return null; }

  @GET('/companies')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getCompanies(): Observable<Company[]> { return null; }

  @GET('/companies/{id}')
  public getCompany(@Path('id') id: string): Observable<Company> { return null; }

  @GET('/invites/company')
  public getCompanyInvite(): Observable<CompanyInvite> { return null; }

  @DELETE('/companies/{id}')
  public deleteCompany(@Path('id') id: string): Observable<any> { return null; }

  @GET('/geoaddress/all/?query={query}')
  @Adapter(AuthHttpAdapter.geoAdressAdapter)
  public getAllGeoAdressesForQuery(@Path('query') query: string): Observable<Waypoint[]> { return null; }

  @GET('/geoaddress/?query={query}')
  @Adapter(AuthHttpAdapter.geoAdressAdapter)
  public getFirstGeoAdressForQuery(@Path('query') query: string): Observable<Waypoint> { return null; }
}
