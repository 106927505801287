export class AccessToken {
    id: string;
    subject: Subject;
    startTime: string;
    endTime: string;
    active: boolean;
    bookingId: string;
    deleted: boolean;
    resourceAccessTokenStatus: string;
    extTokenProvider: string;
    extTokenId: string;
    startBeforeEnd: boolean;
    tenantId: string;
    subjectId: string;
    userIds: string[];
}

export enum Subject {
    'Vehicle'
}

export enum ResourceAccessTokenStatus {
    'CREATED',
    'REVOKED',
    'EXPIRED',
}
