import { OpenrouteCoordinates } from '../map.api.service';
import * as mapboxgl from 'mapbox-gl';
import {
  AfterViewInit, ChangeDetectionStrategy, Component,
  Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, combineLatest, interval, Subscription } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';
import { LngLat } from 'mapbox-gl/dist/mapbox-gl';
import { LngLatBounds, Map, Marker } from 'mapbox-gl';
import { LngLatPopup, MapService } from '../map.service';
import { Waypoint } from '../../../models/index';
import * as _ from 'lodash';

export interface Circle {
  bounds: LngLatPopup[];
  radius: number;
}

@Component({
  selector: 'app-dynamic-map',
  templateUrl: './dynamic-map.component.html',
  styleUrls: ['./dynamic-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicMapComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() singleMarker: Marker;
  @Input() markers: Marker;
  @Input() route: Waypoint[];
  @Input() inputLat: number;
  @Input() inputLng: number;
  @Input() placeName: string;
  @Input() directions: LngLatPopup[];
  @Input() mapId = 'map'; // default
  @Input() circle: Circle;

  /// default settings
  mapSubject = new BehaviorSubject<Map>(null);
  map: Map;
  style = {
    bright: 'https://api.maptiler.com/maps/streets/style.json?key=zcuBYHLDuGu3jJvRovaQ'
  };
  centerLat = 48.741515;
  centerLng = 9.097027;

  // data
  source: any;
  endPointMarker: Marker[] = [];
  containerId = 'map';
  _mapBoxCircle: any;

  isLoaded: BehaviorSubject<Map> = new BehaviorSubject<Map>(null);
  initialized$ = new BehaviorSubject<boolean>(false);
  resized$ = new BehaviorSubject<boolean>(false);
  lngLatBounds$ = new BehaviorSubject<LngLat[] | LngLatBounds>(null);
  subscriptions: Subscription[] = [];


  containerWidth = 0;

  constructor(
    private _mapService: MapService
  ) {
    this.initialized$.next(false);
    this.resized$.next(false);
    this.lngLatBounds$.next(null);

    this.subscriptions.push(combineLatest(this.initialized$, this.resized$, this.lngLatBounds$)
      .subscribe(([initialized, resized, bounds]) => {
        if (initialized && bounds) {
          this._mapService.fitBound(this.map, bounds);
        }
      })
    );
  }

  ngOnInit() {
    if (this.inputLat && this.inputLng) {
      this.singleMarker = new Marker();
      this.singleMarker.setLngLat(new LngLat(this.inputLng, this.inputLat));
    }

    this.subscriptions.push(this.mapSubject.pipe(
      filter(map => map !== null)).subscribe(map => {
        this.initInputValues();
      })
    );
  }

  ngAfterViewInit() {
    this.buildMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.map && (changes.directions || changes.circle)) {
      this.resetMap();

      if (changes.directions && changes.circle && changes.directions.currentValue !== null && changes.circle.currentValue !== null) {
        console.error('cant set circle and direction value at once');
      } else if (changes.directions && changes.directions.currentValue !== null) {
        this.drawDirection();
      } else if (changes.circle && changes.circle.currentValue !== null) {
        this.drawCircle(this.circle);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.map.remove();
  }

  set mapBoxCircle(mapBoxCircle: any) {
    this._mapBoxCircle = mapBoxCircle;
  }

  get mapBoxCircle(): any {
    return this._mapBoxCircle;
  }

  resetMap() {
    this._mapService.removeCircle(this);
    this._mapService.eraseDirections(this.map, this.endPointMarker);
    this.endPointMarker = [];
    this.mapBoxCircle = null;
    this.lngLatBounds$.next(null);
  }

  private buildMap() {
    this.map = new Map({
      container: this.mapId,
      style: this.style.bright + '&optimize=true',
      zoom: 13,
      center: [this.centerLng, this.centerLat]
    });

    /// Add map controls
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.ScaleControl({
      unit: 'metric'
    }));

    this.map.on('load', event => {
      this.subscriptions.push(
        interval(1000).pipe(
          takeWhile(() => !this.initialized$.getValue()))
          .subscribe(() => {
            const canvasContainer = this.map.getCanvasContainer();
            if (canvasContainer.clientWidth !== 0) {
              this.initialized$.next(true);
              this.map.resize();
            }
          })
      );

      this.mapSubject.next(this.map);
    });

    this.map.on('resize', event => {
      this.resized$.next(false);
      this.resized$.next(true);
    });
  }

  onResize(event) {
    if (event.target.innerWidth > 0) {
      this.map.resize();
    }
  }

  adjustFocus(lngLat: LngLat, zoomLevel: number = 14) {
    // Center the map on the coordinates of any clicked symbol from the 'symbols' layer.
    this.map.flyTo({
      center: lngLat,
      zoom: zoomLevel
    });
  }

  private initInputValues() {
    if (this.singleMarker) {
      this._mapService.addMarker(this.map, this.singleMarker);
      this.map.setCenter(this.singleMarker.getLngLat(), null);
    }
  }

  private drawCircle(circle: Circle) {
    if (circle.bounds && circle.bounds[0]) {
      const lngLat = new LngLat(circle.bounds[0].lngLat.lng, circle.bounds[0].lngLat.lat);
      this.endPointMarker.push(this._mapService.createMarker(lngLat, circle.bounds[0].name));
      this._mapService.addMarker(this.map, this.endPointMarker[this.endPointMarker.length - 1]);
      this._mapService.drawCircle(this, lngLat, this.circle.radius * 1000 / 2);
    }
  }

  private drawDirection() {
    if (this.directions.length > 1) {
      const coordinates: OpenrouteCoordinates = new OpenrouteCoordinates();
      coordinates.coordinates = [];
      const lngLatArray: LngLat[] = [];

      // remove old markers
      this._mapService.eraseDirections(this.map, this.endPointMarker);

      this.endPointMarker = [];
      this.directions.forEach((lnglatPop, index) => {
        if (lnglatPop) {
          coordinates.coordinates[index] = [lnglatPop.lngLat.lng, lnglatPop.lngLat.lat];
          const newMarker = this._mapService.createMarker(lnglatPop.lngLat, lnglatPop.name);
          this._mapService.addMarker(this.map, newMarker);
          this.endPointMarker.push(newMarker);

          lngLatArray.push(new LngLat(lnglatPop.lngLat.lng, lnglatPop.lngLat.lat));
        }
      });

      // set map bounds
      this.lngLatBounds$.next(lngLatArray);

      // avoid error if style not loaded
      if (this.initialized$.value) {
        this._mapService.drawDirections(this.map, coordinates).subscribe(() => { });
      } else {
        this.map.on('load', event => {
          this._mapService.drawDirections(this.map, coordinates).subscribe(() => { });
        });
      }
    } else {
      console.log('Can\'t draw directions with one element');
    }
  }
}
