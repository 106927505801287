import { InvoiceElement } from '.';

export class BookingInvoice {
    consigneeId: string;
    createdAt: Date;
    date: Date;
    deleted: boolean;
    expirationDate: Date;
    filename: string;
    fullFilename: string;
    id: string;
    invoicedElements: InvoiceElement[];
    path: string;
    tenantId: string;
    updatedAt: Date;
}
