import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthHttpAdapter } from '../../http/auth-http.adapter';
import { Adapter, API, AuthHttpService, Extern, GET, Headers, Body, Params, Path, POST, APIKey, ResponseType } from '../../http/index';
import { Waypoint } from '../../models/index';
import { OpenRouteMatrixResponse, OpenrouteMatrixCoordinates } from '../../models/openRouteServiceResponseModel'
import { get } from 'http';

const imgType = { 'Accept': 'image/webp,image/png,image/*,*/*;q=0.8' };

@Injectable()
export class MapAPIService extends AuthHttpService {

  private _tile = 'https://geocoder.tilehosting.com';
  private _api = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
  private _openrouteservice = 'https://api.openrouteservice.org/geocoding';

  // ------------------
  // ---- GEOCODING ---
  // ------------------

  @GET('/q/{query}.js')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  @Params({ 'key': environment.tileHosting.geoAPIKey })
  @Extern(true)
  public getGeocodingsTile(@Path('query') search: string): Observable<any> { return null; }

  @GET('/?q={query}')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  @Params({ 'lang': 'de' })
  @Extern(true)
  public getGeocodingsPhoton(@Path('query') search: string): Observable<any> { return null; }

  @GET('/?address={query}')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  @Params({ 'format': 'JSON' })
  @Extern(true)
  public getGeocodingsGisgraphy(@Path('query') search: string): Observable<any> { return null; }

  // Mapbox
  @GET('/{query}.json')
  @Adapter(AuthHttpAdapter.mapboxWaypointAdaper)
  @Params({ 'access_token': 'pk.eyJ1Ijoic2NobmlsbHoiLCJhIjoiY2pjeW81NHp4NGhmdjMzcGczOGhoanY0eiJ9.Gzu7A7R7R4u1j2ztvZclng' })
  @Extern(true)
  public getGeocodings(@Path('query') search: string): Observable<Waypoint[]> { return null; }

  @GET('?query={query}')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  @Params({ 'api_key': environment.openRouteService.APIKey })
  @Extern(true)
  public getGeocodingsOpenrouteService(@Path('query') search: string): Observable<any> { return null; }

  @GET('/userFavoriteWaypoints?q={query}')
  @APIKey('coredatabooking')
  @Adapter(AuthHttpAdapter.arrayAdapter)
  public getUserFavoriteWayPoints(@Path('query') search: string): Observable<Waypoint[]> { return null; }
  // public getUserFavoriteWayPoints(): Observable<Waypoint[]> { return null; }

  @GET('/{query}')
  @API('https://api.mapbox.com/styles/v1/mapbox/streets-v11/static')
  @Headers(imgType)
  @ResponseType('blob')
  @Adapter(AuthHttpAdapter.imgUrlAdapter)
  @Params({ 'access_token': 'pk.eyJ1Ijoic2NobmlsbHoiLCJhIjoiY2pjeW81NHp4NGhmdjMzcGczOGhoanY0eiJ9.Gzu7A7R7R4u1j2ztvZclng' })
  // @Extern(true)
  public getMapAsImage(@Path('query') search: string): Observable<any> { return null; }

  // ------------------
  // ---- DIRECTION ---
  // ------------------

  // openrouteservice
  @POST('')
  @API('https://api.openrouteservice.org/v2/directions/driving-car/geojson')
  @Adapter(AuthHttpAdapter.openrouteDirectionAdapter)
  @Params({
    'api_key': environment.openRouteService.APIKey
  })
  @Extern(true)
  public getDirections(@Body coordinates: OpenrouteCoordinates): Observable<any> { return null; }

  @POST('')
  @API('https://api.openrouteservice.org/v2/matrix/driving-car/json')
  @Adapter(AuthHttpAdapter.openrouteMatrixAdapter)
  @Params({
    'api_key': environment.openRouteService.APIKey
  })
  @Extern(true)
  public getTimeDistanceMatrixCar(@Body matrixCoordinates: OpenrouteMatrixCoordinates): Observable<OpenRouteMatrixResponse> { return null; }

  @POST('')
  @API('https://api.openrouteservice.org/v2/matrix/foot-walking/json')
  @Adapter(AuthHttpAdapter.openrouteMatrixAdapter)
  @Params({
    'api_key': environment.openRouteService.APIKey
  })
  @Extern(true)
  public getTimeDistanceMatrixWalk(@Body matrixCoordinates: OpenrouteMatrixCoordinates):
    Observable<OpenRouteMatrixResponse> { return null; }

  @POST('')
  @API('https://api.openrouteservice.org/v2/matrix/cycling-road/json')
  @Adapter(AuthHttpAdapter.openrouteMatrixAdapter)
  @Params({
    'api_key': environment.openRouteService.APIKey
  })
  @Extern(true)
  public getTimeDistanceMatrixCycle(@Body matrixCoordinates: OpenrouteMatrixCoordinates):
    Observable<OpenRouteMatrixResponse> { return null; }

}

// Format to query Open Route Service to get route info
export class OpenrouteCoordinates {
  coordinates: number[][];
}
