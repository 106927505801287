import { Component, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { RootLoadingService } from './share/utility/loading/root-loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'ubstack';

  constructor(
    private _router: Router,
    private _loadingService: RootLoadingService) {
    _router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this._loadingService.showLoading();
      } else if (event instanceof NavigationEnd) {
        this._loadingService.hideLoading();
      }
    });
  }
}
