import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeElapsed'
})
export class TimeElapsedPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    let result: string;
    const date = new Date(value);
    // current time
    const now = new Date().valueOf();

    // time since message was sent in seconds
    const delta = (now - (date.valueOf())) / 1000;
    // format string
    if (delta < 10) {
      result = 'Just';
    } else if (delta < 60) { // sent in last minute
      result = 'vor ' + Math.floor(delta) + ' Seconds';
    } else if (delta < 3600) { // sent in last hour
      result = 'vor ' + Math.floor(delta / 60) + ' Minutes';
    } else if (delta < 86400) { // sent on last day
      result = 'vor ' + Math.floor(delta / 3600) + ' Hours';
    } else { // sent more than one day ago
      result = 'vor ' + Math.floor(delta / 86400) + ' Days';
    }

    return result;
  }

}
