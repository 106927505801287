import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { Global } from '../../../config/global';
import { PipesModule } from '../../helper/pipes/pipes.module';
import { MaterialModule } from '../../material/material.module';
import { MapModule } from '../map/map.module';
import { RootTableModule } from '../table/root-table.module';
import { ChiplistComponent } from './chiplist/chiplist.component';
import { CustomDateAdapter } from './date-adapter/date-adapter';
import { DynamicFormItemComponent } from './dynamic-form-item/dynamic-form-item.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { FormItemControlService } from './form-item-control.service';


// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
// DateTimeFormat
export const MY_FORMATS = {
  parseInput: Global.dateFormatLong,
  fullPickerInput: Global.dateFormatLong,
  datePickerInput: '',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

// DateFormat
export const DATE_PICKER_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    PipesModule,
    RootTableModule,
    MapModule,
    FormsModule,
    TranslateModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule
  ],
  declarations: [
    DynamicFormComponent,
    DynamicFormItemComponent,
    ChiplistComponent
  ],
  exports: [
    DynamicFormComponent,
    DynamicFormItemComponent
  ],
  providers: [
    FormItemControlService,
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'de' },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_FORMAT },
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    DatePipe,
  ]
})
export class FormModule { }
