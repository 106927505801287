/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dynamic-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../dynamic-form-item/dynamic-form-item.component.ngfactory";
import * as i5 from "../dynamic-form-item/dynamic-form-item.component";
import * as i6 from "../../map/map.service";
import * as i7 from "../../../utility/utility.service";
import * as i8 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "@angular/forms";
import * as i14 from "./dynamic-form.component";
import * as i15 from "../form-item-control.service";
var styles_DynamicFormComponent = [i0.styles];
var RenderType_DynamicFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DynamicFormComponent, data: {} });
export { RenderType_DynamicFormComponent as RenderType_DynamicFormComponent };
function View_DynamicFormComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "form-fieldset-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.parent.parent.context.$implicit.label)); _ck(_v, 1, 0, currVal_0); }); }
function View_DynamicFormComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = ("icon lineicon-" + _v.parent.context.$implicit.icon); _ck(_v, 1, 0, currVal_0); }, null); }
function View_DynamicFormComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "badge"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_8)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "form-fieldset-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = _v.context.$implicit.icon; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.$implicit.name)); _ck(_v, 5, 0, currVal_1); }); }
function View_DynamicFormComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "form-fieldset-subheader"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_7)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.sublabels; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DynamicFormComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "form-row"]], [[2, "full-row", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-dynamic-form-item", [["class", "form-item"]], null, [[null, "onDateChange"], [null, "onImageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDateChange" === en)) {
        var pd_0 = (_co.dateChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("onImageChange" === en)) {
        var pd_1 = (_co.onImageChange.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_DynamicFormItemComponent_0, i4.RenderType_DynamicFormItemComponent)), i1.ɵdid(3, 245760, null, 0, i5.DynamicFormItemComponent, [i1.ChangeDetectorRef, i6.MapService, i7.UtilityService, i3.DatePipe], { formItem: [0, "formItem"], form: [1, "form"], groupName: [2, "groupName"], disabled: [3, "disabled"], formObject: [4, "formObject"] }, { onDateChange: "onDateChange", onImageChange: "onImageChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "form-row"; var currVal_2 = _v.parent.context.$implicit.controlType; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.context.$implicit; var currVal_4 = _co.form; var currVal_5 = _v.parent.parent.parent.context.$implicit.groupName; var currVal_6 = _co.disabled; var currVal_7 = _co.formObject; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.singlerow; _ck(_v, 0, 0, currVal_0); }); }
function View_DynamicFormComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_10)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.hidden; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DynamicFormComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_5)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_6)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "form-fieldset"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "no-label": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_9)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.sublabels; _ck(_v, 4, 0, currVal_1); var currVal_2 = "form-fieldset"; var currVal_3 = _ck(_v, 7, 0, (_v.parent.context.$implicit.label === null)); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = _v.parent.context.$implicit.formItems; _ck(_v, 9, 0, currVal_4); }, null); }
function View_DynamicFormComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[8, "hidden", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "no-label-wrapper": 0, "label-wrapper": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, (_v.context.$implicit.label === null), (_v.context.$implicit.label !== null)); _ck(_v, 1, 0, currVal_1); var currVal_2 = (_v.context.$implicit.triggerValue ? _v.context.$implicit.triggerValue.includes(_co.triggerValue) : true); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.label === "hidden") || !_v.context.$implicit.show); _ck(_v, 0, 0, currVal_0); }); }
function View_DynamicFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_3)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formFieldsets; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DynamicFormComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "form-row"]], [[2, "full-row", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-dynamic-form-item", [["class", "form-item"]], null, [[null, "onDateChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDateChange" === en)) {
        var pd_0 = (_co.dateChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_DynamicFormItemComponent_0, i4.RenderType_DynamicFormItemComponent)), i1.ɵdid(3, 245760, null, 0, i5.DynamicFormItemComponent, [i1.ChangeDetectorRef, i6.MapService, i7.UtilityService, i3.DatePipe], { formItem: [0, "formItem"], form: [1, "form"], groupName: [2, "groupName"], disabled: [3, "disabled"], formObject: [4, "formObject"] }, { onDateChange: "onDateChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "form-row"; var currVal_2 = _v.parent.context.$implicit.controlType; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.context.$implicit; var currVal_4 = _co.form; var currVal_5 = _co.formFieldset.groupName; var currVal_6 = _co.disabled; var currVal_7 = _co.formObject; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.singlerow; _ck(_v, 0, 0, currVal_0); }); }
function View_DynamicFormComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_14)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.hidden; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DynamicFormComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "form-fieldset-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "form-fieldset"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_13)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.formFieldset.formItems; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formFieldset.label; _ck(_v, 2, 0, currVal_0); }); }
function View_DynamicFormComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_12)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.formFieldset.triggerValue ? _co.formFieldset.triggerValue.includes(_co.triggerValue) : true); _ck(_v, 2, 0, currVal_0); }, null); }
function View_DynamicFormComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dynamic-form-identifier"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dynamicFormIdentifier; _ck(_v, 1, 0, currVal_0); }); }
function View_DynamicFormComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "button-icon red"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeLastFormItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon lineicon-cross-circle"], ["matprefix", ""]], null, null, null, null, null))], null, null); }
function View_DynamicFormComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "dynamic-form"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_16)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "button-icon action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addFormItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon lineicon-plus-circle"], ["matprefix", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_17)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dynamicFormIdentifier; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.dynamicForm.length > 0); _ck(_v, 6, 0, currVal_1); }, null); }
function View_DynamicFormComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.additionalButtonClicked.emit("true") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.additionalButton; _ck(_v, 2, 0, currVal_2); }); }
function View_DynamicFormComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelClicked.emit("true") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("rootTableDetail.cancel")); _ck(_v, 2, 0, currVal_2); }); }
function View_DynamicFormComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "form-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["color", "accent"], ["mat-raised-button", ""], ["type", "submit"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((!_co.form.valid ? _co.touchAll(_co.form) : _co.onSubmit()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(3, 0, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_19)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_20)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "accent"; _ck(_v, 2, 0, currVal_2); var currVal_4 = _co.additionalButton; _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.cancelButton; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("rootTableDetail.save")); _ck(_v, 3, 0, currVal_3); }); }
function View_DynamicFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i13.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(3, 540672, null, 0, i13.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i13.ControlContainer, null, [i13.FormGroupDirective]), i1.ɵdid(5, 16384, null, 0, i13.NgControlStatusGroup, [[4, i13.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_11)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_15)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_18)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 3, 0, currVal_7); var currVal_8 = _co.formFieldsets; _ck(_v, 7, 0, currVal_8); var currVal_9 = _co.formFieldset; _ck(_v, 9, 0, currVal_9); var currVal_10 = _co.buildDynamicFieldset; _ck(_v, 11, 0, currVal_10); var currVal_11 = (!_co.disabled && !_co.noSubmitButton); _ck(_v, 13, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_DynamicFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DynamicFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dynamic-form", [], null, null, null, View_DynamicFormComponent_0, RenderType_DynamicFormComponent)), i1.ɵdid(1, 770048, null, 0, i14.DynamicFormComponent, [i15.FormItemControlService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DynamicFormComponentNgFactory = i1.ɵccf("app-dynamic-form", i14.DynamicFormComponent, View_DynamicFormComponent_Host_0, { formFieldsets: "formFieldsets", formFieldset: "formFieldset", disabled: "disabled", state: "state", noSubmitButton: "noSubmitButton", cancelButton: "cancelButton", additionalButton: "additionalButton", dynamicForm: "dynamicForm", dynamicFormIdentifier: "dynamicFormIdentifier", buildDynamicFieldset: "buildDynamicFieldset" }, { submitted: "submitted", formEmitter: "formEmitter", cancelClicked: "cancelClicked", additionalButtonClicked: "additionalButtonClicked", formValueChange: "formValueChange", onImageChange: "onImageChange", submittedValue: "submittedValue" }, []);
export { DynamicFormComponentNgFactory as DynamicFormComponentNgFactory };
