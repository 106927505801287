var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthHttpAdapter } from '../../share/http/auth-http.adapter';
import { Adapter, Body, DELETE, GET, POST, PUT } from '../../share/http/auth-http.decorator';
import { AuthHttpService } from '../../share/http/auth-http.service';
import { MediaType, Path, Produces } from '../../share/http/index';
var imgType = { 'Accept': 'image/webp,image/png,image/*,*/*;q=0.8' };
var CompanyAPIService = /** @class */ (function (_super) {
    __extends(CompanyAPIService, _super);
    function CompanyAPIService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._api = _this.configService.getAPI('companies');
        return _this;
    }
    CompanyAPIService.prototype.createCompany = function (form) { return null; };
    CompanyAPIService.prototype.updateCompany = function (form, id) { return null; };
    CompanyAPIService.prototype.getCompanies = function () { return null; };
    CompanyAPIService.prototype.getCompany = function (id) { return null; };
    CompanyAPIService.prototype.getCompanyInvite = function () { return null; };
    CompanyAPIService.prototype.deleteCompany = function (id) { return null; };
    CompanyAPIService.prototype.getAllGeoAdressesForQuery = function (query) { return null; };
    CompanyAPIService.prototype.getFirstGeoAdressForQuery = function (query) { return null; };
    __decorate([
        POST('/companies'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup]),
        __metadata("design:returntype", Observable)
    ], CompanyAPIService.prototype, "createCompany", null);
    __decorate([
        PUT('/companies/{id}'),
        Produces(MediaType.FORM_DATA),
        __param(0, Body), __param(1, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FormGroup, String]),
        __metadata("design:returntype", Observable)
    ], CompanyAPIService.prototype, "updateCompany", null);
    __decorate([
        GET('/companies'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], CompanyAPIService.prototype, "getCompanies", null);
    __decorate([
        GET('/companies/{id}'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], CompanyAPIService.prototype, "getCompany", null);
    __decorate([
        GET('/invites/company'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], CompanyAPIService.prototype, "getCompanyInvite", null);
    __decorate([
        DELETE('/companies/{id}'),
        __param(0, Path('id')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], CompanyAPIService.prototype, "deleteCompany", null);
    __decorate([
        GET('/geoaddress/all/?query={query}'),
        Adapter(AuthHttpAdapter.geoAdressAdapter),
        __param(0, Path('query')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], CompanyAPIService.prototype, "getAllGeoAdressesForQuery", null);
    __decorate([
        GET('/geoaddress/?query={query}'),
        Adapter(AuthHttpAdapter.geoAdressAdapter),
        __param(0, Path('query')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], CompanyAPIService.prototype, "getFirstGeoAdressForQuery", null);
    return CompanyAPIService;
}(AuthHttpService));
export { CompanyAPIService };
