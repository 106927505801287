/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog-form.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/form/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "../../../components/form/dynamic-form/dynamic-form.component";
import * as i5 from "../../../components/form/form-item-control.service";
import * as i6 from "./dialog-form.component";
import * as i7 from "@angular/material/dialog";
var styles_DialogFormComponent = [i0.styles];
var RenderType_DialogFormComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DialogFormComponent, data: {} });
export { RenderType_DialogFormComponent as RenderType_DialogFormComponent };
export function View_DialogFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-dynamic-form", [], null, [[null, "formEmitter"], [null, "submitted"], [null, "cancelClicked"], [null, "additionalButtonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("formEmitter" === en)) {
        var pd_0 = (_co.handleFormEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("submitted" === en)) {
        var pd_1 = (_co.dialogRef.close(_co.formGroup) !== false);
        ad = (pd_1 && ad);
    } if (("cancelClicked" === en)) {
        var pd_2 = (_co.dialogRef.close(null) !== false);
        ad = (pd_2 && ad);
    } if (("additionalButtonClicked" === en)) {
        var pd_3 = (_co.dialogRef.close("additionalButtonClicked") !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "multiColumn": 0 }), i1.ɵdid(3, 770048, null, 0, i4.DynamicFormComponent, [i5.FormItemControlService, i1.ChangeDetectorRef], { formFieldsets: [0, "formFieldsets"], cancelButton: [1, "cancelButton"], additionalButton: [2, "additionalButton"], dynamicForm: [3, "dynamicForm"], dynamicFormIdentifier: [4, "dynamicFormIdentifier"], buildDynamicFieldset: [5, "buildDynamicFieldset"] }, { submitted: "submitted", formEmitter: "formEmitter", cancelClicked: "cancelClicked", additionalButtonClicked: "additionalButtonClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_co.data.columns && (_co.data.columns !== 1))); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.formFieldsets; var currVal_2 = true; var currVal_3 = _co.data.additionalButton; var currVal_4 = _co.data.dynamicForm; var currVal_5 = _co.data.dynamicFormIdentifier; var currVal_6 = _co.data.buildDynamicFieldset; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_DialogFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-form", [], null, null, null, View_DialogFormComponent_0, RenderType_DialogFormComponent)), i1.ɵdid(1, 49152, null, 0, i6.DialogFormComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA], null, null)], null, null); }
var DialogFormComponentNgFactory = i1.ɵccf("app-dialog-form", i6.DialogFormComponent, View_DialogFormComponent_Host_0, {}, {}, []);
export { DialogFormComponentNgFactory as DialogFormComponentNgFactory };
