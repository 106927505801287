import { DialogService } from './../../share/utility/dialog/dialog.service';
import { RootLoadingService } from '../../share/utility/loading/root-loading.service';

import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { NotificationService } from '../../share/utility/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UserAPIService } from '../../modules/user/user.api.service';
import { AuthenticationAppService } from '../authentication.app.service';
import { User } from '../../share/models/user';
import { RegionalConfig } from '../../../../core/regionalConfig';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  private _model: any = {};
  private returnUrl: string;
  hide: boolean;
  error: string;
  passwordError: string;
  pwforgotten = false;
  usernamePw: string;
  random: number;

  constructor(
    private _loaderService: RootLoadingService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _authAppService: AuthenticationAppService,
    private _notificationService: NotificationService,
    private _dialogService: DialogService,
    private _translate: TranslateService,
    private _userAPIService: UserAPIService) {
    _translate.addLangs(RegionalConfig.languages);
    _translate.setDefaultLang('de');
  }

  ngOnInit() {

    // authenticationService.logout();
    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    if (this._route.snapshot.data['pwforgotten']) {
      this.pwforgotten = true;
    }

    // Returns a random integer between 1-4
    this.random = Math.floor(Math.random() * 4) + 1;
  }

  ngAfterViewInit() {
    // this.loaderService.hideLoading();
  }

  get model(): any {
    return this._model;
  }

  get pwel(): any {
    return this._model;
  }

  login() {
    this._loaderService.showLoading();
    this._authenticationService.login(this.model.username, this.model.password).subscribe(
      user => {
        // Update User data
        this._authAppService.user = user;

        // Force user to accept terms of service/use
        !user.termsOfUseAccepted ? this.showTerms(user) : this.redirect();

        // Update current user configuration from service call
        this._userAPIService.getOwnUserConfiguration().subscribe(result => {
          this._authAppService.userConfiguration = result;
        });
      },
      error => {
        console.error(error);
        this.error = 'Ooops, username or password is incorrect';
        this._loaderService.hideLoading();
      });
  }

  sendPassword($event: any) {
    $event.preventDefault();
    this._loaderService.showLoading();
    this._authenticationService.sendPassword(this.model.usernamePw)
      .subscribe(data => {
        this.passwordError = '';
        this.pwforgotten = false;
        this._notificationService.showSuccess('Sie erhalten in Kürze eine E-Mail');
        this._router.navigate(['/#/login']);
        this._loaderService.hideLoading();
      },
        error => {
          this.passwordError = 'Benutzername oder Email-Adresse nicht bekannt';
          this._notificationService.showWarning('Benutzername oder Email-Adresse nicht bekannt. Bitte versuchen Sie es erneut!');
          this._loaderService.hideLoading();
        });
  }

  triggerPassword() {
    const currentValue = this.pwforgotten;
    if (currentValue) {
      this._router.navigate(['login']);
    } else {
      this._router.navigate(['pwforgotten']);
    }
    this.pwforgotten = !this.pwforgotten;
  }

  private showTerms(user: User) {
    this._loaderService.hideLoading();
    this._dialogService.confirm('dialog.terms', 'dialog.termsMessage').subscribe(confirm => {
      if (confirm) {
        this._loaderService.showLoading();
        user.termsOfUseAccepted = true;
        this._userAPIService.updateSelfObject(user).subscribe(result => {
          localStorage.setItem('user', JSON.stringify(user));
          this._loaderService.hideLoading();
        });
      } else {
        this._authenticationService.logout();
      }
    });
  }

  private redirect() {
    this._authAppService.navigateAfterLogin(this.returnUrl);
  }

}
