import { OpenrouteCoordinates } from '../map.api.service';
import * as mapboxgl from 'mapbox-gl';
import { AfterViewInit, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, interval } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';
import { LngLat } from 'mapbox-gl/dist/mapbox-gl';
import { Map, Marker } from 'mapbox-gl';
import { MapService } from '../map.service';
var DynamicMapComponent = /** @class */ (function () {
    function DynamicMapComponent(_mapService) {
        var _this = this;
        this._mapService = _mapService;
        this.mapId = 'map'; // default
        /// default settings
        this.mapSubject = new BehaviorSubject(null);
        this.style = {
            bright: 'https://api.maptiler.com/maps/streets/style.json?key=zcuBYHLDuGu3jJvRovaQ'
        };
        this.centerLat = 48.741515;
        this.centerLng = 9.097027;
        this.endPointMarker = [];
        this.containerId = 'map';
        this.isLoaded = new BehaviorSubject(null);
        this.initialized$ = new BehaviorSubject(false);
        this.resized$ = new BehaviorSubject(false);
        this.lngLatBounds$ = new BehaviorSubject(null);
        this.subscriptions = [];
        this.containerWidth = 0;
        this.initialized$.next(false);
        this.resized$.next(false);
        this.lngLatBounds$.next(null);
        this.subscriptions.push(combineLatest(this.initialized$, this.resized$, this.lngLatBounds$)
            .subscribe(function (_a) {
            var initialized = _a[0], resized = _a[1], bounds = _a[2];
            if (initialized && bounds) {
                _this._mapService.fitBound(_this.map, bounds);
            }
        }));
    }
    DynamicMapComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.inputLat && this.inputLng) {
            this.singleMarker = new Marker();
            this.singleMarker.setLngLat(new LngLat(this.inputLng, this.inputLat));
        }
        this.subscriptions.push(this.mapSubject.pipe(filter(function (map) { return map !== null; })).subscribe(function (map) {
            _this.initInputValues();
        }));
    };
    DynamicMapComponent.prototype.ngAfterViewInit = function () {
        this.buildMap();
    };
    DynamicMapComponent.prototype.ngOnChanges = function (changes) {
        if (this.map && (changes.directions || changes.circle)) {
            this.resetMap();
            if (changes.directions && changes.circle && changes.directions.currentValue !== null && changes.circle.currentValue !== null) {
                console.error('cant set circle and direction value at once');
            }
            else if (changes.directions && changes.directions.currentValue !== null) {
                this.drawDirection();
            }
            else if (changes.circle && changes.circle.currentValue !== null) {
                this.drawCircle(this.circle);
            }
        }
    };
    DynamicMapComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.map.remove();
    };
    Object.defineProperty(DynamicMapComponent.prototype, "mapBoxCircle", {
        get: function () {
            return this._mapBoxCircle;
        },
        set: function (mapBoxCircle) {
            this._mapBoxCircle = mapBoxCircle;
        },
        enumerable: true,
        configurable: true
    });
    DynamicMapComponent.prototype.resetMap = function () {
        this._mapService.removeCircle(this);
        this._mapService.eraseDirections(this.map, this.endPointMarker);
        this.endPointMarker = [];
        this.mapBoxCircle = null;
        this.lngLatBounds$.next(null);
    };
    DynamicMapComponent.prototype.buildMap = function () {
        var _this = this;
        this.map = new Map({
            container: this.mapId,
            style: this.style.bright + '&optimize=true',
            zoom: 13,
            center: [this.centerLng, this.centerLat]
        });
        /// Add map controls
        this.map.addControl(new mapboxgl.NavigationControl());
        this.map.addControl(new mapboxgl.ScaleControl({
            unit: 'metric'
        }));
        this.map.on('load', function (event) {
            _this.subscriptions.push(interval(1000).pipe(takeWhile(function () { return !_this.initialized$.getValue(); }))
                .subscribe(function () {
                var canvasContainer = _this.map.getCanvasContainer();
                if (canvasContainer.clientWidth !== 0) {
                    _this.initialized$.next(true);
                    _this.map.resize();
                }
            }));
            _this.mapSubject.next(_this.map);
        });
        this.map.on('resize', function (event) {
            _this.resized$.next(false);
            _this.resized$.next(true);
        });
    };
    DynamicMapComponent.prototype.onResize = function (event) {
        if (event.target.innerWidth > 0) {
            this.map.resize();
        }
    };
    DynamicMapComponent.prototype.adjustFocus = function (lngLat, zoomLevel) {
        if (zoomLevel === void 0) { zoomLevel = 14; }
        // Center the map on the coordinates of any clicked symbol from the 'symbols' layer.
        this.map.flyTo({
            center: lngLat,
            zoom: zoomLevel
        });
    };
    DynamicMapComponent.prototype.initInputValues = function () {
        if (this.singleMarker) {
            this._mapService.addMarker(this.map, this.singleMarker);
            this.map.setCenter(this.singleMarker.getLngLat(), null);
        }
    };
    DynamicMapComponent.prototype.drawCircle = function (circle) {
        if (circle.bounds && circle.bounds[0]) {
            var lngLat = new LngLat(circle.bounds[0].lngLat.lng, circle.bounds[0].lngLat.lat);
            this.endPointMarker.push(this._mapService.createMarker(lngLat, circle.bounds[0].name));
            this._mapService.addMarker(this.map, this.endPointMarker[this.endPointMarker.length - 1]);
            this._mapService.drawCircle(this, lngLat, this.circle.radius * 1000 / 2);
        }
    };
    DynamicMapComponent.prototype.drawDirection = function () {
        var _this = this;
        if (this.directions.length > 1) {
            var coordinates_1 = new OpenrouteCoordinates();
            coordinates_1.coordinates = [];
            var lngLatArray_1 = [];
            // remove old markers
            this._mapService.eraseDirections(this.map, this.endPointMarker);
            this.endPointMarker = [];
            this.directions.forEach(function (lnglatPop, index) {
                if (lnglatPop) {
                    coordinates_1.coordinates[index] = [lnglatPop.lngLat.lng, lnglatPop.lngLat.lat];
                    var newMarker = _this._mapService.createMarker(lnglatPop.lngLat, lnglatPop.name);
                    _this._mapService.addMarker(_this.map, newMarker);
                    _this.endPointMarker.push(newMarker);
                    lngLatArray_1.push(new LngLat(lnglatPop.lngLat.lng, lnglatPop.lngLat.lat));
                }
            });
            // set map bounds
            this.lngLatBounds$.next(lngLatArray_1);
            // avoid error if style not loaded
            if (this.initialized$.value) {
                this._mapService.drawDirections(this.map, coordinates_1).subscribe(function () { });
            }
            else {
                this.map.on('load', function (event) {
                    _this._mapService.drawDirections(_this.map, coordinates_1).subscribe(function () { });
                });
            }
        }
        else {
            console.log('Can\'t draw directions with one element');
        }
    };
    return DynamicMapComponent;
}());
export { DynamicMapComponent };
