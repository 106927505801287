import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export abstract class RootTableService<T> {
  abstract getObjects(): Observable<T[]>;
  abstract getAll(): Observable<any[]>;
}

@Injectable()
export abstract class RootTableServiceCRUD<T> extends RootTableService<T> {
  abstract createObject(object: any): Observable<any>;
  abstract updateObject(object: any, objectId: string): Observable<any>;
  abstract deleteObject(object: T): Observable<any>;
  abstract createGenericObject(): T;
}

@Injectable()
export abstract class RootTableServicePageable<T> extends RootTableServiceCRUD<T> {
  abstract getHrefObjects(path: string): Observable<any>;
  abstract getPageableObjects(offset: number, limit: number): Observable<any>;
  abstract getPageableObjectsId(offset: number, limit: number, id: string, filter?: string): Observable<any>;
  // abstract getFilterObjects(path: string): Observable<any>;
}
