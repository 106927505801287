// hard coded
export const Rights = {
    manage_all_tenants: 'manage_all_tenants',
    manage_tenants: 'manage_tenants',
    manage_tenant: 'manage_tenant',
    manage_companies: 'manage_companies',
    manage_site_as_company: 'manage_site_as_company',

    manage_access_keys: 'manage_access_keys',

    manage_bookings: 'manage_bookings',
    manage_bookings_as_company: 'manage_booking_as_company',
    manage_waypoint_as_company: 'manage_waypoint_as_company',
    book_and_use_vehicles: 'book_and_use_vehicles',
    execute_approvals_for_booking: 'execute_approvals_for_booking',
    execute_booking_for_user: 'execute_booking_for_user',
    select_explicit_vehicle: 'select_explicit_vehicle',
    can_checkin: 'can_checkin',
    can_checkout: 'can_checkout',

    manage_audit_trail: 'manage_audit_trail',

    manage_user_as_tenant: 'manage_user_as_tenant',
    manage_user_as_company: 'manage_user_as_company',
    view_all_user: 'view_all_user',
    manage_all_user: 'manage_all_user',

    manage_vehicles: 'manage_vehicles',
    manage_vehicle_as_company: 'manage_vehicle_as_company',
    manage_rights: 'manage_rights',
    manage_pool_as_company: 'manage_pool_as_company',

    command_charging_stations: 'command_charging_stations',
    config_charging_stations: 'config_charging_stations',
    create_charging_profile: 'createcharging_profile',
    create_charging_stations: 'create_charging_stations',
    create_configuration: 'create_configuration',
    create_tariffs: 'create_tariffs',
    create_tasks: 'create_tasks',
    create_transactions: 'create_transactions',
    create_whitelist: 'create_whitelist',
    delete_charging_stations: 'delete_charging_stations',
    delete_configuration: 'delete_configuration',
    delete_tariffs: 'delete_tariffs',
    delete_transactions: 'delete_transactions',
    delete_whitelist: 'delete_whitelist',
    edit_charging_stations: 'edit_charging_stations',
    edit_configuration: 'edit_configuration',
    edit_tariffs: 'edit_tariffs',
    edit_tasks: 'edit_tasks',
    edit_transactions: 'edit_transactions',
    edit_whitelist: 'edit_whitelist',
    view_charging_stations: 'view_charging_stations',
    view_configuration: 'view_configuration',
    view_tariffs: 'view_tariffs',
    view_transactions: 'view_transactions',
    view_whitelist: 'view_whitelist',
    view_tasks: 'view_tasks',
    view_report: 'view_report',
    view_invoice: 'view_invoice',
    view_calendar: 'view_calendar',

    view_user_favorite_waypoint: 'view_user_favorite_waypoint',
    edit_user_favorite_waypoint: 'edit_user_favorite_waypoint',
    create_user_favorite_waypoint: 'create_user_favorite_waypoint',
    delete_user_favorite_waypoint: 'delete_user_favorite_waypoint',

    create_efs_veloxpress_booking: 'create_efs_veloxpress_booking',
    create_efs_booking: 'create_efs_booking'
};

export class Right {
    id: string;
    internalName: string;
    name: string;
    description: string;
    deleted: Boolean;
    consistent: Boolean;
}
