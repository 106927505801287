var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthHttpAdapter } from '../../http/auth-http.adapter';
import { Adapter, API, AuthHttpService, Extern, GET, Headers, Body, Params, Path, POST, APIKey, ResponseType } from '../../http/index';
import { OpenrouteMatrixCoordinates } from '../../models/openRouteServiceResponseModel';
var imgType = { 'Accept': 'image/webp,image/png,image/*,*/*;q=0.8' };
var MapAPIService = /** @class */ (function (_super) {
    __extends(MapAPIService, _super);
    function MapAPIService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._tile = 'https://geocoder.tilehosting.com';
        _this._api = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
        _this._openrouteservice = 'https://api.openrouteservice.org/geocoding';
        return _this;
    }
    // ------------------
    // ---- GEOCODING ---
    // ------------------
    MapAPIService.prototype.getGeocodingsTile = function (search) { return null; };
    MapAPIService.prototype.getGeocodingsPhoton = function (search) { return null; };
    MapAPIService.prototype.getGeocodingsGisgraphy = function (search) { return null; };
    // Mapbox
    MapAPIService.prototype.getGeocodings = function (search) { return null; };
    MapAPIService.prototype.getGeocodingsOpenrouteService = function (search) { return null; };
    MapAPIService.prototype.getUserFavoriteWayPoints = function (search) { return null; };
    // public getUserFavoriteWayPoints(): Observable<Waypoint[]> { return null; }
    MapAPIService.prototype.getMapAsImage = function (search) { return null; };
    // ------------------
    // ---- DIRECTION ---
    // ------------------
    // openrouteservice
    MapAPIService.prototype.getDirections = function (coordinates) { return null; };
    MapAPIService.prototype.getTimeDistanceMatrixCar = function (matrixCoordinates) { return null; };
    MapAPIService.prototype.getTimeDistanceMatrixWalk = function (matrixCoordinates) { return null; };
    MapAPIService.prototype.getTimeDistanceMatrixCycle = function (matrixCoordinates) { return null; };
    __decorate([
        GET('/q/{query}.js'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        Params({ 'key': environment.tileHosting.geoAPIKey }),
        Extern(true),
        __param(0, Path('query')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], MapAPIService.prototype, "getGeocodingsTile", null);
    __decorate([
        GET('/?q={query}'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        Params({ 'lang': 'de' }),
        Extern(true),
        __param(0, Path('query')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], MapAPIService.prototype, "getGeocodingsPhoton", null);
    __decorate([
        GET('/?address={query}'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        Params({ 'format': 'JSON' }),
        Extern(true),
        __param(0, Path('query')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], MapAPIService.prototype, "getGeocodingsGisgraphy", null);
    __decorate([
        GET('/{query}.json'),
        Adapter(AuthHttpAdapter.mapboxWaypointAdaper),
        Params({ 'access_token': 'pk.eyJ1Ijoic2NobmlsbHoiLCJhIjoiY2pjeW81NHp4NGhmdjMzcGczOGhoanY0eiJ9.Gzu7A7R7R4u1j2ztvZclng' }),
        Extern(true),
        __param(0, Path('query')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], MapAPIService.prototype, "getGeocodings", null);
    __decorate([
        GET('?query={query}'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        Params({ 'api_key': environment.openRouteService.APIKey }),
        Extern(true),
        __param(0, Path('query')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], MapAPIService.prototype, "getGeocodingsOpenrouteService", null);
    __decorate([
        GET('/userFavoriteWaypoints?q={query}'),
        APIKey('coredatabooking'),
        Adapter(AuthHttpAdapter.arrayAdapter),
        __param(0, Path('query')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], MapAPIService.prototype, "getUserFavoriteWayPoints", null);
    __decorate([
        GET('/{query}'),
        API('https://api.mapbox.com/styles/v1/mapbox/streets-v11/static'),
        Headers(imgType),
        ResponseType('blob'),
        Adapter(AuthHttpAdapter.imgUrlAdapter),
        Params({ 'access_token': 'pk.eyJ1Ijoic2NobmlsbHoiLCJhIjoiY2pjeW81NHp4NGhmdjMzcGczOGhoanY0eiJ9.Gzu7A7R7R4u1j2ztvZclng' }),
        __param(0, Path('query')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Observable)
    ], MapAPIService.prototype, "getMapAsImage", null);
    __decorate([
        POST(''),
        API('https://api.openrouteservice.org/v2/directions/driving-car/geojson'),
        Adapter(AuthHttpAdapter.openrouteDirectionAdapter),
        Params({
            'api_key': environment.openRouteService.APIKey
        }),
        Extern(true),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [OpenrouteCoordinates]),
        __metadata("design:returntype", Observable)
    ], MapAPIService.prototype, "getDirections", null);
    __decorate([
        POST(''),
        API('https://api.openrouteservice.org/v2/matrix/driving-car/json'),
        Adapter(AuthHttpAdapter.openrouteMatrixAdapter),
        Params({
            'api_key': environment.openRouteService.APIKey
        }),
        Extern(true),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [OpenrouteMatrixCoordinates]),
        __metadata("design:returntype", Observable)
    ], MapAPIService.prototype, "getTimeDistanceMatrixCar", null);
    __decorate([
        POST(''),
        API('https://api.openrouteservice.org/v2/matrix/foot-walking/json'),
        Adapter(AuthHttpAdapter.openrouteMatrixAdapter),
        Params({
            'api_key': environment.openRouteService.APIKey
        }),
        Extern(true),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [OpenrouteMatrixCoordinates]),
        __metadata("design:returntype", Observable)
    ], MapAPIService.prototype, "getTimeDistanceMatrixWalk", null);
    __decorate([
        POST(''),
        API('https://api.openrouteservice.org/v2/matrix/cycling-road/json'),
        Adapter(AuthHttpAdapter.openrouteMatrixAdapter),
        Params({
            'api_key': environment.openRouteService.APIKey
        }),
        Extern(true),
        __param(0, Body),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [OpenrouteMatrixCoordinates]),
        __metadata("design:returntype", Observable)
    ], MapAPIService.prototype, "getTimeDistanceMatrixCycle", null);
    return MapAPIService;
}(AuthHttpService));
export { MapAPIService };
// Format to query Open Route Service to get route info
var OpenrouteCoordinates = /** @class */ (function () {
    function OpenrouteCoordinates() {
    }
    return OpenrouteCoordinates;
}());
export { OpenrouteCoordinates };
