/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./root-loading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./root-loading.component";
import * as i4 from "./root-loading.service";
var styles_RootLoadingComponent = [i0.styles];
var RenderType_RootLoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RootLoadingComponent, data: {} });
export { RenderType_RootLoadingComponent as RenderType_RootLoadingComponent };
function View_RootLoadingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "cssload-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "assets/img/loader.svg"]], null, null, null, null, null))], null, null); }
export function View_RootLoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RootLoadingComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RootLoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root-loading", [], null, null, null, View_RootLoadingComponent_0, RenderType_RootLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.RootLoadingComponent, [i4.RootLoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RootLoadingComponentNgFactory = i1.ɵccf("app-root-loading", i3.RootLoadingComponent, View_RootLoadingComponent_Host_0, {}, {}, []);
export { RootLoadingComponentNgFactory as RootLoadingComponentNgFactory };
