import { FormItemChiplist } from './../form-item-chiplist';
import { Component, EventEmitter, Input, OnDestroy,
  OnInit, Output, ViewChild, ViewEncapsulation, ElementRef, ChangeDetectorRef } from '@angular/core';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { Depend, DependValue, FormItemBase } from '../form-item-base';
import { ErrorStateMatcher, MatDatepickerInputEvent, MatChipInputEvent } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { FormItemAuto } from '../form-item-auto';
import { LngLat } from 'mapbox-gl';
import { DynamicMapComponent } from '../../map/dynamic-map/dynamic-map.component';
import { MapService } from '../../map/map.service';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { UtilityService } from '../../../utility/utility.service';
import { Waypoint } from '../../../models/index';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DATE_PICKER_OUTPUT_FORMAT } from '../date-adapter/date-adapter';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

export interface SelectElement {
  id: string;
  name: string;
  value: any;
  special?: boolean;
}

export type SelectElements = SelectElement[];

@Component({
  selector: 'app-dynamic-form-item',
  templateUrl: './dynamic-form-item.component.html',
  styleUrls: ['./dynamic-form-item.component.scss'],
  host: { 'class': 'form-item' },
  encapsulation: ViewEncapsulation.None
})
export class DynamicFormItemComponent implements OnInit, OnDestroy {
  @Input() formItem: FormItemBase<any>;
  @Input() form: FormGroup;
  @Input() groupName: string;
  @Input() disabled: boolean;
  @Input() formObject: any;

  @Output() onDateChange: EventEmitter<DependValue<Date>> = new EventEmitter();
  @Output() onImageChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('map') map: DynamicMapComponent;
  @ViewChild('imagePicker') imagePicker: any;
  @ViewChild('chiplistInput') chiplistInput: ElementRef<HTMLInputElement>;

  get isValid() {
    return this.form.get(this.controlKey).valid;
  }

  customErrorStateMatcher: ErrorStateMatcher;
  controlKey: string;
  subscriptions: Subscription[] = [];
  geoWaypoints = new Subject<Waypoint[]>();
  filteredOptions: BehaviorSubject<SelectElements> = new BehaviorSubject([]);
  filteredElements: SelectElements;
  selectedElements: BehaviorSubject<SelectElements> = new BehaviorSubject([]);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];


  constructor(
    private _cdr: ChangeDetectorRef,
    private _mapService: MapService,
    private _utilityService: UtilityService,
    private datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.setControlKey();
    this.setValues();
    this.setSubscription();
    this.customErrorStateMatcher = {
      isErrorState: (control: FormControl | null) => {
        if (control) {
          const hasInteraction = control.dirty || control.touched;
          const isInvalid = hasInteraction ? control.invalid : false;
          return isInvalid;
        }
        return false;
      }
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  compareFn(option: any, value: any) {
    let returnvalue;
    if (value && value.id) {
      returnvalue = option.id === value.id || option === value.id;
    } else {
      returnvalue = option === value;
    }
    return returnvalue;
  }

  dateChange(event: MatDatepickerInputEvent<Moment>, dependsOn: Depend) {
    const dependsOnDateEvent: DependValue<Date> = {
      depend: {
        key: dependsOn.key,
        isMin: dependsOn.isMin
      },
      value: event.value.toDate()
    };
    this.onDateChange.emit(dependsOnDateEvent);
  }

  jsonDate(event: any) {
    const date = moment(event.value).format(DATE_PICKER_OUTPUT_FORMAT);
    this.form.get(this.controlKey).setValue(date);
    // console.log(JSON.stringify(date));
  }

  // User selected a waypoint in the UI store it
  setLocation(waypoint: Waypoint) {
    const lat = waypoint.geoLatitude;
    const lng = waypoint.geoLongitude;
    const lnglat = new LngLat(lng, lat);
    if (this.map.singleMarker) {
      this.map.singleMarker.remove();
    }
    this.map.singleMarker = this._mapService.createMarker(lnglat, waypoint.name);
    this._mapService.addMarker(this.map.map, this.map.singleMarker);
    this._mapService.fitBound(this.map.map, [lnglat]);
    // Update the geo position
    this.form.get('geoLongitude').setValue(waypoint.geoLongitude);
    this.form.get('geoLatitude').setValue(waypoint.geoLatitude);
    // Update strings in form
    this.form.patchValue({
      country: waypoint.country,
      zipcode: waypoint.zipcode,
      region: waypoint.region,
      city: waypoint.city,
      street: waypoint.street,
      housenumber: waypoint.streetNumber,
    });
  }

  startDate(dt: any, stepSize: number): Moment {
    if (!dt.value || dt.value === '') {
      const now = moment();
      return this._utilityService.ceilMoment(now, stepSize, 'minutes');
    } else {
      return dt.value;
    }
  }

  removeChip(selectElement: SelectElement): void {
    let selectedElements = this.selectedElements.getValue();
    selectedElements = selectedElements.filter(el => el.id !== selectElement.id); // remove
    selectedElements.sort(this.sortSelectElementArray); // sort
    this.selectedElements.next(selectedElements); // update
    this.filteredElements.push(selectElement); // add
    this.filteredElements.sort(this.sortSelectElementArray); // sort
    this.setChiplistValue(selectedElements); // set values in ctrl
  }

  addChip(selectElement: string | any) {
    const selectedElement = this.filteredElements.filter(el => el.id === selectElement || el.id === selectElement.id)[0]; // get object
    const selectedElements = this.selectedElements.getValue();
    selectedElements.push(selectedElement); // add
    selectedElements.sort(this.sortSelectElementArray); // sort
    this.filteredElements = this.filteredElements.filter(el => el !== selectedElement); // remove object from filtered list
    this.filteredElements.sort(this.sortSelectElementArray);
    this.selectedElements.next(selectedElements); // update
    this.setChiplistValue(selectedElements); // set values in ctrl
  }

  removeChipSimple(element: string) {
    const index = (this.formItem.value as string[]).indexOf(element);

    if (index >= 0) {
      (this.formItem.value as string[]).splice(index, 1);
    }
  }

  addChipSimple(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      (this.formItem.value as string[]).push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  private setValues() {
    if (this.formItem.value && this.formItem.controlType === 'datepicker' && this.formItem.value != null) {
      const date = moment(this.formItem.value).format(DATE_PICKER_OUTPUT_FORMAT);
      this.form.get(this.controlKey).setValue(date);

    } else if (this.formItem.value && this.formItem.controlType === 'datetimepicker') {
      this.form.get(this.controlKey).setValue(moment(this.formItem.value));

    } else if (this.formItem.controlType === 'chiplist') {
      const selectedElementsInit = (this.formItem as FormItemChiplist).selectedElements;
      const selectElements = (this.formItem as FormItemChiplist).selectElements;
      selectedElementsInit.sort(this.sortSelectElementArray);
      this.selectedElements.next(selectedElementsInit); // init value
      const filteredElements = selectElements.filter(el => {
        for (const initEl of selectedElementsInit) {
          if (el.id === initEl.id) {
            return false; // exclude object
          }
        }
        return true; // include object
      });
      this.filteredElements = filteredElements.sort(this.sortSelectElementArray);
    } else if (!this.controlKey.includes('id')) {
      const formElement = this.form.get(this.controlKey);
      if (formElement) {
        // console.log(formElement);
        formElement.setValue(this.formItem.value);
      } else {
        console.log('FormItem with name ' + this.controlKey + ' not found');
      }
    }
  }

  private setControlKey() {
    // check if nested group
    if (this.groupName) {
      this.controlKey = this.groupName + '.' + this.formItem.key;
    } else {
      this.controlKey = this.formItem.key;
    }
  }

  private setSubscription() {
    if (this.formItem.controlType === 'map') {
      const mapControl = this.form.get(this.controlKey) as FormControl;
      this.subscriptions.push(mapControl.valueChanges.pipe(debounceTime(300)).subscribe(search => {
        if (search && search.length >= 1 && typeof search === 'string') {
          this._mapService.getGeocoding(search).subscribe(response => {
            this.geoWaypoints.next(response);
          });
        }
      }));
    } else if (this.formItem.controlType === 'auto') {
      const autoCtrl = this.form.get(this.controlKey) as FormControl;
      const selectElements = (this.formItem as FormItemAuto).selectElements;
      autoCtrl.valueChanges.pipe(
        startWith<string | SelectElement>(''),
        map(value => value ? value : ''),
        map(value => typeof value === 'string' ? value : value.name),
        map(value => value ? this.filterOptions(selectElements, value) : selectElements)).subscribe(value => {
          this.filteredOptions.next(value);
        });
    } else if (this.formItem.controlType === 'chiplist') {
      const chipCtrl = this.form.get(this.controlKey) as FormControl;
      chipCtrl.valueChanges.pipe(
        startWith<string | SelectElement>(''),
        map(value => value ? value : ''),
        map(value => typeof value === 'string' ? value : value.name),
        map(value => value ? this.filterOptions(this.filteredElements, value) : this.filteredElements)).subscribe(value => {
          this.filteredOptions.next(value);
        });
    }
  }

  private filterOptions(source: SelectElements, value: string): SelectElements {
    return source.filter(el =>
      el.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
  }

  private setChiplistValue(selectedElements: SelectElements) {
    const chipCtrl = this.form.get(this.controlKey) as FormControl;
    const value: any[] = selectedElements.map(el => {
      return el.value;
    });
    // reset value
    this.chiplistInput.nativeElement.value = '';
    chipCtrl.setValue(null);
    chipCtrl.setValue(value);
  }

  private sortSelectElementArray(a: SelectElement, b: SelectElement) {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    } else {
      return 0;
    }
  }

  onChangeImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      const reader = new FileReader();

      const formReference = this.form;
      const formItemElement = this.formItem;

      reader.readAsDataURL(file);
      reader.onload = (readerEvent) => {
        formItemElement.value = readerEvent.target['result'];
        const chipCtrl = formReference.get(this.controlKey) as FormControl;
        chipCtrl.setValue(readerEvent.target['result']);
        formReference.get('file').setValue(file);
        this._cdr.detectChanges();
      };
    }
  }

  onChangeSelection(event: any) {
    this._cdr.detectChanges();
  }

}
