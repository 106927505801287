import { DialogService } from './../../share/utility/dialog/dialog.service';
import { RootLoadingService } from '../../share/utility/loading/root-loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { NotificationService } from '../../share/utility/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UserAPIService } from '../../modules/user/user.api.service';
import { AuthenticationAppService } from '../authentication.app.service';
import { RegionalConfig } from '../../../../core/regionalConfig';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(_loaderService, _route, _router, _authenticationService, _authAppService, _notificationService, _dialogService, _translate, _userAPIService) {
        this._loaderService = _loaderService;
        this._route = _route;
        this._router = _router;
        this._authenticationService = _authenticationService;
        this._authAppService = _authAppService;
        this._notificationService = _notificationService;
        this._dialogService = _dialogService;
        this._translate = _translate;
        this._userAPIService = _userAPIService;
        this._model = {};
        this.pwforgotten = false;
        _translate.addLangs(RegionalConfig.languages);
        _translate.setDefaultLang('de');
    }
    LoginComponent.prototype.ngOnInit = function () {
        // authenticationService.logout();
        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
        if (this._route.snapshot.data['pwforgotten']) {
            this.pwforgotten = true;
        }
        // Returns a random integer between 1-4
        this.random = Math.floor(Math.random() * 4) + 1;
    };
    LoginComponent.prototype.ngAfterViewInit = function () {
        // this.loaderService.hideLoading();
    };
    Object.defineProperty(LoginComponent.prototype, "model", {
        get: function () {
            return this._model;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "pwel", {
        get: function () {
            return this._model;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.login = function () {
        var _this = this;
        this._loaderService.showLoading();
        this._authenticationService.login(this.model.username, this.model.password).subscribe(function (user) {
            // Update User data
            _this._authAppService.user = user;
            // Force user to accept terms of service/use
            !user.termsOfUseAccepted ? _this.showTerms(user) : _this.redirect();
            // Update current user configuration from service call
            _this._userAPIService.getOwnUserConfiguration().subscribe(function (result) {
                _this._authAppService.userConfiguration = result;
            });
        }, function (error) {
            console.error(error);
            _this.error = 'Ooops, username or password is incorrect';
            _this._loaderService.hideLoading();
        });
    };
    LoginComponent.prototype.sendPassword = function ($event) {
        var _this = this;
        $event.preventDefault();
        this._loaderService.showLoading();
        this._authenticationService.sendPassword(this.model.usernamePw)
            .subscribe(function (data) {
            _this.passwordError = '';
            _this.pwforgotten = false;
            _this._notificationService.showSuccess('Sie erhalten in Kürze eine E-Mail');
            _this._router.navigate(['/#/login']);
            _this._loaderService.hideLoading();
        }, function (error) {
            _this.passwordError = 'Benutzername oder Email-Adresse nicht bekannt';
            _this._notificationService.showWarning('Benutzername oder Email-Adresse nicht bekannt. Bitte versuchen Sie es erneut!');
            _this._loaderService.hideLoading();
        });
    };
    LoginComponent.prototype.triggerPassword = function () {
        var currentValue = this.pwforgotten;
        if (currentValue) {
            this._router.navigate(['login']);
        }
        else {
            this._router.navigate(['pwforgotten']);
        }
        this.pwforgotten = !this.pwforgotten;
    };
    LoginComponent.prototype.showTerms = function (user) {
        var _this = this;
        this._loaderService.hideLoading();
        this._dialogService.confirm('dialog.terms', 'dialog.termsMessage').subscribe(function (confirm) {
            if (confirm) {
                _this._loaderService.showLoading();
                user.termsOfUseAccepted = true;
                _this._userAPIService.updateSelfObject(user).subscribe(function (result) {
                    localStorage.setItem('user', JSON.stringify(user));
                    _this._loaderService.hideLoading();
                });
            }
            else {
                _this._authenticationService.logout();
            }
        });
    };
    LoginComponent.prototype.redirect = function () {
        this._authAppService.navigateAfterLogin(this.returnUrl);
    };
    return LoginComponent;
}());
export { LoginComponent };
