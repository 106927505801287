import { JwtHelperService } from '@auth0/angular-jwt';
import * as i0 from "@angular/core";
import * as i1 from "@auth0/angular-jwt/src/jwthelper.service";
var JwtService = /** @class */ (function () {
    function JwtService(_jwtHelper) {
        this._jwtHelper = _jwtHelper;
    }
    JwtService.prototype.isTokenExpired = function () {
        return this._jwtHelper.isTokenExpired();
    };
    JwtService.ngInjectableDef = i0.defineInjectable({ factory: function JwtService_Factory() { return new JwtService(i0.inject(i1.JwtHelperService)); }, token: JwtService, providedIn: "root" });
    return JwtService;
}());
export { JwtService };
