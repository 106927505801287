import { ChargingCharacteristic } from './index';
export class CableInlet {
    chargingCharacteristic: ChargingCharacteristic;
    connectorFormat: ConnectorFormat;
}

export enum ConnectorFormat {
    'SOCKET',
    'CABLE',
}
