export class Waypoint {
    id?: string;
    zipcode?: string;
    region?: string;
    country?: string;
    city?: string;
    name?: string;
    street?: string;
    streetNumber?: string;
    geoLongitude: number;
    geoLatitude: number;
    userId?: string;
    canCharge?: boolean;
    userWaypoint?: boolean;
}
