import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { AuthHttpServiceModule } from './share/http/index';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ConfigService } from './app-config.service';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpClientService } from './share/http/http-client.service';
import { HttpModule } from '@angular/http';
import { MaterialModule } from './share/material/material.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgModule } from '@angular/core';
import { PrimaryRouter } from './config/primary-router';
import { RootLoadingComponent } from './share/utility/loading/root-loading.component';
import { RootLoadingService } from './share/utility/loading/root-loading.service';
import { RouterModule } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UtilityModule } from './share/utility/utility.module';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    RootLoadingComponent,
  ],
  imports: [
    FormsModule,
    HttpModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AuthenticationModule,
    HttpClientModule,
    UtilityModule,
    RouterModule.forRoot(
      PrimaryRouter.appRoutes, {
        onSameUrlNavigation: 'reload',
        enableTracing: false,
        useHash: true
      }),
    AuthHttpServiceModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    HttpClientService,
    ConfigService,
    RootLoadingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
